import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, AbstractControl, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-flex-gmm-view',
  templateUrl: './flex-gmm-view.component.html',
  styleUrls: ['./flex-gmm-view.component.css']
})
export class FlexGmmViewComponent implements OnInit {

  planInit: any = {};
  planProductOptions: any = [];
  planFlexForm: FormGroup;
  planPlanesModels: FormArray;
  categoriesCount = 0;
  showSpecialCoverages = false;
  // Datos para el dual-list
  specialCoverageList = [];
  specialCoverageListSelectedByPlan = [];
  specialCoverageListSelected = [];
  key = 'idCoverage';
  display = 'coverageName';
  format = {add: 'AGREGAR', remove: 'QUITAR', all: 'TODO', none: 'NINGUNO'};
  modalRefAddCoveragePlan: BsModalRef;
  idPlanFlex;
  selectedPlanIndex = 0; // Para saber qué plan está seleccionando el usuario
  dataSourcesCoverages = [];
  isEdition = false;
  messageProduct: string;
  modalRef: BsModalRef;
  planAddCar: number;
  selectedOption: any = null;
  currentCoverageIndex: number;
  currentCoverage: AbstractControl;
  optionsValuesMap: { [key: string]: any[] } = {}; // Mapea valores personalizados por cobertura
  manualValue: string = '';
  poblationType: any = {}; // Almacena el valor ingresado en el modal
  @ViewChild('messageAlert', {static : true}) myTemplate: TemplateRef<any>;
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private router: Router) {

    this.activatedRoute.params.subscribe(params => {
      this.idPlanFlex = params.idPlan;
    });

    this.planFlexForm = this.fb.group({
      idFlexPlanGmm: [null],  // Long
      planFlexId: [this.idPlanFlex],  // Long
      typePopulationPolicyId: [null],  // Long
      typeConfigurationPolicyId: [null],  // Long
      addSpouse: [false],  // Boolean
      addChildren: [0],  // Boolean
      addMother: [false],  // Boolean
      addFather: [false],  // Boolean
      typePlanProductId: [null],  // Long
      planReduction: [0.0],  // Double
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Long
      updateUserId: [null],  // Long

      planPlanesModels: this.fb.array([])
    });

    this.planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
  }

  ngOnInit(): void {
    this.loadUnitOptions();
    this.getPlanGMMByIdPlanFlex();
  }

  onCategoriesCountChange(): void {
    this.updatePlanPlanes(this.categoriesCount);
    this.loadCoveragesByIdProduct().then(() => {
      this.spinner.hide('sp');
    });
  }

  updatePlanPlanes(count: number) {
    return new Promise((resolve, reject) => {
      try {
        this.planPlanesModels.clear();
        for (let i = 0; i < count; i++) {
          this.planPlanesModels.push(this.createPlan(false, i)); // Agrega planes según el count
        }
        resolve(); // Llama a resolve cuando termine
      } catch (error) {
        reject(error); // En caso de error
      }
    });
  }

  createPlan(isPlanFexlible: boolean, count): FormGroup {
    return this.fb.group({
      idFlexPlanPlanes: [null],  // Integer
      flexPlanCategoriesId: [null],  // Integer
      planName: ['PLAN ' + count, Validators.required],  // String
      flexiblePlan: [isPlanFexlible],  // Boolean
      quote: [0.0],  // Double
      premiumIndividual: [false],  // Double
      averagePremium: [0.0],  // Double
      netPremiumIndividual: [0.0],  // Double
      netPremiumGroup: [0.0],  // Double
      policyRights: [0.0],  // Double
      surcharges: [0.0],  // Double
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Integer
      updateUserId: [null],  // Integer
      status: [1],
      renewedPolicy: [false], // Boolean
      flexPlanCategoriesModel: this.fb.group({ // FormGroup para almacenar la categoria
        idFlexPlanCategories: [null],
        categoryName: [isPlanFexlible ? 'FLEX_CATEGORY_GMM_' + count : '', Validators.required],
        planFlexId: [this.idPlanFlex],
        flexibleProductId: [1],
        creationDate: [''],
        updateDate: [''],
        creationUserId: [''],
        updateUserId: ['']
      }),
      flexPlanCoveragesModels: this.fb.array([])  // FormArray para almacenar las coberturas
    });
  }

  onDestinationChange(selectedCoverages): void {
    this.specialCoverageListSelected = selectedCoverages;

    this.planPlanesModels.controls.forEach(planPlane => {
      const flexPlanCoveragesModels = planPlane.get('flexPlanCoveragesModels') as FormArray;

      this.addSelectedCoveragesToPlans(selectedCoverages);
    });
  }


  createEndorsement(coverageName: string, coverageId: number): FormGroup {
    return this.fb.group({
      idFlexPlanEndorsement: null,
      flexPlanCoverageId: null, // Este campo se llenará después de crear el planCoverage
      coverageName,
      value: [null, Validators.required],
      unitMeasurementId: [null, Validators.required],
      creationDate: null,
      updateDate: null,
      creationUserId: null,
      updateUserId: null
    });
  }

  removeCategory(planIndex: number, categoryIndex: number): void {
    const planCategories = this.planPlanesModels.at(planIndex).get('flexPlanCategoriesModel') as FormArray;
    planCategories.removeAt(categoryIndex);
  }

  loadCoveragesByIdProduct(): Promise<void> {
    return new Promise((resolve, reject) => {
      const data = {
        idProduct: 1,
        idPlan: null
      };
      this.api.getCoveragesByIdProduct(data)
        .then(
          (response: any) => {
            this.specialCoverageList = response;
            if (!this.isEdition) { // Los filtros solo se harán si el plan es nuevo
              this.filterAndOrderCoverages(this.specialCoverageList).then(orderedCoverages => {
                this.addSelectedCoveragesToPlans(orderedCoverages);
              });
            }
            resolve();
          }, error => {
            this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
            reject(error);
          }
        );
    });
  }


  filterAndOrderCoverages(specialCoverageList: any[]): Promise<any[]> {
    return new Promise((resolve) => {
      // Definir el orden deseado de las coberturas usando un arreglo de nombres en mayúsculas
      const desiredOrder = ['PLAN CONTRATADO', 'NIVEL HOSPITALARIO', 'TABULADOR MEDICO', 'SUMA ASEGURADA', 'DEDUCIBLE',
        'COASEGURO', 'TOPE DE COASEGURO', 'MATERNIDAD', 'TERRITORIALIDAD'];

      // Filtrar las coberturas
      const filteredCoverages = specialCoverageList.filter((coverage: any) => coverage.coverageType === 1);

      // Ordenarlas según el orden deseado
      const orderedCoverages = filteredCoverages.sort((a: any, b: any) => {
        const indexA = desiredOrder.indexOf(a.coverageName.toUpperCase());
        const indexB = desiredOrder.indexOf(b.coverageName.toUpperCase());
        return indexA - indexB;
      });

      resolve(orderedCoverages);
    });
  }

  addSelectedCoveragesToPlans(selectedCoverages: any[]): void {
    this.planPlanesModels.controls.forEach(planPlane => {
      this.specialCoverageListSelected = selectedCoverages
        .map(coverage => ({
          idCoverage: coverage.idCoverage,
          coverageName: coverage.coverageName
        }));

      this.processPlanPlane(planPlane, selectedCoverages);
    });
  }

  processPlanPlane(planPlane: any, selectedCoverages: any[]): void {
    const flexPlanCoveragesModels = planPlane.get('flexPlanCoveragesModels') as FormArray;

    selectedCoverages.forEach(coverage => {
      // Verificar si la cobertura ya existe en flexPlanCoveragesModels
      const exists = flexPlanCoveragesModels.controls.some(ctrl => ctrl.get('coverageSurexsId').value === coverage.idCoverage);

      if (!exists) {
        if (coverage.coverageType === 1) {
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.idCoverage,
            origen: 1,
            coverageName: coverage.coverageName,
            value: [null, Validators.required],
            unitMeasurementId: [null, Validators.required],
            endoso: false,
            flexible: false,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([]),
            optionsValues: coverage.optionsValues && coverage.optionsValues.length > 0
              ? this.fb.array(coverage.optionsValues.map(option => this.fb.group({
                value: [option.value],
                unitMeasurementId: [option.unitMeasurementId],
                defaultValue: [option.defaultValue],
                openValue: [option.openValue],
                order: [option.order],
                unitMeasurement: [option.unitMeasurement]
              }))) : this.fb.array([])
          }));
        } else if (coverage.coverageType === 2) {
          const coverageGroup = this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.idCoverage,
            origen: 1,
            coverageName: coverage.coverageName,
            value: ['-', Validators.required],
            unitMeasurementId: [10, Validators.required],
            endoso: true,
            flexible: false,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([
              this.createEndorsement('SUMA ASEGURADA', coverage.coverageId),
              this.createEndorsement('DEDUCIBLE', coverage.coverageId),
              this.createEndorsement('TOPE COASEGURO', coverage.coverageId),
              this.createEndorsement('PERIODO DE ESPERA', coverage.coverageId)
            ]),
            optionsValues: coverage.optionsValues && coverage.optionsValues.length > 0
              ? this.fb.array(coverage.optionsValues.map(option => this.fb.group({
                value: [option.value],
                unitMeasurementId: [option.unitMeasurementId],
                defaultValue: [option.defaultValue],
                openValue: [option.openValue],
                order: [option.order],
                unitMeasurement: [option.unitMeasurement]
              }))) : this.fb.array([])
          });

          flexPlanCoveragesModels.push(coverageGroup);
        }
      }
    });
  }

  loadUnitOptions() {
    this.api.getUnitMeasurement()
      .then(
        (response: any) => {
          this.planProductOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
        }
      );
  }

  getUnitOptions(): any[] {
    return this.planProductOptions;
  }

  onSubmit(): void {
    const dto = this.planFlexForm.value;
    console.log(dto);
  }

  getPlanInit(event) {
    this.planInit = event;
    // Asignación de los valores al FormGroup
    this.planFlexForm.patchValue({
      typePopulationPolicyId: event.typePopulationPolicyId,  // Convertir a número (Long)
      planReduction: event.planReduction,  // Boolean
      addSpouse: event.addSpouse,  // Boolean
      addChildren: event.numberOfChildren,  // Boolean
      addMother: event.addMother,  // Boolean
      addFather: event.addFather,  // Boolean
      typePlanProductId: 1
    });
  }

  removeCoverage(planIndex: number, coverageIndex: number) {
    // Obtener el FormArray de flexPlanCoveragesModels del plan correspondiente
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    const plan = planPlanesModels.at(planIndex) as FormGroup;
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    // Verificar que el FormArray flexPlanCoveragesModels exista antes de eliminar
    if (flexPlanCoveragesModels) {
      flexPlanCoveragesModels.removeAt(coverageIndex);
    } else {
      console.error('FormArray flexPlanCoveragesModels no existe');
    }
  }

  toggleSpecialCoverages() {
    this.showSpecialCoverages = !this.showSpecialCoverages;
  }


  openModalCoverage(templateCoverage: TemplateRef<any>, planIndex: number) {
    this.dataSourcesCoverages = [];
    this.selectedPlanIndex = planIndex; // Guardamos el índice del plan seleccionado

    // Obtenemos las coberturas actuales del plan y sus IDs
    const plan = this.getPlanAtIndex(planIndex);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;
    const planCoverageIds = flexPlanCoveragesModels.value.map((coverage: any) => coverage.coverageSurexsId); // IDs de coberturas actuales

    // Cargar las coberturas seleccionadas basadas en las que ya están en el plan
    this.dataSourcesCoverages = this.specialCoverageList.filter(coverage => planCoverageIds.includes(coverage.idCoverage));

    // tslint:disable-next-line:max-line-length
    this.modalRefAddCoveragePlan = this.modalService.show(templateCoverage,
      {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});

  }

  openModalCoverageByPlan(templateCoverage: TemplateRef<any>, planIndex: number) {
    this.dataSourcesCoverages = [];
    this.selectedPlanIndex = planIndex; // Guardamos el índice del plan seleccionado

    // Obtenemos las coberturas actuales del plan y sus IDs
    const plan = this.getPlanAtIndex(planIndex);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;
    this.specialCoverageListSelectedByPlan = flexPlanCoveragesModels.value
      .filter((coverage: any) => coverage.flexible === false) // Filtrar solo donde flexible es false
      .map((coverage: any) => ({
        coverageSurexsId: coverage.coverageSurexsId,
        coverageName: coverage.coverageName,
        endoso: coverage.endoso
      }));

    // Cargar las coberturas del plan que esten flexibilizadas
    this.dataSourcesCoverages = flexPlanCoveragesModels.value
      .filter((coverage: any) => coverage.flexible === true) // Filtrar solo donde flexible es false
      .map((coverage: any) => ({
        coverageSurexsId: coverage.coverageSurexsId,
        coverageName: coverage.coverageName,
        endoso: coverage.endoso
      }));

    this.modalRefAddCoveragePlan = this.modalService.show(templateCoverage,
      {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});

  }

  getPlanAtIndex(planIndex: number): FormGroup {
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    return planPlanesModels.at(planIndex) as FormGroup;
  }

  closeModalCoverage() {
    this.modalRefAddCoveragePlan.hide();
  }

  onCoverageByPlanChange(event: any) {
    const plan = this.getPlanAtIndex(this.selectedPlanIndex);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    // Iteramos sobre las coberturas seleccionadas en el dual-list
    event.forEach((coverage: any) => {
      // Verificar si la cobertura ya existe en flexPlanCoveragesModels
      const existingCoverage = flexPlanCoveragesModels.controls.some(
        (ctrl: any) => ctrl.get('coverageSurexsId').value === coverage.idCoverage);

      // Si no existe, la agregamos
      if (!existingCoverage) {
        if (coverage.coverageType === 1) {
          // Agregar cobertura de tipo 1
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.idCoverage,
            origen: 1,
            coverageName: coverage.coverageName,
            value: [null, Validators.required],
            unitMeasurementId: [null, Validators.required],
            endoso: false,
            flexible: false,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([])
          }));
        } else if (coverage.coverageType === 2) {
          // Agregar cobertura de tipo 2 con endorsements
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.idCoverage,
            origen: 1,
            coverageName: coverage.coverageName,
            value: ['-', Validators.required],
            unitMeasurementId: [10, Validators.required],
            endoso: true,
            flexible: false,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([
              this.createEndorsement('SUMA ASEGURADA', coverage.idCoverage),
              this.createEndorsement('DEDUCIBLE', coverage.idCoverage),
              this.createEndorsement('TOPE COASEGURO', coverage.idCoverage),
              this.createEndorsement('PERIODO DE ESPERA', coverage.idCoverage)
            ])
          }));
        }
      }
    });

    // Actualizamos el array `dataSourcesCoverages` para reflejar las coberturas seleccionadas
    this.dataSourcesCoverages = event;
  }

  onCoverageFlexByPlanChange(event: any) {
    const plan = this.getPlanAtIndex(this.selectedPlanIndex);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    // Iteramos sobre las coberturas seleccionadas en el dual-list
    event.forEach((coverage: any) => {
      // Verificar si la cobertura ya existe en flexPlanCoveragesModels
      const existingCoverage = flexPlanCoveragesModels.controls.some((ctrl: any) =>
        ctrl.get('coverageSurexsId').value === coverage.coverageSurexsId && ctrl.get('flexible').value === true);

      // Si no existe, la agregamos
      if (!existingCoverage) {
        if (!coverage.endoso) {
          // Agregar cobertura de tipo 1
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.coverageSurexsId,
            origen: 1,
            coverageName: coverage.coverageName,
            value: [null, Validators.required],
            unitMeasurementId: [null, Validators.required],
            endoso: false,
            flexible: true,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([])
          }));
        } else if (coverage.endoso) {
          // Agregar cobertura de tipo 2 con endorsements
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.coverageSurexsId,
            origen: 1,
            coverageName: coverage.coverageName,
            value: ['-', Validators.required],
            unitMeasurementId: [10, Validators.required],
            endoso: true,
            flexible: true,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([
              this.createEndorsement('SUMA ASEGURADA', coverage.idCoverage),
              this.createEndorsement('DEDUCIBLE', coverage.idCoverage),
              this.createEndorsement('TOPE COASEGURO', coverage.idCoverage),
              this.createEndorsement('PERIODO DE ESPERA', coverage.idCoverage)
            ])
          }));
        }
      }
    });

    // Actualizamos el array `dataSourcesCoverages` para reflejar las coberturas seleccionadas
    this.dataSourcesCoverages = event;
  }

  removePlan(planIndex: number) {
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;

    if (planPlanesModels && planPlanesModels.length > planIndex) {
      planPlanesModels.removeAt(planIndex);
    }
  }

  trackByCoverageId(index: number, coverageGroup: AbstractControl[]): any {
    return coverageGroup.length > 0 ? coverageGroup[0].get('coverageSurexsId').value : null;
  }


  groupFlexCoveragesById(flexPlanCoveragesModels: FormArray): { groupId: string, controls: AbstractControl[] }[] {
    if (!flexPlanCoveragesModels || !flexPlanCoveragesModels.controls) {
      return []; // Si no hay coberturas, devolvemos un array vacío
    }

    const grouped = flexPlanCoveragesModels.controls.reduce((acc: { [key: string]: AbstractControl[] }, curr: AbstractControl) => {
      if (curr.get('flexible').value === true) {  // Agrupamos solo si esFactor es true
        const id = curr.get('coverageSurexsId').value;

        // Si no existe el grupo, lo creamos
        if (!acc[id]) {
          acc[id] = [];
        }

        // Añadimos la cobertura al grupo correspondiente
        acc[id].push(curr);
      }

      return acc;
    }, {});

    // Convertimos el objeto agrupado en un array de objetos con groupId y controls
    return Object.keys(grouped).map(groupId => ({groupId, controls: grouped[groupId]}));
  }


  clonePlan(planIndex: number): void {
    // Obtenemos el FormArray que contiene todos los planes
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;

    // Obtenemos el plan en el índice especificado
    const planToClone = planPlanesModels.at(planIndex) as FormGroup;

    // Creamos un nuevo FormGroup basado en los valores del plan a clonar
    const clonedPlan = this.fb.group({
      idFlexPlanPlanes: [''],
      planName: ['', Validators.required],
      flexPlanCategoriesId: [''],
      flexiblePlan: true,
      quote: [planToClone.get('quote').value],
      premiumIndividual: [planToClone.get('premiumIndividual').value],
      averagePremium: [planToClone.get('averagePremium').value],
      netPremiumIndividual: [planToClone.get('netPremiumIndividual').value],
      netPremiumGroup: [planToClone.get('netPremiumGroup').value],
      policyRights: [planToClone.get('policyRights').value],
      surcharges: [planToClone.get('surcharges').value],
      creationDate: [planToClone.get('creationDate').value],
      updateDate: [planToClone.get('updateDate').value],
      creationUserId: [planToClone.get('creationUserId').value],
      updateUserId: [planToClone.get('updateUserId').value],
      status: [planToClone.get('status').value],
      renewedPolicy: false,
      flexPlanCategoriesModel: this.fb.group({
        idFlexPlanCategories: [null],
        categoryName: ['FLEX_CATEGORY_GMM_' + (planPlanesModels.length + 1)],
        planFlexId: [planToClone.get('flexPlanCategoriesModel').get('planFlexId').value],
        flexibleProductId: [planToClone.get('flexPlanCategoriesModel').get('flexibleProductId').value],
        creationDate: [planToClone.get('flexPlanCategoriesModel').get('creationDate').value],
        updateDate: [planToClone.get('flexPlanCategoriesModel').get('updateDate').value],
        creationUserId: [planToClone.get('flexPlanCategoriesModel').get('creationUserId').value],
        updateUserId: [planToClone.get('flexPlanCategoriesModel').get('updateUserId').value]
      }),
      flexPlanCoveragesModels: this.cloneCoverages(planToClone.get('flexPlanCoveragesModels') as FormArray)
    });

    // Añadimos el plan clonado al FormArray
    planPlanesModels.push(clonedPlan);
  }

  cloneCoverages(flexPlanCoveragesModels: FormArray): FormArray {
    const coverages = this.fb.array([]);

    // Iteramos sobre cada cobertura para clonarla
    flexPlanCoveragesModels.controls.forEach((coverage: AbstractControl) => {
      const clonedCoverage = this.fb.group({
        idFlexPlanCoverages: [''],
        flexPlanPlanesId: [''],
        coverageSurexsId: [coverage.get('coverageSurexsId').value],
        origen: [coverage.get('origen').value],
        coverageName: [coverage.get('coverageName').value],
        value: [coverage.get('value').value],
        unitMeasurementId: [coverage.get('unitMeasurementId').value],
        endoso: [coverage.get('endoso').value],
        flexible: [coverage.get('flexible').value],
        factorQuote: [coverage.get('factorQuote').value],
        creationDate: [coverage.get('creationDate').value],
        updateDate: [coverage.get('updateDate').value],
        creationUserId: [coverage.get('creationUserId').value],
        updateUserId: [coverage.get('updateUserId').value],
        endorsements: this.cloneEndorsements(coverage.get('endorsements') as FormArray),
        optionsValues: coverage.get('optionsValues') && (coverage.get('optionsValues') as FormArray).length > 0
          ? this.fb.array((coverage.get('optionsValues') as FormArray).controls.map(option => this.fb.group({
            value: [option.get('value').value],
            unitMeasurementId: [option.get('unitMeasurementId').value],
            defaultValue: [option.get('defaultValue').value],
            openValue: [option.get('openValue').value],
            order: [option.get('order').value],
            unitMeasurement: [option.get('unitMeasurement').value]
          }))) : this.fb.array([])
      });

      coverages.push(clonedCoverage);
    });

    return coverages;
  }

  cloneEndorsements(endorsements: FormArray): FormArray {
    const clonedEndorsements = this.fb.array([]);

    // Clonamos cada endoso
    endorsements.controls.forEach((endorsement: AbstractControl) => {
      const clonedEndorsement = this.fb.group({
        idFlexPlanEndorsement: [''],
        flexPlanCoverageId: [''],  // Se llenará después
        coverageName: [endorsement.get('coverageName').value],
        value: [endorsement.get('value').value],
        unitMeasurementId: [endorsement.get('unitMeasurementId').value],
        creationDate: [endorsement.get('creationDate').value],
        updateDate: [endorsement.get('updateDate').value],
        creationUserId: [endorsement.get('creationUserId').value],
        updateUserId: [endorsement.get('updateUserId').value]
      });

      clonedEndorsements.push(clonedEndorsement);
    });

    return clonedEndorsements;
  }

  createPlanFlex(): void {
    // Obtenemos el FormArray que contiene todos los planes
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;

    // Añadimos el nuevo plan al FormArray
    const planFormGroup = this.createPlan(true, (planPlanesModels.length + 1));

    this.filterAndOrderCoverages(this.specialCoverageList).then(orderedCoverages => {
      this.processPlanPlane(planFormGroup, orderedCoverages);
    });
    planPlanesModels.push(planFormGroup);
  }

  savePlanGmm(): void {
    this.spinner.show('sp');

    if (!this.planFlexForm.valid) {
      this.planFlexForm.markAllAsTouched();
      this.spinner.hide('sp');
      this.toastr.warning('Por favor, llena todos los datos', 'Notificación');
      return;
    }

    const formCopy = {
      ...this.planFlexForm.getRawValue(), // Usamos getRawValue para incluir valores deshabilitados
      planPlanesModels: this.planFlexForm.getRawValue().planPlanesModels.map(plan => ({
        ...plan,
        flexPlanCoveragesModels: plan.flexPlanCoveragesModels.map(coverage => {
          // Eliminamos el campo optionsValues
          const { optionsValues, ...coverageWithoutOptions } = coverage;
          return coverageWithoutOptions;
        })
      }))
    };

    if (!this.isEdition) {
      this.api.savePlanGMM(formCopy)
        .then(
          (response: any) => {
            this.planFlexForm.reset();
            this.isPlanOnEdition(response);
            this.populateFormWithResponseData(response);
            this.isEdition = true;
            this.spinner.hide('sp');
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');

          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    } else {
      this.api.editPlanGMMById(formCopy)
        .then(
          (response: any) => {
            this.planFlexForm.reset();
            this.isPlanOnEdition(response);
            this.populateFormWithResponseData(response);
            this.spinner.hide('sp');
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');

          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    }

  }

  getEndorsementLabel(index: number): string {
    const labels = ['SUMA ASEGURADA', 'DEDUCIBLE', 'COASEGURO', 'PERIODO DE ESPERA'];
    return labels[index] || 'Valor';
  }

  getPlanGMMByIdPlanFlex(): void {
    this.spinner.show('sp');
    this.api.getPlanGMMById(this.planFlexForm.get('planFlexId').value)
      .then(
        (response: any) => {

          if (response.planPlanesModels && response.planPlanesModels.length > 0) {
            this.isEdition = true;
            this.loadCoveragesByIdProduct().then(() => {
              this.populateFormWithResponseData(response);
              this.spinner.hide('sp');
            });
          } else {
            this.isEdition = false;
            // this.populateFormWithResponseData(response);
          }

          if (this.isEdition) {
            for (const plan of response.planPlanesModels) {
              if (plan.status === 3) {
                this.planAddCar = 1;
                return;
              }
            }

            switch (response.productPlanFlexConfiguration) {
              case 2:
                this.messageProduct = 'YA SE HA GENERADO SLIP, SI REALIZA CAMBIOS DEBE DE GENERARLO DE NUEVO PARA SU COTIZACIÓN';
                this.modalRef = this.modalService.show(this.myTemplate, {
                  class: 'modal-md',
                  keyboard: false,
                  backdrop: 'static'
                });
                break;
              case 3:
                if (this.planAddCar === 0) {
                  // tslint:disable-next-line:max-line-length
                  this.messageProduct = 'YA SE ENCUENTRAN CONFIGURADAS LAS TARIFAS SI USTED HACE UN CAMBIO SE BORRARAN Y TENDRÁ QUE GENERAR SLIP PARA COTIZACIÓN Y VOLVER A INGRESAR TARIFAS';
                } else {
                  // tslint:disable-next-line:max-line-length
                  this.messageProduct = 'YA NO SE PUEDEN REALIZAR CAMBIOS, UN PLAN YA SE ENCUENTRA EN PROCESO DE COMPRA';
                }
                this.modalRef = this.modalService.show(this.myTemplate, {
                  class: 'modal-md',
                  keyboard: false,
                  backdrop: 'static'
                });
                break;

              default:
                break;
            }

            // Asignación a specialCoverageListSelected
            this.specialCoverageListSelected = this.planFlexForm.get('planPlanesModels').value
              .flatMap(plan => plan.flexPlanCoveragesModels)  // Obtén todos los elementos de flexPlanCoveragesModels
              .map(coverage => ({
                idCoverage: coverage.coverageSurexsId,
                coverageName: coverage.coverageName
              }));
          }

          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  isPlanOnEdition(data: any) {

  }

  populateFormWithResponseData(response: any): void {
    // Asignar directamente los valores principales del formulario
    this.planFlexForm.patchValue({
      idFlexPlanGmm: response.idFlexPlanGmm,
      planFlexId: response.planFlexId,
      typePopulationPolicyId: response.typePopulationPolicyId,
      typeConfigurationPolicyId: response.typeConfigurationPolicyId,
      addSpouse: response.addSpouse,
      addChildren: response.addChildren,
      addMother: response.addMother,
      addFather: response.addFather,
      typePlanProductId: response.typePlanProductId,
      planReduction: response.planReduction,
      creationDate: response.creationDate,
      updateDate: response.updateDate,
      creationUserId: response.creationUserId,
      updateUserId: response.updateUserId
    });

    // Limpiar el FormArray antes de agregar nuevos planes
    const planPlanesModelsArray = this.planFlexForm.get('planPlanesModels') as FormArray;
    planPlanesModelsArray.clear();

    // Iterar sobre los planes y agregarlos al FormArray
    response.planPlanesModels.forEach((plan: any, index: number) => {
      const planFormGroup = this.createPlan(plan.flexiblePlan, index + 1);

      // Asignar valores al plan
      planFormGroup.patchValue({
        idFlexPlanPlanes: plan.idFlexPlanPlanes,
        flexPlanCategoriesId: plan.flexPlanCategoriesId,
        planName: plan.planName,
        flexiblePlan: plan.flexiblePlan,
        quote: plan.quote,
        premiumIndividual: plan.premiumIndividual,
        averagePremium: plan.averagePremium,
        netPremiumIndividual: plan.netPremiumIndividual,
        netPremiumGroup: plan.netPremiumGroup,
        policyRights: plan.policyRights,
        surcharges: plan.surcharges,
        renewedPolicy: plan.renewedPolicy,
        creationDate: plan.creationDate,
        updateDate: plan.updateDate,
        creationUserId: plan.creationUserId,
        updateUserId: plan.updateUserId,
      });

      // Asignar valores a la categoría del plan
      planFormGroup.get('flexPlanCategoriesModel').patchValue({
        idFlexPlanCategories: plan.flexPlanCategoriesModel.idFlexPlanCategories,
        categoryName: plan.flexPlanCategoriesModel.categoryName,
        planFlexId: plan.flexPlanCategoriesModel.planFlexId,
        flexibleProductId: plan.flexPlanCategoriesModel.flexibleProductId,
        creationDate: plan.flexPlanCategoriesModel.creationDate,
        updateDate: plan.flexPlanCategoriesModel.updateDate,
        creationUserId: plan.flexPlanCategoriesModel.creationUserId,
        updateUserId: plan.flexPlanCategoriesModel.updateUserId
      });

      // Asignar coberturas al plan
      const flexPlanCoveragesModelsArray = planFormGroup.get('flexPlanCoveragesModels') as FormArray;
      plan.flexPlanCoveragesModels.forEach((coverage: any) => {
        const coverageGroup = this.fb.group({
          idFlexPlanCoverages: coverage.idFlexPlanCoverages,
          flexPlanPlanesId: coverage.flexPlanPlanesId,
          coverageSurexsId: coverage.coverageSurexsId,
          origen: coverage.origen,
          coverageName: coverage.coverageName,
          value: [coverage.value, Validators.required],
          unitMeasurementId: [coverage.unitMeasurementId, Validators.required],
          endoso: coverage.endoso,
          flexible: coverage.flexible,
          factorQuote: coverage.factorQuote,
          creationDate: coverage.creationDate,
          updateDate: coverage.updateDate,
          creationUserId: coverage.creationUserId,
          updateUserId: coverage.updateUserId,
          endorsements: this.fb.array(coverage.endorsements.map((endorsement: any) => this.fb.group({
            idFlexPlanEndorsement: endorsement.idFlexPlanEndorsement,
            flexPlanCoverageId: endorsement.flexPlanCoverageId,
            coverageName: endorsement.coverageName,
            value: [endorsement.value, Validators.required],
            unitMeasurementId: [endorsement.unitMeasurementId, Validators.required],
            creationDate: endorsement.creationDate,
            updateDate: endorsement.updateDate,
            creationUserId: endorsement.creationUserId,
            updateUserId: endorsement.updateUserId
          })))
        });

        // TODO QUITAR PORQUE TAMBIÉN LO PUEDE USAR PLANES BASE
        if (this.isEdition) {
          const specialCoverage = this.specialCoverageList.find(sc => sc.coverageName === coverage.coverageName);

          // Si existe una coincidencia, agregar optionsValues si están definidos
          if (specialCoverage && specialCoverage.optionsValues && specialCoverage.optionsValues.length > 0) {
            const optionsValuesArray = this.fb.array(specialCoverage.optionsValues.map(option => this.fb.group({
              value: [option.value],
              unitMeasurementId: [option.unitMeasurementId],
              defaultValue: [option.defaultValue],
              openValue: [option.openValue],
              order: [option.order],
              unitMeasurement: [option.unitMeasurement]
            })));
            coverageGroup.addControl('optionsValues', optionsValuesArray);
          }

        }

        this.poblationType = {
          typePopulationPolicyId: this.planFlexForm.get('typePopulationPolicyId').value,
          typePlanProductId: this.planFlexForm.get('typePlanProductId').value,
          planReduction: this.planFlexForm.get('planReduction').value,
          addSpouse: this.planFlexForm.get('addSpouse').value,
          addChildren: this.planFlexForm.get('addChildren').value,
          addMother: this.planFlexForm.get('addMother').value,
          addFather: this.planFlexForm.get('addFather').value
        };

        // Agregar cobertura al FormArray
        flexPlanCoveragesModelsArray.push(coverageGroup);

        // Deshabilitar cobertura si tiene endorsements y el plan es flexible
        if (!plan.flexiblePlan ) {
          coverageGroup.disable();
        }
      });

      // Deshabilitar el plan completo si es flexible y tiene al menos una cobertura con endorsements
      // tslint:disable-next-line:max-line-length
      if (!plan.flexiblePlan && flexPlanCoveragesModelsArray.controls.some((coverage: FormGroup) => coverage.get('endorsements').value.length > 0)) {
        planFormGroup.disable();
      }

      // Agregar plan al FormArray de planes
      planPlanesModelsArray.push(planFormGroup);
    });

    console.log('Así se llenó' + this.planFlexForm.value);
  }

  addCoverage(indexPlan: number, flexPlanCoverages: FormGroup): void {
    const plan = this.getPlanAtIndex(indexPlan);
    console.log('el index ', indexPlan);
    console.log('el objeto ', flexPlanCoverages);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    if (!flexPlanCoverages.get('endoso').value) {
      // Agregar cobertura de tipo 1
      flexPlanCoveragesModels.push(this.fb.group({
        idFlexPlanCoverages: null,
        flexPlanPlanesId: null,
        coverageSurexsId: flexPlanCoverages.get('coverageSurexsId').value,
        origen: 1,
        coverageName: flexPlanCoverages.get('coverageName').value,
        value: [null, Validators.required],
        unitMeasurementId: [null, Validators.required],
        endoso: false,
        flexible: true,
        factorQuote: null,
        creationDate: null,
        updateDate: null,
        creationUserId: null,
        updateUserId: null,
        endorsements: this.fb.array([])
      }));
    } else if (flexPlanCoverages.get('endoso').value) {
      // Agregar cobertura de tipo 2 con endorsements
      flexPlanCoveragesModels.push(this.fb.group({
        idFlexPlanCoverages: null,
        flexPlanPlanesId: null,
        coverageSurexsId: flexPlanCoverages.get('coverageSurexsId').value,
        origen: 1,
        coverageName: flexPlanCoverages.get('coverageName').value,
        value: '-',
        unitMeasurementId: 10,
        endoso: true,
        flexible: true,
        factorQuote: null,
        creationDate: null,
        updateDate: null,
        creationUserId: null,
        updateUserId: null,
        endorsements: this.fb.array([
          this.createEndorsement('SUMA ASEGURADA', 0),
          this.createEndorsement('DEDUCIBLE', 0),
          this.createEndorsement('TOPE COASEGURO', 0),
          this.createEndorsement('PERIODO DE ESPERA', 0)
        ])
      }));
    }
  }

  removeCoverageFlex(indexPlan: number, indexCoverage: number): void {
    const plan = this.getPlanAtIndex(indexPlan);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    if (flexPlanCoveragesModels) {
      flexPlanCoveragesModels.removeAt(indexCoverage);
    }
  }


  getSlip(): void {
    this.spinner.show('sp');
    this.api.getSlip(this.idPlanFlex)
      .then(
        (response: any) => {
          const base64Data = response.slip;
          const binaryData = atob(base64Data);  // Decodificar Base64 a binario
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uintArray = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryData.length; i++) {
            uintArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uintArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', 'SurexsSlip.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.spinner.hide('sp');
        }, (error) => {
          this.spinner.hide('sp');
        });

  }

  getFilteredCoverages(plan: FormGroup): any[] {
    const coverages = plan.get('flexPlanCoveragesModels') as FormArray;
    return coverages.controls.filter(cov =>
      !cov.get('endoso').value && !cov.get('flexible').value
    );
  }

  get categoriesForDropdown(): Array<{ idFlexPlanCategories: number, categoryName: string }> {
    return this.getCategoriesForDropdown(this.planFlexForm.get('planPlanesModels') as FormArray);
  }

  getCategoriesForDropdown(plansArray: FormArray): Array<{ idFlexPlanCategories: number, categoryName: string }> {
    return plansArray.controls
      .filter((planGroup: FormGroup) => {
        const flexiblePlan = planGroup.get('flexiblePlan').value === false;
        const category = planGroup.get('flexPlanCategoriesModel').value;
        const idDefined = category.idFlexPlanCategories !== null && category.idFlexPlanCategories !== undefined;
        return flexiblePlan && idDefined;
      })
      .map((planGroup: FormGroup) => {
        const category = planGroup.get('flexPlanCategoriesModel').value;
        return {
          idFlexPlanCategories: category.idFlexPlanCategories,
          categoryName: category.categoryName
        };
      });
  }

  hasInvalidPlans(): boolean {
    return (this.planFlexForm.get('planPlanesModels') as FormArray).controls.some((planGroup: FormGroup) => {
      const idFlexPlanPlanes = planGroup.get('idFlexPlanPlanes').value;
      const flexiblePlan = planGroup.get('flexiblePlan').value;
      return  flexiblePlan === false && (idFlexPlanPlanes === null || idFlexPlanPlanes === undefined || idFlexPlanPlanes === '');
    });
  }

  validateCategory(event, index: number) {
    const selectedCategoryId = event.target.value; // Convertimos el valor a número
    const plansArray = this.planFlexForm.get('planPlanesModels') as FormArray;

    // Busca la categoría seleccionada en la lista de categorías disponibles
    const selectedCategory = this.categoriesForDropdown.find(category => category.idFlexPlanCategories === selectedCategoryId);

    if (selectedCategory) {
      // Obtén el FormGroup del plan correspondiente según el índice
      const planGroup = plansArray.at(index) as FormGroup;

      // Actualiza los valores en el FormGroup del plan correspondiente
      planGroup.get('flexPlanCategoriesModel').patchValue({
        idFlexPlanCategories: selectedCategory.idFlexPlanCategories,
        categoryName: selectedCategory.categoryName,
        planFlexId: this.idPlanFlex,
        flexibleProductId: 1
      });

      // Si necesitas también actualizar el ID de la categoría en el nivel superior
      planGroup.get('flexPlanCategoriesId').setValue(selectedCategory.idFlexPlanCategories);
    }
  }

  validatePlan(): boolean {
    const plansArray = this.planFlexForm.get('planPlanesModels') as FormArray;

    // Verifica si planPlanesModels está vacío o indefinido
    if (!plansArray || plansArray.length === 0) {
      return false;
    }

    if (plansArray.controls.some(plan => {
      const idFlexPlanPlanes = plan.get('idFlexPlanPlanes') ? plan.get('idFlexPlanPlanes').value : null;
      return idFlexPlanPlanes === null || idFlexPlanPlanes === undefined || idFlexPlanPlanes === '';
    })) {
      return false;
    }

     // Verifica si todos los elementos tienen flexiblePlan en false
    if (this.planFlexForm.get('typeConfigurationPolicyId').value === '2' ||
      this.planFlexForm.get('typeConfigurationPolicyId').value === 2)  {
      if (
        plansArray.controls.some(plan =>
          plan.get('flexPlanCoveragesModels').value.some(coverage =>
            coverage.flexible === true &&
            (coverage.idFlexPlanCoverages === null || coverage.idFlexPlanCoverages === undefined)
          )
        )
      ) {
        return false;
      }

    } else {
      if (plansArray.controls.every(plan => plan.get('flexiblePlan') && plan.get('flexiblePlan').value === false)) {
        return false;
      }
    }


    // Si pasa todas las verificaciones anteriores, devuelve true
    return true;
  }
  onOptionValueChange(event: any, coverage: AbstractControl, coverageIndex: number, templateCoverage: TemplateRef<any>) {
    const selectedValue = event.target.value;

    // Encontrar la opción seleccionada
    const selectedOption = coverage.get('optionsValues').value.find((option: any) => option.value === selectedValue);

    if (selectedOption) {
      this.selectedOption = selectedOption;
      coverage.get('unitMeasurementId').setValue(Number(selectedOption.unitMeasurementId));
    }

    // Si la opción tiene openValue en true, abrir el modal
    if (selectedOption.openValue === true) {
      // Asignar las variables de índice y cobertura actual
      this.currentCoverageIndex = coverageIndex;
      this.currentCoverage = coverage;

      // Abrir el modal
      this.openValueModal(templateCoverage);
    }
  }

  // Método para abrir el modal
  openValueModal(templateCoverage: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateCoverage, { initialState: { title: 'Ingrese un valor personalizado' }, class: 'modal-sm' });
  }

  // Método para guardar el valor y cerrar
  onSave(coverageIndex: number, coverage: AbstractControl): void {
    this.addManualValueToSelect(coverageIndex, coverage); // Agregar el valor ingresado
    this.modalRef.hide(); // Cerrar el modal
  }

  // Método para agregar el valor personalizado al select
  addManualValueToSelect(coverageIndex: number, coverage: AbstractControl) {
    const currentOptions = coverage.get('optionsValues').value;
    const newOption = { value: `GUA + ${this.manualValue} + %`, openValue: false };
    currentOptions.push(newOption);
    this.optionsValuesMap[coverageIndex] = currentOptions;
    coverage.get('value').setValue(newOption.value);
  }

  // Método para cerrar el modal
  onCloseModal(): void {
    this.modalRef.hide();
  }

  invokeFunctionBackDetailPlan() {
    this.router.navigate(['/admin/plans-detalle/', this.idPlanFlex]);
  }
}
