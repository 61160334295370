import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalService} from 'ngx-bootstrap/modal';
import {regex} from '../../common/regex';
import {Dates} from '../plans-carga-poblacion/dates.model';

@Component({
  selector: 'app-flex-excess-p2-view',
  templateUrl: './flex-excess-p2-view.component.html',
  styleUrls: ['./flex-excess-p2-view.component.css']
})
export class FlexExcessP2ViewComponent implements OnInit {

  specialCoverageList = [];
  planFlexForm: FormGroup;
  idPlanFlex;
  isEdition = false;
  planPlanesModels: FormArray;
  planProductOptions: any = [];
  insuranceListDetail: any = [];
  showSpecialCoverages = false;
  isLoadTarrifManual;
  selectedPlanIndex; // Para saber qué plan está seleccionando el usuario
  isTariffSectionVisible: boolean[] = [];
  allCoverages: FlexPlanCoveragesModel[] = [];
  error = {active: false, msg: ''};
  public fileUpload: File;
  public fileUploadFactor: File;
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  isPoblation: boolean;
  dates: Dates;
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private shared: SharedService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private cd: ChangeDetectorRef) {
    this.activatedRoute.params.subscribe(params => {
      this.idPlanFlex = params.idPlan;
    });
    this.planFlexForm = this.fb.group({
      idFlexPlanGmm: [null],  // Long
      planFlexId: [this.idPlanFlex],  // Long
      typePopulationPolicyId: [null],  // Long
      typeConfigurationPolicyId: [null],  // Long
      addSpouse: [false],  // Boolean
      addChildren: [0],  // Boolean
      addMother: [false],  // Boolean
      addFather: [false],  // Boolean
      typePlanProductId: [null],  // Long
      planReduction: [0.0],  // Double
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Long
      updateUserId: [null],  // Long

      planPlanesModels: this.fb.array([])
    });

    this.planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
  }




  ngOnInit() {
    this.loadCoveragesByIdProduct();
    this.loadUnitOptions();
    this.getPlanByIdPlanFlex();
    this.getInsuranceCompanies();

    this.dates = {
      edadMaximaAnios: 0,
      edadMaximaMeses: 0,
      edadMinimaAnios: 0,
      edadMinimaMeses: 0,
      edadMaximaRenovacionAnios: 0,
      edadMaximaRenovacionMeses: 0,
      edadMinimaRenovacionAnios: 0,
      edadMinimaRenovacionMeses: 0
    };

  }

  loadUnitOptions() {
    this.api.getUnitMeasurement()
      .then(
        (response: any) => {
          this.planProductOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
        }
      );
  }

  getPlanByIdPlanFlex(): void {
    this.spinner.show('sp');
    this.api.getPlanById(this.planFlexForm.get('planFlexId').value, false)
      .then(
        (response: any) => {
          this.populateFormWithResponseData(response);

          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  populateFormWithResponseData(response: any): void {
    this.planFlexForm.reset();
    this.allCoverages = [];


    // Limpiar el FormArray antes de agregar nuevos planes
    const planPlanesModelsArray = this.planFlexForm.get('planPlanesModels') as FormArray;
    planPlanesModelsArray.clear();

    // Iterar sobre los planes y agregarlos al FormArray
    response.planPlanesModels.forEach((plan: any, index: number) => {

      const planFormGroup = this.createPlan(plan.flexiblePlan, index + 1);
      const planId = plan.idFlexPlanPlanes;

      // Asignar valores al plan
      planFormGroup.patchValue({
        idFlexPlanPlanes: plan.idFlexPlanPlanes,
        flexPlanCategoriesId: plan.flexPlanCategoriesId,
        planName: plan.planName,
        flexiblePlan: plan.flexiblePlan,
        quote: plan.quote,
        premiumIndividual: plan.premiumIndividual,
        averagePremium: plan.averagePremium,
        netPremiumIndividual: plan.netPremiumIndividual,
        netPremiumGroup: plan.netPremiumGroup,
        policyRights: plan.policyRights,
        surcharges: plan.surcharges,
        insuranceCompanyId: plan.insuranceCompanyId,
        creationDate: plan.creationDate,
        updateDate: plan.updateDate,
        creationUserId: plan.creationUserId,
        updateUserId: plan.updateUserId,
        renewedPolicy: plan.renewedPolicy,
        maximumYearsPolicy: plan.maximumYearsPolicy,
        maximumMonthsPolicy: plan.maximumMonthsPolicy,
        minimumYearsPolicy: plan.minimumYearsPolicy,
        minimumMonthsPolicy: plan.minimumMonthsPolicy,
        maximumYearsRenewal: plan.maximumYearsRenewal,
        maximumMonthsRenewal: plan.maximumMonthsRenewal,
        minimumYearsRenewal: plan.minimumYearsRenewal,
        minimumMonthsRenewal: plan.minimumMonthsRenewal
      });

      // Asignar los valores a this.dates
      this.dates = {
        edadMaximaAnios: plan.maximumYearsPolicy,
        edadMaximaMeses: plan.maximumMonthsPolicy,
        edadMinimaAnios: plan.minimumYearsPolicy,
        edadMinimaMeses: plan.minimumMonthsPolicy,
        edadMaximaRenovacionAnios: plan.maximumYearsRenewal,
        edadMaximaRenovacionMeses: plan.maximumMonthsRenewal,
        edadMinimaRenovacionAnios: plan.minimumYearsRenewal,
        edadMinimaRenovacionMeses: plan.minimumMonthsRenewal
      };

      // Asignar valores a la categoría del plan
      planFormGroup.get('flexPlanCategoriesModel').patchValue({
        idFlexPlanCategories: plan.flexPlanCategoriesModel.idFlexPlanCategories,
        categoryName: plan.flexPlanCategoriesModel.categoryName,
        planFlexId: plan.flexPlanCategoriesModel.planFlexId,
        flexibleProductId: plan.flexPlanCategoriesModel.flexibleProductId,
        creationDate: plan.flexPlanCategoriesModel.creationDate,
        updateDate: plan.flexPlanCategoriesModel.updateDate,
        creationUserId: plan.flexPlanCategoriesModel.creationUserId,
        updateUserId: plan.flexPlanCategoriesModel.updateUserId
      });


      // Asignar coberturas al plan
      const flexPlanCoveragesModelsArray = planFormGroup.get('flexPlanCoveragesModels') as FormArray;

      plan.flexPlanCoveragesModels.forEach((coverage: any) => {
        const coverageGroup = this.fb.group({
          idFlexPlanCoverages: coverage.idFlexPlanCoverages,
          flexPlanPlanesId: coverage.flexPlanPlanesId,
          coverageSurexsId: coverage.coverageSurexsId,
          origen: coverage.origen,
          coverageName: coverage.coverageName,
          value: coverage.value,
          unitMeasurementId: coverage.unitMeasurementId,
          endoso: coverage.endoso,
          flexible: coverage.flexible,
          factorQuote: [coverage.factorQuote, [Validators.pattern(regex.onlydecimanes)]],
          /*endorsements: this.fb.array(coverage.endorsements.map((endorsement: any) => this.fb.group({
            idFlexPlanEndorsement: endorsement.idFlexPlanEndorsement,
            flexPlanCoverageId: endorsement.flexPlanCoverageId,
            coverageName: endorsement.coverageName,
            value: endorsement.value,
            unitMeasurementId: endorsement.unitMeasurementId,
          })))*/
        });

        // Usa `setValue`
        coverageGroup.setValue({
          idFlexPlanCoverages: coverage.idFlexPlanCoverages,
          flexPlanPlanesId: coverage.flexPlanPlanesId,
          coverageSurexsId: coverage.coverageSurexsId,
          origen: coverage.origen,
          coverageName: coverage.coverageName,
          value: coverage.value,
          unitMeasurementId: coverage.unitMeasurementId,
          endoso: coverage.endoso,
          flexible: coverage.flexible,
          factorQuote: coverage.factorQuote,
          /*endorsements: coverage.endorsements.map((endorsement: any) => ({
            idFlexPlanEndorsement: endorsement.idFlexPlanEndorsement,
            flexPlanCoverageId: endorsement.flexPlanCoverageId,
            coverageName: endorsement.coverageName,
            value: endorsement.value,
            unitMeasurementId: endorsement.unitMeasurementId,
          }))*/
        });

        const specialCoverage = this.specialCoverageList.find(sc => sc.coverageName === coverage.coverageName);

        // Si existe una coincidencia, agregar optionsValues si están definidos
        if (specialCoverage && specialCoverage.optionsValues && specialCoverage.optionsValues.length > 0) {
          const optionsValuesArray = this.fb.array(specialCoverage.optionsValues.map(option => this.fb.group({
            value: [option.value],
            unitMeasurementId: [option.unitMeasurementId],
            defaultValue: [option.defaultValue],
            openValue: [option.openValue],
            order: [option.order],
            unitMeasurement: [option.unitMeasurement]
          })));
          coverageGroup.addControl('optionsValues', optionsValuesArray);
        }

        coverageGroup.disable();
        // Agregar la cobertura al FormArray
        flexPlanCoveragesModelsArray.push(coverageGroup);

      });

      // Asignar tarifas a la categoría del plan
      const categoryRatesArray = planFormGroup.get('categoryRates') as FormArray;

      // Verificar si plan.categoryRates tiene datos antes de iterar
      if (plan.categoryRates && plan.categoryRates.length > 0) {
        plan.categoryRates.forEach((rate: any) => {
          const rateGroup = this.fb.group({
            idFlexPlanCategoryRates: [rate.idFlexPlanCategoryRates],  // Integer
            flexPlanPlanesId: [rate.flexPlanPlanesId],  // Integer
            minimumAge: [rate.minimumAge],  // Integer
            maximumAge: [rate.maximumAge],  // Integer
            manRate: [rate.manRate],  // Double
            womanRate: [rate.womanRate],  // Double
            thousandQuota: [rate.thousandQuota]  // Boolean
          });
          categoryRatesArray.push(rateGroup);
        });
      }

      // Agregar plan al FormArray de planes
      planPlanesModelsArray.push(planFormGroup);

    });

    // Inicializa el array de visibilidad con valores 'false' para cada plan
    this.isTariffSectionVisible = this.planFlexForm.get('planPlanesModels').value.reduce((acc, plan) => {
      acc[plan.idFlexPlanPlanes] = false;
      return acc;
    }, {});

    this.planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    this.cd.detectChanges();
  }

  createPlan(isPlanFexlible: boolean, count): FormGroup {
    return this.fb.group({
      idFlexPlanPlanes: [null],  // Integer
      flexPlanCategoriesId: [null],  // Integer
      planName: ['Plan ' + count],  // String
      flexiblePlan: [isPlanFexlible],  // Boolean
      quote: [0.0],  // Double
      premiumIndividual: [false],  // Double
      averagePremium: [0.0],  // Double
      netPremiumIndividual: [0.0],  // Double
      netPremiumGroup: [0.0],  // Double
      policyRights: [null, Validators.required],  // Double
      surcharges: [null, Validators.required],  // Double
      insuranceCompanyId: [null, Validators.required],
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Integer
      updateUserId: [null],  // Integer
      status: [1],  // Integer
      flexPlanCategoriesModel: this.fb.group({ // FormGroup para almacenar la categoria
        idFlexPlanCategories: [null],
        categoryName: [''],
        planFlexId: [this.idPlanFlex],
        flexibleProductId: [1],
        creationDate: [''],
        updateDate: [''],
        creationUserId: [''],
        updateUserId: ['']
      }),
      categoryRates: this.fb.array([]),  // FormArray para almacenar las tarifas manuales
      flexPlanCoveragesModels: this.fb.array([]),  // FormArray para almacenar las coberturas
      maximumYearsPolicy: [null, Validators.required],  // Integer
      maximumMonthsPolicy: [null, Validators.required],  // Integer
      minimumYearsPolicy: [null, Validators.required],  // Integer
      minimumMonthsPolicy: [null, Validators.required],  // Integer
      maximumYearsRenewal: [null, Validators.required],  // Integer
      maximumMonthsRenewal: [null, Validators.required],  // Integer
      minimumYearsRenewal: [null, Validators.required],  // Integer
      minimumMonthsRenewal: [null, Validators.required],
      renewedPolicy: []// Integer
    });
  }

  getUnitOptions(): any[] {
    return this.planProductOptions;
  }

  toggleSpecialCoverages() {
    this.showSpecialCoverages = !this.showSpecialCoverages;
  }


  getPlanAtIndex(planIndex: number): FormGroup {
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    return planPlanesModels.at(planIndex) as FormGroup;
  }

  addCategoryRate(planIndex: number) {
    const plan = this.getPlanAtIndex(planIndex);
    if (planIndex !== null) {
      const rateForm = this.fb.group({
        idFlexPlanCategoryRates: [null],
        flexPlanPlanesId: [plan.get('idFlexPlanPlanes').value],
        minimumAge: [null, Validators.required],
        maximumAge: [null, Validators.required],
        manRate: [0.0, Validators.required],
        womanRate: [0.0, Validators.required],
        thousandQuota: [false],
        creationDate: [null],
        updateDate: [null],
        creationUserId: [null],
        updateUserId: [null]
      });

      const categoryRatesArray = (plan.get('categoryRates') as FormArray);
      categoryRatesArray.push(rateForm);
    }
  }


  // Método para mostrar/ocultar la sección de tarifas manuales de un plan específico
  toggleTariffSection(planIndex: number) {
    this.isTariffSectionVisible[planIndex] = !this.isTariffSectionVisible[planIndex];
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  onChangeInputFileSlip(e) {
    this.error = {active: false, msg: ''};
    this.fileUploadFactor = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUploadFactor.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_TARIFAS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  removeCategoryRate(indexPlan: number, indexRate: number): void {
    const categoryRatesArray = this.getPlanAtIndex(indexPlan).get('categoryRates') as FormArray;
    categoryRatesArray.removeAt(indexRate);

  }

  saveRates(indexPlan: number): void {

    const plan = this.getPlanAtIndex(indexPlan);
    this.spinner.show('sp');

    if (!plan.get('quote').value || plan.get('quote').value === 'false') {
      this.api.saveRatesConfiguration(this.planFlexForm.value)
        .then(
          (response: any) => {
            this.fileUpload = undefined;
            this.spinner.hide('sp');
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    } else {

      const idPlanPlanes = this.getPlanAtIndex(indexPlan).get('idFlexPlanPlanes').value;
      if (this.fileUpload === undefined) {
        this.toastr.info('DEBE DE INGRESAR EL LAYOUT', 'NOTIFICACION');
        this.spinner.hide('sp');
        return;
      }
      const file = this.fileUpload;
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const base64String = (reader.result as string).split(',')[1];   // Elimina el prefijo de la URL de datos
          const model = {
            fileName: file.name,
            fileData: base64String,
            idFlexPlanPlanes: idPlanPlanes
          };
          this.api.getTariffFromLayoutGMM(model)
            .then(
              (response: any) => {
                this.spinner.hide('sp');
                this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
              }, error => {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                this.spinner.hide('sp');
              }
            );
        } else {
          this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
          this.spinner.hide('sp');
        }
      };

      reader.onerror = () => {
        this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
        this.spinner.hide('sp');
      };

      reader.readAsDataURL(file);  // Inicia la lectura del archivo
    }
  }

  saveOnlyPlan(indexPlan: number): void {

    const plan = this.getPlanAtIndex(indexPlan);
    this.spinner.show('sp');

    if (!plan.valid) {
      plan.markAllAsTouched();
      this.spinner.hide('sp');
      return;
    }
    const dataToSend = this.cleanOptionsValues(plan.value);
    this.api.saveAdditionalPlanConfiguration(dataToSend)
        .then(
            (response: any) => {
              this.spinner.hide('sp');
              this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
        );
    }

  savePlanGmm(): void {
    this.spinner.show('sp');

    if (!this.planFlexForm.valid) {
      this.planFlexForm.markAllAsTouched();
      this.spinner.hide('sp');
      return;
    }

    this.api.editPlanById(this.planFlexForm.value)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          this.populateFormWithResponseData(response);

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  layoutCargado(e) {
    console.log('respuesta población inicial');
    console.log(e);
    this.isPoblation = e;
  }

  getInsuranceCompanies() {
    this.api.getInsuranceCompaniesByProduct(2).then((data: any) => {
      console.log('asegyradora' + data);
      this.insuranceListDetail = data;

    }, error => {
      this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
    });
  }

  loadCoveragesByIdProduct(): void {
    const data = {
      idProduct: 2,
      idPlan: null
    };
    this.spinner.show('sp');
    this.api.getCoveragesByIdProduct(data)
      .then(
        (response: any) => {
          this.specialCoverageList = response;

          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  cleanOptionsValues(data: any): any {
    // Creamos una copia para no modificar el objeto original
    const cleanedData = { ...data };

    // Iteramos sobre las coberturas y eliminamos el atributo optionsValues si existe
    if (cleanedData.flexPlanCoveragesModels) {
      cleanedData.flexPlanCoveragesModels = cleanedData.flexPlanCoveragesModels.map((coverage: any) => {
        const { optionsValues, ...coverageWithoutOptionsValues } = coverage;
        return coverageWithoutOptionsValues;
      });
    }

    return cleanedData;
  }

  invokeFunctionLoadPoblation() {
    console.log(this.isPoblation);
    const objRequest = {
      idPlan: Number(this.idPlanFlex),
      idProduct: 2
    };

    this.api.getLoadTariffQuotesByPlanProduct(objRequest)
      .then(
        (response: any) => {
          if (response.LoadTariffQuotes === 0) {
            this.isPoblation = true;
          } else {
            this.toastr.info('TIENE QUE CONFIGURAR TODAS LAS TARIFAS PARA PODER CARGAR LA POBLACIÓN', 'NOTIFICACIÓN');
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
        }
      );

  }

  asignInsurance(insurance: any) {
    console.log('Esta es la aseguradora', insurance);

    const plansArray = this.planFlexForm.get('planPlanesModels');
    if (!(plansArray instanceof FormArray)) {
      console.error('planPlanesModels no es un FormArray');
      return;
    }

    plansArray.controls.forEach(planControl => {
      const insuranceCompanyId = planControl.get('insuranceCompanyId');
      insuranceCompanyId.setValue(insurance);
    });
  }

  invokeFunctionBackDetailPlan() {
    this.router.navigate(['/admin/plans-detalle/', this.idPlanFlex]);
  }

    protected readonly Number = Number;
}
