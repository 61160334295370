import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME} from 'ng-wizard';
import {of} from 'rxjs';
import {regex} from '../../common/regex';

@Component({
  selector: 'app-insurance-company',
  templateUrl: './insurance-company.component.html',
  styleUrls: ['./insurance-company.component.css']
})
export class InsuranceCompanyComponent implements OnInit {
  modalRef: BsModalRef;
  // variables para conponte wizard
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      showPreviousButton: false
      , showNextButton: false
    },
    anchorSettings: {
      anchorClickable: true,
      enableAllAnchors: true,
      markDoneStep: true,
      markAllPreviousStepsAsDone: true,
      removeDoneStepOnNavigateBack: true,
      enableAnchorOnDoneStep: true
    }
  };
  isEdithInsurance: number;
  selectedStepIndex = 0;
  totalSteps = 5;
  disableBtnPrevious = true;
  disableBtnNext = false;
  typeStep: number;
  /*CONFIGURACION DE STEPS*/
  isValidTypeBoolean = true;
  // variables para admin aseguradora
  dataInsurance: any = {};
  insuranceId: number;
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['image/png'];
  public fileUploadAttachment: File;
  imageB64: string;
  myForm: FormGroup;
  dropdownSettingsSubBranch = {};
  catSubBranches: any = [];
  subBranchesSelectedItems: any = [];

  // variables para admin contactos
  contactList: any = [];
  searchContact: string;
  contactId: number;
  myFormContact: FormGroup;
  dataContact: any = {};
  contactTypeList: any = {};
  contactTypeSelected: any = {};
  emailContactOld: string;
  // -Varibales para paginado de contactos->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 5; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  list;
  list2;
  // variables para admin anexos
  attachmentId: number;
  attachmentList: any = [];
  attachmentListPag: any = [];
  searchName: string;
  myFormAttachment: FormGroup;
  dataAttachment: any = {};
  subBranchTypeSelected: any = {};
  subBranchList: any = [];
  // -Varibales para paginado de anexos->
  pgBoundaryLinksAttachment = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSizeAttachment = 3; // Número de links en paginado
  pgItemsPerPageAttachment = 10; // Número de registros por página
  pgTotalItemsAttachment: number; // Total de registros
  pgCurrentPageAttachment = 1; // Página actual

  // variables para admin claves de agentes
  dataAgentKey: any = {};
  agentKeyList: any = [];
  dataAgentKeyFilter: any = {};
  agentKeyId: number;
  myFormAgentKey: FormGroup;
  // -Varibales para paginado de anexos->
  pgBoundaryLinksAgent = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSizeAgent = 3; // Número de links en paginado
  pgItemsPerPageAgent = 10; // Número de registros por página
  pgTotalItemsAgent: number; // Total de registros
  pgCurrentPageAgent = 1; // Página actual
  paginationResponseAgent: any = {};
  attachmentCatalog: any = [];
  // variables para admin direcciones
  searchAddress: string;
  addressList: any = [];
  addressListPag: any = [];
  addressId: number;
  dataAddressFiscal: any = {};
  typesAddress: any = [];
  // -Varibales para paginado de direcciones->
  pgBoundaryLinksAddress = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSizeAddress = 3; // Número de links en paginado
  pgItemsPerPageAddress = 5; // Número de registros por página
  pgTotalItemsAddress: number; // Total de registros
  pgCurrentPageAddress = 1; // Página actual
  // listas de catalogos
  countryListFiscal: any = [];
  countryList: any = [];
  countryListOther: any = [];
  municipalityListFiscal: any = [];
  municipalityList: any = [];
  municipalityListOther: any = [];
  zipCodeListFiscal: any = [];
  zipCodeList: any = [];
  zipCodeListOther: any = [];
  stateListFiscal: any = [];
  stateList: any = [];
  stateListOther: any = [];
  neighborhoodListFiscal: any = [];
  neighborhoodList: any = [];
  neighborhoodListOther: any = [];
  streetsListFiscal: any = [];
  streetsList: any = [];
  streetsListOther: any = [];
  selectStateFiscalyTemp: any = {};
  selectMunicipalityFiscalTemp: any = {};
  selectZipCodeFiscalTemp: any = {};
  selectNeighborhoodFiscalTemp: any = {};
  selectStreetFiscalTemp: any = {};
  _FISCAL = 'FISCAL';
  streetFiscalNew: any = {};
  isNewStreetFiscal = false;
  addressForm: FormGroup;
  countryFiscal: string;
  // variables para dias de gracia
  searchDays: string;
  daysPaymentId: number;
  daysPaymentList: any = [];
  daysPaymentListPag: any = [];
  myFormDaysPayment: FormGroup;
  dataDays: any = {};
  subBranchTypeSelectedDay: any = {};
  subBranchDayList: any = [];
  // -Varibales para paginado de dias de gracia->
  pgBoundaryLinksDays = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSizeDays = 3; // Número de links en paginado
  pgItemsPerPageDays = 10; // Número de registros por página
  pgTotalItemsDays: number; // Total de registros
  pgCurrentPageDays = 1; // Página actual
  isNewRegister: boolean;

  subBranchTypeSelectedDayEdith: any = {};
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private ngWizardService: NgWizardService) {
    this.myForm = this.fb.group({
      rfc: ['', [Validators.required,
        Validators.pattern('[A-Z&Ñ]{3}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]')]],
      nameInsurance: ['', [Validators.required, Validators.maxLength(100)]],
      emergencyPhoneNumber: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
        Validators.maxLength(10)]],
      shortName: ['',  [Validators.required]],
      imagePath: [''],
      subBranch: ['', [Validators.required]],
      webPage: ['', [Validators.required]],
      userWeb: ['', [Validators.required]],
      passwordWeb: ['', [Validators.required]]
    });
    this.myFormContact = this.fb.group({
      nameContact: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$'),  Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.pattern(regex.correoElectronicolarge), Validators.maxLength(60)]],
      phone: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
        Validators.maxLength(10)]],
      contactTypeId: ['']
    });
    this.myFormAttachment = this.fb.group({
      typeAttachment: [''],
      name: [''],
      subBranchId: ['']
    });
    this.myFormAgentKey = this.fb.group({
      agentKey: ['', [Validators.required, Validators.maxLength(10)]],
      description: ['', [Validators.required, Validators.maxLength(100)]]
    });
    this.addressForm = new FormGroup({
      numIntFiscal: new FormControl(null, [
        Validators.maxLength(20),
        Validators.required
      ]),
      numExtFiscal: new FormControl(null, [
        Validators.maxLength(20),
        Validators.required
      ]),
      countryFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      stateFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      municipalityCodeFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      zipCodeFiscal: new FormControl(null, [
        Validators.required
      ]),
      neighborhoodFiscal: new FormControl(null, [
        Validators.required
      ]),
      streetFiscal: new FormControl(null, [
        Validators.required, Validators.maxLength(100)
      ]),
      agregatCalle: new FormControl(),
      addressTypeFiscal: new FormControl(null, [
        Validators.required
      ])
    });
    this.myFormDaysPayment = this.fb.group({
      initialPeriod: ['', [Validators.required, Validators.pattern(/^[0-9]\d{0,1}$/)]],
      subsequentPeriod: ['', [Validators.required, Validators.pattern(/^[0-9]\d{0,1}$/)]],
      subBranchId: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.searchDays = '';
    this.imageB64 = '../../assets/img/image.png';
    this.insuranceId = Number(localStorage.getItem('insurance-id'));
    this.isEdithInsurance = Number(localStorage.getItem('isEdithInsurance'));
    if (this.insuranceId > 0) {
      this.getInsuranceCompanyById();
    } else {
      this.initInsurance();
    }
    this.searchContact = '';
    this.initDataContact();
    this.getContactTypes();
    this.getSubBranches();
    this.initDataDays();
    this.attachmentCatalog = [{attachmentId: 1, description: 'ARCHIVO'}, {attachmentId: 8, description: 'DIRECTORIO MEDICO'}];

    this.dropdownSettingsSubBranch = {
      singleSelection: false,
      idField: 'subBranchId',
      textField: 'subBranch',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 8,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB RAMOS',
      searchPlaceholderText: 'BUSCAR',
      closeDropDownOnSelection: true
    };
  }
  /* -----------------------------------------------------------------------------------------------------
     -------------------------------------- G E N E R A L E S  ---------------------------------------
     ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    // this.router.navigate(['/admin/insurance']);
    this.router.navigate(['/admin/catalog']);
  }
  previousStep() {
    this.selectedStepIndex--;
    if (this.selectedStepIndex > 0) {
      if (this.selectedStepIndex === 2 && this.attachmentList == null && this.insuranceId > 0) {
        this.toastr.warning('DEBE DE INGRESAR LOS ANEXOS DE LA ASEGURADORA PARA CONTINUAR', 'NOTIFICACIÓN');
        this.selectedStepIndex = 3;
        return;
      }
      this.stepIndexSelected(this.selectedStepIndex);
      this.disableBtnPrevious = false;
      this.disableBtnNext = false;

    } else if (this.selectedStepIndex === 0) {
      this.stepIndexSelected(this.selectedStepIndex);
      this.disableBtnPrevious = true;
    }
  }
  nextStep() {
    this.selectedStepIndex++;
    if (this.selectedStepIndex < this.totalSteps) {
      this.disableBtnNext = false;
      this.disableBtnPrevious = false;
      if (this.selectedStepIndex === 4 && this.attachmentList == null && this.insuranceId > 0) {
        this.toastr.warning('DEBE DE INGRESAR LOS ANEXOS DE LA ASEGURADORA PARA CONTINUAR', 'NOTIFICACIÓN');
        this.selectedStepIndex = 3;
        return;
      }
      this.stepIndexSelected(this.selectedStepIndex);
    } else if (this.selectedStepIndex === this.totalSteps) {
      this.disableBtnNext = true;
      this.stepIndexSelected(this.selectedStepIndex);
    }
  }
  stepIndexSelected(selectedStepIndex) {
    this.ngWizardService.show(selectedStepIndex);
    this.typeStep = selectedStepIndex;
    switch (selectedStepIndex) {
      case 0:
        // console.log('datos generales');
        break;
      case 1:
        // console.log('contactos');
        this.getContactsByInsuranceId();
        break;
      case 2:
        // console.log('address');
        this.searchAddress = '';
        this.getAddressByInsuranceId();
        this.invokeServiceGetCountries();
        this.invokeServiceGetStatesByCountry();
        this.getTypesAddress();
        break;
      case 5:
        // console.log('anexos');
        this.getAttachmentsByInsuranceId();
        this.getSubBranchesByInsuranceAttachment(this.insuranceId);
        break;
      case 3:
        // console.log('claves');
        this.initDataAgentKeyFilter();
        this.getAgentKeyByInsuranceId();
        break;
      case 4:
        // console.log('dias de gracia');
        this.getSubBranchesDays();
        this.getDaysPaymentByInsuranceId();
        break;
    }
  }
  stepChanged(args: StepChangedArgs) {
    // console.log('entra en pestaña');
    /*console.log(args.step.index);*/
    this.stepIndexSelected(args.step.index);
  }
  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }
  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }
  closeModal() {
    this.modalRef.hide();
    this.contactId = 0;
  }
  confirmDelete() {
    // console.log('pestaña actual:  ' + this.typeStep);
    switch (this.typeStep) {
      case 1:
        this.api.deleteContactInsuranceById(this.contactId).subscribe( response => {
          this.contactId = 0;
          this.modalRef.hide();
          this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
          this.getContactsByInsuranceId();
        }, error => {
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        });
        break;
      case 2:
        this.api.deleteAddressInsuranceById(this.addressId).subscribe( response => {
          this.addressId = 0;
          this.modalRef.hide();
          this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
          this.getAddressByInsuranceId();
        }, error => {
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        });
        break;
        break;
      case 5:
        this.api.deleteAttachmentId(this.attachmentId).subscribe( response => {
          // this.contactId = 0;
          this.modalRef.hide();
          this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
          this.getAttachmentsByInsuranceId();
        }, error => {
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        });
        break;
      case 3:
        this.api.deleteAgentKeyId(this.agentKeyId).subscribe( response => {
          this.modalRef.hide();
          this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
          this.getAgentKeyByInsuranceId();
        }, error => {
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        });
        break;
      case 4:
        this.api.deleteDaysPaymentId(this.daysPaymentId).subscribe( response => {
          this.modalRef.hide();
          this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
          this.getDaysPaymentByInsuranceId();
        }, error => {
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        });
        break;
    }
  }
  /* -----------------------------------------------------------------------------------------------------
       -------------------------------------- I N S U R A N C E C O M P A N Y  ---------------------------------------
       ----------------------------------------------------------------------------------------------------- */
  initInsurance() {
    this.dataInsurance = {
      insuranceId : 0,
      rfc: '',
      name: '',
      emergencyPhoneNumber: '',
      imagePath: '',
      shortName: '',
      cardColor: '',
      subBranches: [],
      webPage: '',
      userWeb: '',
      passwordWeb: ''
    };
  }
  getInsuranceCompanyById() {
    this.spinner.show('sp');
    this.api.getInsuranceCompanyById(this.insuranceId).then((data: any) => {
      this.dataInsurance = data;
      this.imageB64 = this.dataInsurance.imagePath;
      this.getSubBranchesByInsurance(this.insuranceId);
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  getSubBranchesByInsurance(insuranceId: number) {
    this.spinner.show('sp');
    this.api.getSubBranchesByInsurance(insuranceId).then((data: any) => {
      this.subBranchesSelectedItems = data;
      this.changeSubBranch();
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  saveInsuranceCompany() {
    console.log('guardar aseguradora');
    if (this.dataInsurance.imagePath === '' ) {
      this.toastr.warning('Ingrese el logo de la aseguradora para continuar', 'Notificación');
      return;
    }
    this.spinner.show('sp');
    this.api.postInsuranceCompany(this.dataInsurance)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.insuranceId = response.InsuranceId;
          this.dataInsurance.insuranceId = this.insuranceId;
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  updateInsuranceCompany() {
    if (this.fileUploadAttachment === undefined) {
      const b64 = this.dataInsurance.imagePath.split(',', 2);
      this.dataInsurance.imagePath = b64[1];
    }
    this.spinner.show('sp');
    this.api.updateInsuranceCompany(this.dataInsurance)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    this.fileUploadAttachment = evt.target.files[0];
    this.dataInsurance.cardColor = this.fileUploadAttachment.name;
    // console.log(this.fileUploadAttachment);
    if (0 > this.fileTypes.indexOf(this.fileUploadAttachment.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
      return;
    }
    if (file) {
      this.error = {active: false, msg: ''};
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    this.dataInsurance.imagePath = btoa(e.target.result);
    this.imageB64 = 'data:image/png;base64,' + this.dataInsurance.imagePath;
  }
  onFocusOutEvent(event: any) {
    // console.log('FOCUS' + event.target.value);
    this.spinner.show('sp');
    this.api.getValidationsRFC(event.target.value,  this.insuranceId).then((data: any) => {
      if (data.validateRFC > 0) {
        this.toastr.warning('EL RFC INGRESADO YA EXISTE', 'NOTIFICACIÓN');
        this.dataInsurance.rfc = '';
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  get i() {
    return this.myForm.controls;
  }
  changeSubBranch() {
    const subBranch = [];
    setTimeout(() => {
      try {
        this.subBranchesSelectedItems.forEach(item => {
          subBranch.push(item.subBranchId);
        });
        this.dataInsurance.subBranches = subBranch;
        // console.log(this.dataToSendGeneral.clients);
      } catch (e) {
      }
    }, 1000);
  }
  /* -----------------------------------------------------------------------------------------------------
       -------------------------------------- C O N T A C T  ---------------------------------------
       ----------------------------------------------------------------------------------------------------- */
  initDataContact() {
    this.dataContact = {
      contactId: 0,
      name: '',
      email: '',
      phone: '',
      contactTypeId: 0,
      contactType: '',
      insuranceId: this.insuranceId,
      insuranceCompany: ''
    };
  }
  getContactsByInsuranceId() {
    this.contactList = [];
    this.list2 = [];
    this.spinner.show('sp');
    this.api.getContactsByInsurance(this.insuranceId, this.searchContact).then((data: any) => {
      this.contactList = data;
      // console.log(this.contactList);
      if (this.contactList == null) {
        if (this.searchContact !== '') {
          this.toastr.info('NO SE ENCONTRARON RESULTADOS CON LA BUSQUEDA SOLICITADA', 'Notificación');
        }
        this.pgTotalItems = 0;
      } else {
        this.pgTotalItems = this.contactList.length;
        this.list2 =  this.contactList.slice(0, 5);
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  clearFiltersTableContact() {
    this.searchContact = '';
    this.getContactsByInsuranceId();
  }
  loadFiltersTableContact() {
    this.getContactsByInsuranceId();
  }
  openDetailContact(template: TemplateRef<any>, contactId) {
    if (this.insuranceId === 0) {
      this.toastr.warning('NO SE PUEDEN AGREGAR CONTANTOS SIN ANTES AGREGAR LA ASEGURADORA', 'NOTIFICATION');
      return;
    }
    this.contactId = contactId;
    if (this.contactId > 0) {
      this.api.getContactsById(this.contactId).then((data: any) => {
        this.dataContact = data;
        this.contactTypeSelected =  data.contactTypeId;
        this.emailContactOld = data.email;
        // console.log(this.emailContactOld);
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
    } else {
      this.initDataContact();
      // this.contactTypeSelected =  0;
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }
  openModalDeleteRegister(template: TemplateRef<any>, type, id) {
    switch (type) {
      case 1:
        this.contactId = id;
        break;
      case 2:
        this.addressId = id;
        break;
      case 3:
        this.attachmentId = id;
        break;
      case 4:
        this.agentKeyId = id;
        break;
      case 5:
        this.daysPaymentId = id;
        break;
    }
    // console.log('filtro para saber que eliminar');
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
  }
  changeColorPagination() { }
  getContactTypes() {
    this.api.getContactTypes().then((data: any) => {
      this.contactTypeList = data;
      this.contactTypeSelected = data[0].contactTypeId;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  public changeClientToConsult(event: any) {
    /*for (let item of this.contactTypeList) {
      if (this.contactTypeSelected == item.contactTypeId) {
        this.dataContact.contactTypeId = item.contactTypeId;
        // console.log(this.dataContact);
      }
    }*/
    if (event !== undefined) {
      this.dataContact.contactTypeId = event.contactTypeId;
      // console.log(this.dataContact);
    }
  }
  saveContact() {
    this.spinner.show('sp');
    if (this.contactList != null) {
      for (let item of this.contactList) {
        if (item.email == this.dataContact.email) {
          this.toastr.info('EL CORREO QUE INTENTA AGREGAR YA EXISTE', 'Notificación');
          this.spinner.hide('sp');
          return;
        }
    }
    }
    if (this.dataContact.contactTypeId == 0) {
      this.toastr.info('Seleccione un tipo de contacto por favor', 'Notificación');
      this.spinner.hide('sp');
      return;
    }
    this.api.postContact(this.dataContact)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getContactsByInsuranceId();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  updateContact() {
    this.spinner.show('sp');
    for (const item of this.contactList) {
      if (item.email !== this.emailContactOld) {
        if (item.email === this.dataContact.email) {
          this.toastr.info('EL CORREO QUE INTENTA AGREGAR YA EXISTE', 'Notificación');
          this.spinner.hide('sp');
          return;
        }
      }
    }
    this.api.updateContact(this.dataContact)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getContactsByInsuranceId();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  get f() {
    return this.myFormContact.controls;
  }
  itemsPerPageChange(size) {
    if (this.pgItemsPerPage === size) { return; }
    this.pgItemsPerPage = size;
    this.pgBoundaryLinks = (this.pgTotalItems / this.pgItemsPerPage) >= 5;
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.list2 = this.contactList.slice(startItem, endItem);
    // console.info(startItem, '-', endItem);
  }
  /* -----------------------------------------------------------------------------------------------------
       -------------------------------------- A T T A C H M E N T S ---------------------------------------
       ----------------------------------------------------------------------------------------------------- */
  getAttachmentsByInsuranceId() {
    this.spinner.show('sp');
    this.api.getAttachmentByInsurance(this.insuranceId).then((data: any) => {
      this.attachmentList = data;
      // console.log(this.attachmentList);
      if (this.attachmentList == null) {
        this.attachmentListPag = [];
        this.toastr.info('NO SE ENCONTRARON RESULTADOS CON LA BUSQUEDA SOLICITADA', 'Notificación');
        this.pgTotalItemsAttachment = 0;
      } else {
        this.pgTotalItemsAttachment = this.attachmentList.length;
        this.attachmentListPag =  this.attachmentList.slice(0, 10);
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  pageChangedAttachment(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.attachmentListPag = this.attachmentList.slice(startItem, endItem);
    console.info(startItem, '-', endItem);
  }
  clearFiltersTableAttachment() {
    this.searchName = '';
  }
  loadFiltersTableAttachment() {
   // attachmentName: this.searchName;
   // this.getAttachmentsByInsuranceId();
  }
  downloadAttachment(attachment) {
    this.api.getAttachmentId(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          // // console.log(response);
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', attachment.name);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          // console.error(error);
        }
      );
  }
  changeSubBranchToConsult(event: any) {
    /*for (const item of this.subBranchList) {
      if (this.subBranchTypeSelected == item.subBranchId) {
        this.dataAttachment.subBranchId = item.subBranchId;
        // console.log(this.dataAttachment);
      }
    }*/
    if (event !== undefined) {
      this.dataAttachment.subBranchId = event.subBranchId;
      if (this.dataAttachment.typeAttachmentId === 8) {
        if (event.subBranchId !== 5 && event.subBranchId !== 2 && event.subBranchId !== 7
          && event.subBranchId !== 4 && event.subBranchId !== 28) {
          this.toastr.warning('EL DIRECTORIO MEDICO SÓLO APLICA PARA GM MAYORES, GM MENORES, DENTAL Y VISIÓN.', 'NOTIFICATIÓN');
          return;
        }
      }
    }
  }
  clearSubBranch() {
    this.dataAttachment.subBranchId = 0;
  }
  getSubBranches() {
    this.catSubBranches = [];
    const branches = [1, 2, 3, 4, 5, 6];
    this.api.getSubBranchesAll(branches).then((data: any) => {
      this.catSubBranches = data;
     /* this.subBranchTypeSelected = data[0].subBranchList;
      setTimeout(() => {
        this.subBranchList.forEach(item => {
          const sub = {
            subBranchId: item.subBranchId,
            subBranch: item.subBranch
          };
          this.catSubBranches.push(sub);
        });
        // console.log(this.catSubBranches);
      }, 500);*/
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  openMdlAttachment(template: TemplateRef<any>) {
    if (this.insuranceId === 0) {
      this.toastr.warning('NO SE PUEDEN AGREGAR ANEXOS SIN ANTES AGREGAR LA ASEGURADORA', 'NOTIFICATION');
      return;
    }
    this.initDataAttachment();
    this.subBranchTypeSelected = 0;
    // this.subBranchTypeSelecte  d =  0;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }
  initDataAttachment() {
    this.dataAttachment = {
      attachmentId: 0,
      name: '',
      attachmentPath: '',
      typeAttachmentId: 1,
      subBranchId: 0,
      subBranch: '',
      relationId: this.insuranceId
    };
  }
  onChangeInputFile(evt: any) {
    const file = evt.target.files[0];
    this.error = {active: false, msg: ''};
    this.fileUpload = evt.target.files[0];
    this.dataAttachment.name = this.fileUpload.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoadedAttachment.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoadedAttachment(e) {
    this.dataAttachment.attachmentPath = btoa(e.target.result);
  }
  saveAttachment() {
    if (this.dataAttachment.subBranchId === 0) {
      this.toastr.warning('TIENE QUE SELECCIONAR EL SUBGRUPO AL CUAL SE ANEXARA EL ARCHIVO', 'NOTIFICACIÓN');
      return;
    }
    this.spinner.show('sp');
    if (this.dataAttachment.typeAttachmentId === 8) {
      if (this.dataAttachment.subBranchId !== 5 && this.dataAttachment.subBranchId !== 2
        && this.dataAttachment.subBranchId !== 7 && this.dataAttachment.subBranchId !== 4 && this.dataAttachment.subBranchId !== 28) {
        this.toastr.warning('EL DIRECTORIO MEDICO SÓLO APLICA PARA GM MAYORES, GM MENORES, DENTAL Y VISIÓN.', 'NOTIFICATIÓN');
        this.clearSubBranch();
        this.spinner.hide('sp');
        return;
      }
    }
    if (this.attachmentList !== null) {
      for (const item of this.attachmentList) {
        if (this.dataAttachment.typeAttachmentId === 8) {
          if (item.attachmentPath == this.dataAttachment.name && item.subBranchId == this.dataAttachment.subBranchId) {
            this.toastr.info('EL DIRECTORIO MEDICO QUE INTENTA AGREGAR YA EXISTE', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            return;
          }
        } else if (item.name == this.dataAttachment.name && item.subBranchId == this.dataAttachment.subBranchId) {
          this.toastr.info('EL ARCHIVO QUE INTENTA AGREGAR YA EXISTE', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          return;
        }
      }
    }
    this.api.postAttachmentInsurance(this.dataAttachment)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getAttachmentsByInsuranceId();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  getSubBranchesByInsuranceAttachment(insuranceId: number) {
    this.spinner.show('sp');
    this.api.getSubBranchesByInsurance(insuranceId).then((data: any) => {
      if (data != null ) {
        this.subBranchList = data;
        this.subBranchTypeSelected = data[0].subBranchList;
      } else {
        this.toastr.warning('DEBE ASIGNAR LOS SUBRAMOS DE ESTA ASEGURADO ANTES DE AGREGAR LOS ANEXOS', 'NOTIFICACIÓN');
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  cleanSpacesString(cadena: string) {
    // console.log(cadena);
    const cleanString = cadena.replace(/ /g, '').trim();
    // console.log(cleanString);
    this.dataAttachment.name = cleanString;

  }
  /* -----------------------------------------------------------------------------------------------------
       -------------------------------------- A G E N T K E Y S  ---------------------------------------
       ----------------------------------------------------------------------------------------------------- */
  initDataAgentKey() {
    this.dataAgentKey = {
      agentKeyId: 0,
      agentKey: '',
      description: '',
      insuranceCompanyId: this.insuranceId
    };
  }
  initDataAgentKeyFilter() {
    this.dataAgentKeyFilter = {
      search: '',
      insuranceCompanyId: this.insuranceId,
      page: 1,
      size: 10
    };
  }
  getAgentKeyByInsuranceId() {
    this.getByPaginationAgentKey(1, 10);
  }
  pageChangedAgent(event: PageChangedEvent): void {
    // // // // console.loglog(event);
    this.pgCurrentPage = event.page;
    this.getByPaginationAgentKey(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  getByPaginationAgentKey(page, size) {
    this.spinner.show('sp');
    this.dataAgentKeyFilter.page = page;
    this.dataAgentKeyFilter.size = size;
    this.api.getAgentKeyByInsurancePagination(this.dataAgentKeyFilter)
      .then(
        (response: any) => {
          if (response == null) {
            if (this.dataAgentKeyFilter.search !== ''){
              this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            }
            this.agentKeyList = [];
            this.pgTotalItemsAgent = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponseAgent = response;
          // console.log(this.paginationResponseAgent);
          this.spinner.hide('sp');
          this.pgTotalItemsAgent = this.paginationResponseAgent.total ;
          this.agentKeyList = this.paginationResponseAgent.agentKeyList;
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // // // console.logerror(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.agentKeyList = [];
          this.pgTotalItemsAgent = 0;
          this.spinner.hide('sp');
        }
      );
  }
  clearFiltersTableAgentKey() {
    this.initDataAgentKeyFilter();
    this.getAgentKeyByInsuranceId();
  }
  loadFiltersTableAgentKey() {
    this.getAgentKeyByInsuranceId();
  }
  getAgentKeyById() {
    this.spinner.show('sp');
    this.api.getAgentKeyById(this.agentKeyId).then((data: any) => {
      this.dataAgentKey = data;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  getValidationAgentKey() {
    this.spinner.show('sp');
    this.api.getValidationAgentKey(this.dataAgentKey.agentKey, this.insuranceId, this.agentKeyId).then((data: any) => {
      if (data > 0) {
        this.toastr.info('LA CLAVE DE AGENTE YA FUE REGISTRADA', 'Notificación');
        this.dataAgentKey.agentKey = '';
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  openDetailAgentKey(template: TemplateRef<any>, id) {
    if (this.insuranceId === 0) {
      this.toastr.warning('NO SE PUEDEN AGREGAR CLAVES DE AGENTES SIN ANTES AGREGAR LA ASEGURADORA', 'NOTIFICATION');
      return;
    }
    this.agentKeyId = id;
    if (this.agentKeyId > 0) {
      this.api.getAgentKeyById(this.agentKeyId).then((data: any) => {
        this.dataAgentKey = data;
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
    } else {
      this.initDataAgentKey();
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }
  saveAgentKey() {
    this.spinner.show('sp');
    this.api.postAgentKey(this.dataAgentKey)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getAgentKeyByInsuranceId();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  updateAgentKey() {
    this.spinner.show('sp');
    this.api.updateAgentKey(this.dataAgentKey)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getByPaginationAgentKey(this.dataAgentKeyFilter.page, this.dataAgentKeyFilter.size);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  onFocusOutEventAgentKey(event: any) {
    this.getValidationAgentKey();
  }

  /* -----------------------------------------------------------------------------------------------------
   -------------------------------------- A D D R E S S  ---------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  pageChangedAddress(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.addressListPag = this.addressList.slice(startItem, endItem);
    console.info(startItem, '-', endItem);
  }
  getAddressByInsuranceId() {
    this.addressList = [];
    this.addressListPag = [];
    this.spinner.show('sp');
    this.api.getAddressByInsurance(this.insuranceId, this.searchAddress).then((data: any) => {
      this.addressList = data;
      // console.log(this.addressList);
      if (this.addressList == null) {
        if (this.searchAddress !== '') {
          this.toastr.info('NO SE ENCONTRARON RESULTADOS CON LA BUSQUEDA SOLICITADA', 'Notificación');
        }
        this.pgTotalItemsAddress = 0;
      } else {
        this.pgTotalItemsAddress = this.addressList.length;
        this.addressListPag =  this.addressList.slice(0, 5);
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  clearFiltersTableAddress() {
    this.searchAddress = '';
    this.getAddressByInsuranceId();
  }
  loadFiltersTableAddress() {
    this.getAddressByInsuranceId();
  }

  openDetailAddress(template: TemplateRef<any>, id) {
    if (this.insuranceId === 0) {
      this.toastr.warning('NO SE PUEDEN AGREGAR DIRECCIONES SIN ANTES AGREGAR LA ASEGURADORA', 'NOTIFICATION');
      return;
    }
    this.loadInitialValuesData();
    this.addressId = id;
    if (this.addressId > 0) {
      this.api.getAddressById(this.insuranceId, this.addressId).then((data: any) => {
        this.dataAddressFiscal = data[0];
        // console.log(this.dataAddressFiscal);
        this.addressForm.get('addressTypeFiscal').patchValue(data[0].addressType);
        this.addressForm.get('countryFiscal').patchValue(data[0].country);
        const stateObj = {
          cKey: data[0].stateCode,
          cValue: data[0].state.toUpperCase()
        };
        this.invokeServiceGetMunicipalyByStateName(stateObj, this._FISCAL);
        const zipCode = {
          cKey: data[0].municipalityCode,
          cValue: data[0].municipality.toUpperCase()
        };
        this.invokeServiceGetZipCodesByMunicipalyCode(zipCode, this._FISCAL);
        const neighborhoodObj = {
          cKey: '',
          cValue: data[0].zipCode
        };
        this.invokeServiceGetColoniesByZipCode(neighborhoodObj, this._FISCAL);
        const streetObj = {
          cKey: data[0].neighborhoodCode,
          cValue: data[0].neighborhood.toUpperCase()
        };
        this.invokeServiceGetStreetsByColonie(streetObj, this._FISCAL);
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
    } else {
      this.dataAddressFiscal.country = 'MÉXICO';
      // console.log(this.dataAddressFiscal);
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }
  getCountrySelectedFiscal(event) {
    this.dataAddressFiscal.country = event.target.value;
  }

  invokeServiceGetCountries() {
    this.api.getCountries().then((data: any) => {
      if (data != null) {
        this.countryList = data;
        this.countryListFiscal = data;
        this.countryListOther = data;
        this.dataAddressFiscal.country = this.countryListFiscal[0].cValue;
      }
    }, error => {
    });
  }
  selectStateFiscaly(event) {
    if (event !== undefined) {
      this.selectStateFiscalyTemp = event;
      this.dataAddressFiscal.stateCode = event.cKey;
      this.clearMunicipalityFiscal();
      this.invokeServiceGetMunicipalyByStateName(event, this._FISCAL);
    }

  }
  clearMunicipalityFiscal() {
    this.dataAddressFiscal.municipalityCode = null;
    this.dataAddressFiscal.zipCode = null;
    this.clearZipCodeFiscalList();
    this.dataAddressFiscal.neighborhoodCode = null;
    this.clearNeighborhoodFiscalList();
    this.dataAddressFiscal.street = null;
    this.clearStreetFiscalList();
  }

  clearMunicipalityFiscalList() {
    this.municipalityListFiscal = [];
  }
  clearMunicipalityList() {
    this.municipalityList = [];
  }
  clearZipCodeFiscalList() {
    this.zipCodeListFiscal = [];
  }
  clearNeighborhoodFiscalList() {
    this.neighborhoodListFiscal = [];
  }
  clearStreetFiscalList() {
    this.streetsListFiscal = [];
  }
  clearNeighborhoodList() {
    this.neighborhoodList = [];
  }
  clearStateFiscal() {
    this.dataAddressFiscal.stateCode = null;
    this.clearMunicipalityFiscal();
    this.clearMunicipalityFiscalList();

  }
  invokeServiceGetMunicipalyByStateName(data1, typeAddress) {
    this.api.getMunicipalyByStateName(data1).then((data: any) => {
      if (data != null) {
        switch (typeAddress) {
          case this._FISCAL: {
            this.municipalityListFiscal = data;
            this.invokeTownByZipCode(this.dataAddressFiscal.zipCode, typeAddress);
            break;
          }
        }
      }
    }, error => {
    });

  }
  selectMunicipalityFiscal(event) {
    // this.zipCodeListFiscal=[];
    if (event !== undefined) {
      this.selectMunicipalityFiscalTemp = event;
      this.dataAddressFiscal.municipalityCode = event.cKey;
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._FISCAL);
      this.clearZipCodeFiscal();
    }
  }
  invokeServiceGetZipCodesByMunicipalyCode(data1, typeAddress) {
    this.api.getZipCodesByMunicipalyCode(data1).then((data: any) => {
      if (data != null) {
        this.zipCodeListFiscal = data;
      }
    }, error => {
    });
  }
  clearZipCodeFiscal() {
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.neighborhoodCode = null;
    this.dataAddressFiscal.zipCode = null;
    this.clearNeighborhoodFiscalList();
    this.clearStreetFiscalList();
  }
  selectZipCodeFiscal(event) {
    if (event !== undefined) {
      this.selectZipCodeFiscalTemp = event;
      this.dataAddressFiscal.zipCode = event.cValue;
      this.invokeServiceGetColoniesByZipCode(event, this._FISCAL);
      this.clearNeighborhoodFiscal();
    }
  }
  invokeServiceGetColoniesByZipCode(data1, typeAddress) {
    this.api.getColoniesByZipCode(data1).then((data: any) => {
      if (data != null) {
        this.neighborhoodListFiscal = data;
      }
    }, error => {
    });
  }
  clearNeighborhoodFiscal() {
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.neighborhoodCode = null;
    this.clearStreetFiscalList();
  }
  selectNeighborhoodFiscal(event) {
    if (event !== undefined) {
      this.selectNeighborhoodFiscalTemp = {};
      this.dataAddressFiscal.neighborhoodCode = event.cKey;
      this.clearStreetFiscalAndList();
      this.invokeServiceGetStreetsByColonie(event, this._FISCAL);
      this.streetFiscalNew.neighborhoodCode = event.cKey;
    }
  }
  clearStreetFiscalAndList() {
    this.dataAddressFiscal.street = null;
    this.clearStreetFiscalList();

  }
  invokeServiceGetStreetsByColonie(data1, typeAddress) {
    this.api.getStreetsByColonie(data1).then((data: any) => {
      if (data != null) {
        this.streetsListFiscal = data;
      }
    }, error => {
    });
  }
  loadInitialValuesData() {
    this.dataAddressFiscal = {
      addressId: 0
      , country: null
      , zipCode: null
      , stateCode: null
      , municipalityCode: null
      , neighborhoodCode: null
      , street: null
      , numExt: null
      , numInt: 'S/N'
      , addressType: 1
      , insuranceId: this.insuranceId
      , streetNew : false,

    };
    this.streetFiscalNew = {
      coloniesStreetsId: 0
      , neighborhoodCode: null
      , nameStreet: null
      , status: false

    };
    this.isNewStreetFiscal = false;
  }
  selectStreetFiscal(event) {
    // console.log('selectStreetFiscal');
    // console.log(event);
    if (event !== undefined) {
      this.selectStreetFiscalTemp = event;
      this.dataAddressFiscal.street = event.cValue;
    }
  }
  clearStreetFiscal() {
    this.dataAddressFiscal.street = null;

  }
  onChangeStreet() {}
  onChangeFiscal(event) {
    this.isNewStreetFiscal = event;
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.streetNew = event;
  }
  onChangeNumInt() {}
  onChangeNumExt() {}
  invokeServiceGetStatesByCountry() {
    this.api.getStatesByCountry().then((data: any) => {
      if (data != null) {
        this.stateListFiscal = data;
      }
    }, error => {
    });
  }
  saveAddress() {
    // console.log(this.dataAddressFiscal);
    this.spinner.show('sp');
    this.api.postAddressInsurance(this.dataAddressFiscal)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          if (response > 0) {
            this.modalRef.hide();
            this.getAddressByInsuranceId();
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          } else {
            this.toastr.info('El registro ya existe', 'Notificación');
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  updateAddress() {
    //// console.log(this.dataAddressFiscal);
    this.spinner.show('sp');
    this.api.updateAddressInsurance(this.dataAddressFiscal)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          if (response > 0) {
            this.modalRef.hide();
            this.getAddressByInsuranceId();
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          } else {
            this.toastr.info('El registro ya existe', 'Notificación');
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  getTypesAddress() {
    this.api.getTypesAddress().then((data: any) => {
      if (data != null) {
        this.typesAddress = data;
      }
    }, error => {
    });
  }
  getTypesSelectedFiscal(event) {
    this.dataAddressFiscal.addressType = event.target.value;
  }
  selectStateMunicipalityByZipCode(typeAddress: string) {
    let zipCode;
    if (typeAddress === this._FISCAL) {
        zipCode = this.dataAddressFiscal.zipCode;
    }
    this.api.getCheckZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (result) {
          this.invokeStateByZipCode(zipCode, typeAddress);
          let value;
          if (typeAddress === this._FISCAL) {
            value =  {
              cKey: this.dataAddressFiscal.zipCode,
              cValue: this.dataAddressFiscal.zipCode
            };
          }
          this.selectZipCodeFiscal(value);
        } else {
          this.toastr.warning('El código postal no existe, por favor intenta con otro', 'Notificación');
          this.clearStateFiscal();
        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });

  }
  invokeStateByZipCode(zipCode, typeAddress) {
    this.api.getStatesByZipeCode(zipCode).then((result: any) => {
      if (result != null) {
          if (this._FISCAL === typeAddress) {
            this.dataAddressFiscal.stateCode = result.cKey;
            this.dataAddressFiscal.state = result.cValue;
            // console.log('obtener municipio');
            // console.log(result);
            this.invokeServiceGetMunicipalyByStateName(result, this._FISCAL);
        }
      } else {
        if (this._FISCAL === typeAddress) {
          this.dataAddressFiscal.stateCode = null;
          this.dataAddressFiscal.state = null;
        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }
  invokeTownByZipCode(zipCode, typeAddress) {
    this.api.getTownByPostcode(zipCode).then((result: any) => {
      if (result != null) {
          if (typeAddress === this._FISCAL) {
            this.dataAddressFiscal.municipalityCode = result.cKey;
          }
      } else {
        if (typeAddress === this._FISCAL) {
          this.dataAddressFiscal.municipalityCode = null;
        }
      }
    }, error => {
      /*console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);*/
    });
  }
  /* -----------------------------------------------------------------------------------------------------
     -------------------------------------- D A Y S O F G R A C E  ---------------------------------------
     ----------------------------------------------------------------------------------------------------- */
  getDaysPaymentByInsuranceId() {
    this.spinner.show('sp');
    this.api.getDaysPaymentByInsurance(this.insuranceId).then((data: any) => {
      this.daysPaymentList = data;
      if (this.daysPaymentList == null) {
        this.daysPaymentListPag = [];
        if (this.searchDays !== ''){
          this.toastr.info('NO SE ENCONTRARON RESULTADOS CON LA BUSQUEDA SOLICITADA', 'Notificación');
        }
        this.pgTotalItemsDays = 0;
      } else {
        this.pgTotalItemsDays = this.daysPaymentList.length;
        this.daysPaymentListPag =  this.daysPaymentList.slice(0, 10);
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  pageChangedDays(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.daysPaymentListPag = this.daysPaymentList.slice(startItem, endItem);
    console.info(startItem, '-', endItem);
  }
  clearFiltersTableDays() {
    this.searchDays = '';
  }

  changeSubBranchToConsultDays(event: any) {
    /*for (const item of this.subBranchDayList) {
      if (this.subBranchTypeSelectedDay == item.subBranchId) {
        this.dataDays.subBranchId = item.subBranchId;
      }
    }*/
    if (event !== undefined) {
      this.dataDays.subBranchId = event.subBranchId;
    }
  }
  getSubBranchesDays() {
    const branches = [1, 2, 3, 4, 5, 6];
    this.api.getSubBranchesAll(branches).then((data: any) => {
      this.subBranchDayList = data;
      this.subBranchTypeSelectedDay = data[0].subBranchDayList;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  openMdlDays(template: TemplateRef<any>, daysPaymentId) {
    if (this.insuranceId === 0) {
      this.toastr.warning('NO SE PUEDEN AGREGAR CONFIGURACIÓN SIN ANTES AGREGAR LA ASEGURADORA', 'NOTIFICATION');
      return;
    }
    this.spinner.show('sp');
    // this.getSubBranchesDays();
    // this.initDataDays();
    this.daysPaymentId = daysPaymentId;
    if (this.daysPaymentId > 0) {
      this.isNewRegister = true;
      this.api.getDayPaymentId(this.daysPaymentId).then((data: any) => {
        this.dataDays = data;
        for (let item of this.subBranchDayList) {
          if (this.dataDays.subBranchId == item.subBranchId) {
            this.dataDays.subBranch = item.subBranch;
          }
        }
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
    } else {
      this.initDataDays();
      // this.subBranchTypeSelectedDay =  0;
      this.isNewRegister = false;
      this.spinner.hide('sp');
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }
  initDataDays() {
    this.dataDays = {
      daysPaymentId: 0,
      subBranchId: 0,
      subBranch: '',
      initialPeriod: 0,
      subsequentPeriod: 0,
      insuranceCompanyId: this.insuranceId
    };
  }
  saveDays() {
    this.spinner.show('sp');
    this.api.postDaysPayment(this.dataDays)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          if (response.id == 0) {
            this.toastr.info('el registro no se puede guardar porqué ya se registro ese sub ramo', 'Notificación');
          } else {
            this.modalRef.hide();
            this.getDaysPaymentByInsuranceId();
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  updateDays() {
    this.spinner.show('sp');
    this.api.putDaysPayment(this.dataDays)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getDaysPaymentByInsuranceId();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
}
