import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {document} from 'ngx-bootstrap/utils';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-endorsement',
  templateUrl: './endorsement.component.html',
  styleUrls: ['./endorsement.component.css']
})
export class EndorsementComponent implements OnInit {
// tslint:disable-next-line:variable-name
  _policyId: number;
  // tslint:disable-next-line:variable-name
  _endorsementId: number;
  typeEndorsementList: any = [];
  endorsementForm: FormGroup;
  dataToEndorsement: any = {};
  receiptTotal: any = {};
  policyAmount: any = {};
  endorsementFlotilla: any = [];
  receiptList: any = [];
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  statusList: any = [];
  paymentList: any = [];
  byCar: boolean;
  constructor(private toastr: ToastrService,
              private shared: SharedService,
              private api: ApiService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private datePipe: DatePipe) {
    this.endorsementForm = new FormGroup({
      endorsement: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      endorsementDate: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      typeEndorsementId: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      endorsementDescription: new FormControl({value: null, disabled: true}, [
        Validators.required
      ])
    });

    this.shared.gPolicyIdEndorsementObservable().subscribe(
      gPolicyIdEndorsement => {
        console.log(gPolicyIdEndorsement);
        this.invokeServiceGetTypeEndorsement();
        this.getStatusReceipts();
        this.getPaymentForms();
        this.invokeServiceGetEndorsementById(this._endorsementId === undefined ? 0 : this._endorsementId);
      }
    );
  }

  ngOnInit() {
    this.bsStartDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });

  }

  get policyId(): number {
    return this._policyId;
  }
  @Input()
  set policyId(value: number) {
    this._policyId = value;
  }
  get endorsementId(): number {
    return this._endorsementId;
  }
  @Input()
  set endorsementId(value: number) {
    this._endorsementId = value;
  }

  invokeServiceGetTypeEndorsement() {
    this.typeEndorsementList = [
      {
        typeEndorsementId: 1,
        endorsement: 'ENDOSO A'
      },
      {
        typeEndorsementId: 2,
        endorsement: 'ENDOSO D'
      },
      {
        typeEndorsementId: 3,
        endorsement: 'ENDOSO B'
      }
    ];
  }
  invokeServiceGetEndorsementById(id: number) {
    const endorsement = {
      endorsementId: id
    };
    if (id === 0) { return; }
    this.api.getEndorsementsById(endorsement).then((data: any) => {
      console.log(data);
      this.byCar = data.byCar;

      if (this.byCar) {
        this.dataToEndorsement = data.endorsement;
        this.endorsementFlotilla = data.endorsementFlotilla;
        for (const recepit of this.endorsementFlotilla) {
          recepit.receiptTotal = recepit.policyAmount;
        }
        // this.endorsementFlotilla.receiptTotal = data.endorsementFlotilla.policyAmount;
      } else {
        this.dataToEndorsement = data.endorsement;
        this.receiptList = data.receiptList;
        this.receiptTotal = data.policyAmount;
        this.policyAmount = data.policyAmount;
      }

    }, error => {
      console.error(error.message);
    });
  }
  invokeServiceForDownloadEndorsementFile() {
    const endorsement = {
      endorsementId: this._endorsementId
    };
    this.api.getEndorsementFile(endorsement)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', this.dataToEndorsement.endorsementFileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          // console.error(error);
        }
      );
  }

  calculateTotalPremium(receipt) {
    this.receiptList.forEach( item => {
      if (item.receiptId === receipt.receiptId) {
        item.iva = ((item.netPremium + item.rights + item.paymentCharges) * 16) / 100;
        item.totalPremium = item.netPremium + item.rights + item.paymentCharges + item.iva;
        return;
      }
    });
  }
  getStatusReceipts() {
    this.api.getStatusReceipts().then((data: any) => {
      this.statusList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  getPaymentForms() {
    this.api.getPaymentForms().then((data: any) => {
      this.paymentList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  invokeServiceSaveReceipts() {
    this.spinner.show('sp');
    let validateData = true;
    let netPremiumTmp = 0;
    let rightsTmp = 0;
    let chargesTmp = 0;
    let ivaTmp = 0;
    let totalPremiumTmp = 0;
    // console.log('validacion de cobranza');
    this.receiptList.forEach(receipt => {
      if ((receipt.receiptStatusId === 3 && receipt.paymentDate === null) || receipt.paymentDeadline === null) {
        this.spinner.hide('sp');
        this.toastr.info('INGRESE UNA FECHA VALIDA', 'Notificación');
        validateData = false;
        return;
      }
      const paymentDate = Date.parse(receipt.paymentDate);
      receipt.paymentDate = this.datePipe.transform(paymentDate, 'yyyy-MM-dd');
      const deadLinePayment = Date.parse(receipt.paymentDeadline);
      receipt.paymentDeadline = this.datePipe.transform(deadLinePayment, 'yyyy-MM-dd');
      netPremiumTmp = netPremiumTmp + receipt.netPremium;
      rightsTmp = rightsTmp + receipt.rights;
      chargesTmp = chargesTmp + receipt.paymentCharges;
      ivaTmp = ivaTmp + receipt.iva;
      totalPremiumTmp = totalPremiumTmp + receipt.totalPremium;
      receipt.policyId = this._policyId;
    });
    if (!validateData) {
      return;
    }
    this.policyAmount = {
      netPremium: netPremiumTmp,
      rights: rightsTmp,
      charges: chargesTmp,
      iva: ivaTmp,
      totalPremium: totalPremiumTmp
    };
    // console.log(this.receiptList);
    // console.log(this.policyAmount);
    if (this.receiptTotal.netPremium !== this.policyAmount.netPremium ||
      this.receiptTotal.rights !== this.policyAmount.rights ||
      this.receiptTotal.charges !== this.policyAmount.charges ||
      this.receiptTotal.iva !== this.policyAmount.iva ||
      this.receiptTotal.totalPremium !== this.policyAmount.totalPremium ) {
      this.spinner.hide('sp');
      this.toastr.warning('VERIFIQUE LOS MONTOS', 'NOTIFICACIÓN');
      return;
    }
    this.api.putReceiptDataDamagesEndorsement(this.receiptList)
      .then(
        (response: any) => {
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACION');
          this.spinner.hide('sp');
          this.invokeServiceGetEndorsementById(this._endorsementId === undefined ? 0 : this._endorsementId);
        }, error => {
          console.error(error.status);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeServiceSaveReceiptsByCar() {
    this.spinner.show('sp');

    for (const item of this.endorsementFlotilla) {
      let validateData = true;
      let netPremiumTmp = 0;
      let rightsTmp = 0;
      let chargesTmp = 0;
      let ivaTmp = 0;
      let totalPremiumTmp = 0;
      // console.log('validacion de cobranza');
      item.receiptDamageList.forEach(receipt => {
        if ((receipt.receiptStatusId === 3 && receipt.paymentDate === null) || receipt.paymentDeadline === null) {
          this.spinner.hide('sp');
          this.toastr.info('INGRESE UNA FECHA VALIDA', 'Notificación');
          validateData = false;
          return;
        }
        const paymentDate = Date.parse(receipt.paymentDate);
        receipt.paymentDate = this.datePipe.transform(paymentDate, 'yyyy-MM-dd');
        const deadLinePayment = Date.parse(receipt.paymentDeadline);
        receipt.paymentDeadline = this.datePipe.transform(deadLinePayment, 'yyyy-MM-dd');
        netPremiumTmp = netPremiumTmp + receipt.netPremium;
        rightsTmp = rightsTmp + receipt.rights;
        chargesTmp = chargesTmp + receipt.paymentCharges;
        ivaTmp = ivaTmp + receipt.iva;
        totalPremiumTmp = totalPremiumTmp + receipt.totalPremium;
        receipt.policyId = this._policyId;
      });
      if (!validateData) {
        return;
      }
      item.receiptTotal = {
        netPremium: netPremiumTmp,
        rights: rightsTmp,
        charges: chargesTmp,
        iva: ivaTmp,
        totalPremium: totalPremiumTmp
      };
      // console.log(this.receiptList);
      // console.log(this.policyAmount);
      if (item.receiptTotal.netPremium !== item.policyAmount.netPremium ||
        item.receiptTotal.rights !== item.policyAmount.rights ||
        item.receiptTotal.charges !== item.policyAmount.charges ||
        item.receiptTotal.iva !== item.policyAmount.iva ||
        item.receiptTotal.totalPremium !== item.policyAmount.totalPremium ) {
        this.spinner.hide('sp');
        this.toastr.warning('VERIFIQUE LOS MONTOS', 'NOTIFICACIÓN');
        return;
      }
      this.api.putReceiptDataDamagesEndorsement(item.receiptList)
        .then(
          (response: any) => {
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACION');
            this.spinner.hide('sp');
            this.invokeServiceGetEndorsementById(this._endorsementId === undefined ? 0 : this._endorsementId);
          }, error => {
            console.error(error.status);
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    }
  }
}
