import {Component, EventEmitter, OnInit, Output, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {subscribeOn} from 'rxjs/operators';
import {object} from '@amcharts/amcharts4/core';
import {ValidateAccessService} from '../validate-access.service';

@Component({
  selector: 'app-admin-catalog-report',
  templateUrl: './admin-catalog-report.component.html',
  styleUrls: ['./admin-catalog-report.component.css']
})
export class AdminCatalogReportComponent implements OnInit {
  filters: any = [];
  catGroups: any = [];
  catReports: any = [];
  reportList: any = [];
  // -Varibles para paginado->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  modalRef: BsModalRef;
  modalNotification: BsModalRef;
  reportId: number;
  reportName: string;
  myFormReport: FormGroup;
  reportCore: any = {};
  groupsList: any = [];
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUpload: File;
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  processExcel: number;
  reportClaim: any = {};
  headerCellList: any = {};
  subBranchList: any = [];
  reportForm: FormGroup;
  reportExcelList: any = [];
  policyList: any = [];
  bugFileExcel: number;
  policyListTmp: any = [];
  configurationId: number;
  cellNumber: number;
  changeConfiguration: number;
  insuranceId: number;
  policyListGroup: any = [];
  policyFile: string;
  policyId: number;
  policyBd: string;
  headers: any = [];
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private validateAccess: ValidateAccessService) {
    this.myFormReport = this.fb.group({
      group: ['', [Validators.required]],
      typeReport: ['', [Validators.required]],
      dateReport: ['', [Validators.required]],
      cellNumber: [''],
      subBranch: ['']
    });
  }

  ngOnInit() {
    this.bsStartDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.validateAccess.validateAccessCore(61).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getGroups();
        this.getTypeReports();
        this.functionLoadFilter();
        this.clearFiltersTable();
      }
    });
  }

  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.catGroups = data;
      this.groupsList = data;
    }, error => {
    });
  }
  getTypeReports() {
    this.catReports = [
      {
        typeReport: 1,
        name: 'SINIESTROS ASEGURADORAS'
      },
      {
        typeReport: 2,
        name: 'PROVISIONES'
      },
      {
        typeReport: 3,
        name: 'ANALISIS DE CUENTA'
      }
    ];
  }
  functionLoadFilter() {
    this.filters = {
      search: '',
      typeReport: 1,
      groupId: 0,
      page: 1,
      size: 10
    };
  }
  clearFiltersTable() {
    this.filters.search = '';
    this.filters.groupId = 0;
    this.validateAccess.validateAccessCore(61).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.pgCurrentPage = page;
    this.api.getReportsPagination(this.filters)
      .then(
        (response: any) => {
          this.paginationResponse = response;
          if (this.paginationResponse.total === 0) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.reportList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total;
          this.reportList = this.paginationResponse.reports;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'NOTIFICACION');
          this.reportList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  loadFiltersTable() {
    this.validateAccess.validateAccessCore(61).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getByPagination(1, 10);
      }
    });
  }
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  openDelete(template: TemplateRef<any>, r) {
    this.validateAccess.validateAccessCore(63).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.reportId = r.reportId;
        this.reportName = r.name;
        this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
      }
    });
  }
  closeModal() {
    this.modalRef.hide();
  }
  invokeServiceDeleteReportById() {
    this.spinner.show('sp');
    const reportDelete = {
      reportId: this.reportId
    };
    this.api.deleteReportCoreId(reportDelete).subscribe(response => {
      this.getByPagination(1, 10);
      this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACION');
      this.spinner.hide('sp');
      this.modalRef.hide();
    }, error => {
      this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN, CONTACTE AL ADMINISTRADOR.', 'NOTIFICACION');
      this.spinner.hide('sp');
    });
  }
  downloadReport(report) {
    this.validateAccess.validateAccessCore(64).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.spinner.show('sp');
        this.api.getReportFileById(report.reportId)
          .subscribe(
            (response: any) => {
              if (response == null) {
                this.toastr.info('No existen archivos para descargar');
                this.spinner.hide('sp');
                return;
              } else {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', report.name);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                this.spinner.hide('sp');
              }
            }, error => {
              this.spinner.hide('sp');
            }
          );
      }
    });
  }

  functionLoadReportCore() {
    this.reportCore = {
      name: '',
      b64File: '',
      groupId: 0,
      dateReport: '',
      typeReport: 0,
      cellNumber: 1,
      branchId: 1
    };
  }
  invokeFunctionOpenReportAdd(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(62).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.processExcel = 0;
        this.functionLoadReportCore();
        this.fileUpload = undefined;
        this.fileUploadXlsx = undefined;
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
      }
    });
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'DEBE SELECCIONAR UN ARCHIVO VALIDO.'};
    } else {
      this.reportCore.name = this.fileUpload.name;
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(this.fileUpload);
    }
  }

  handleReaderLoaded(e) {
    this.reportCore.b64File = btoa(e.target.result);
  }

  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'DEBE SELECCIONAR UN ARCHIVO VALIDO.'};
    } else {
      this.reportCore.name = this.fileUploadXlsx.name;
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(this.fileUploadXlsx);
    }
  }

  invokeServiceSaveReport() {
    console.log(this.reportCore);
    this.reportCore.dateReport = this.datePipe.transform(this.reportCore.dateReport, 'yyyy-MM-dd', 'es-ES');
    if (this.reportCore.name === '') {
      this.toastr.warning('POR FAVOR SELECCIONE EL ARCHIVO DEL REPORTE.', 'NOTIFICAION');
      return;
    }
    if (this.reportCore.dateReport === '') {
      this.toastr.warning('POR FAVOR SELECCIONE LA FECHA DEL REPORTE.', 'NOTIFICAION');
      return;
    }
    if (this.reportCore.typeReport === 1 && this.reportCore.branchId === 1) {
      console.log('siniestralidad');
      // validar que el header sea mayor a
      if (this.reportCore.cellNumber === 0) {
        this.toastr.warning('POR FAVOR INGRESE EL NÚMERO DE FILA DONDE INICIAN LOS TITULOS DEL EXCEL.', 'NOTIFICAION');
        return;
      }
      if (this.cellNumber !== this.reportCore.cellNumber) {
        this.changeConfiguration = 1;
      } else {
        this.changeConfiguration = 0;
      }
      const readExcel = {
        nameReport: this.reportCore.name,
        pathReport: this.reportCore.b64File,
        branchId: this.reportCore.branchId,
        configurationId: this.configurationId,
        changeConfiguration: this.changeConfiguration,
        headerNumber: this.reportCore.cellNumber,
        groupId: this.reportCore.groupId,
        insuranceId: this.insuranceId,
        dateReport: this.reportCore.dateReport
      };
      console.log(readExcel);
      this.toastr.info('SE ESTÁN LEYENDO LOS ENCABEZADOS DE TU REPORTE DE EXCEL POR FAVOR ESPERA.', 'NOTIFICACIÓN');
      this.spinner.show('sp');

      this.api.getConfigurationHeadersByInsuranceReportIncident(readExcel).then((configuration: any) => {
        console.log(configuration);
        if (configuration.layoutValid === 0) {
          this.toastr.warning('LO SENTIMOS EL LAYOUT QUE INGRESO NO ES COMPATIBLE CON EL SISTEMA', 'NOTIFICACION');
          this.spinner.hide('sp');
          this.fileUploadXlsx = undefined;
          this.reportCore.name = '';
          return;
        } else {
          this.processExcel = 1;
          this.headerCellList = configuration.headerExcelList;
          this.reportClaim = configuration.headerExcelConfigurationList;
          this.reportId = configuration.reportId;
          this.configurationId = configuration.configurationId;
          this.policyFile = undefined;
          this.policyId = 0;
          this.spinner.hide('sp');
        }
      }, error => {
        this.spinner.hide('sp');
        this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN', 'NOTIFICACION');
      });
    } else {
      console.log(this.reportCore);
      this.spinner.show('sp');
      this.api.saveReportCore(this.reportCore)
        .then(
          (response: any) => {
            this.getByPagination(1, 10);
            this.toastr.success('SU REGISTRO SE HA AGREGADO CORRECTAMENTE.', 'NOTIFICACION');
            this.spinner.hide('sp');
            this.modalRef.hide();
          }, error => {
            this.spinner.hide('sp');
            this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN', 'NOTIFICACION');
          }
        );
    }
  }

  invokeServiceSaveReportIncident(template: TemplateRef<any>) {
    this.headers = [];
    console.log(this.reportClaim);
    let bug = false;
    this.reportClaim.forEach(item => {
      if (item.headerId === null && !bug) {
        this.toastr.warning('TIENE QUE SELECCIONAR TODOS LOS ENCABEZADO', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
        bug = true;
      }
    });

    if (bug) {
      return;
    }

  /*
    const arrSinDuplicados = [...this.reportClaim];
    arrSinDuplicados.forEach((el, index) => {

      const copyItem = JSON.stringify(el.headerId);

      arrSinDuplicados.forEach((item, indice) => {
        const itemCopy = JSON.stringify(item.headerId);
        // el elemento de por si se va a encontrar asi mismo , por eso hay que establecer  index!=indice
        if (copyItem === itemCopy && index !== indice) {
          // lo que devuelve arrCopy.splice es el elemento que se encuentra duplicado
          // y lo ponemos en el array de duplicados
          if (item.headerId > 0) {
            this.headers.push(arrSinDuplicados.splice(indice, 1));
          }
        }
      });
    });
    */
    /*let tmp = [];
    this.reportClaim.forEach((value, index) => {
      tmp = Object.assign([], this.reportClaim);
      tmp.splice(index, 1);
      if (tmp.indexOf(value.headerId) != -1  && this.headers.indexOf(value.headerId) == -1) {
        this.headers.push(value);
      }
    });*/

    const busqueda = this.reportClaim.reduce((acc, persona) => {
      acc[persona.headerId] = ++acc[persona.headerId] || 0;
      return acc;
    }, {});

    this.headers = this.reportClaim.filter( (persona) => {
      if (persona.headerId > 0) {
        return busqueda[persona.headerId];
      }
    });
    setTimeout(() => {
      console.log(this.headers);
      this.modalNotification = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
    }, 600);
  }

  invokeServicesProcessReadExcel() {
    this.modalNotification.hide();
    console.log(this.reportClaim);
    this.toastr.info('SE ESTA PROCESANDO LA INFORMACIÓN DEL REPORTE DE SINIESTRALIDAD, ESPERA POR FAVOR.', 'NOTIFICACIÓN');
    const objReport = {
      reportTmpId: this.reportId,
      configurationId: this.configurationId,
      headerExcelList: this.reportClaim,
      policyReportList: this.policyList
    };
    this.api.processReportIncident(objReport)
      .then(
        (response: any) => {
          this.reportExcelList = response.reportIncidentExcelList;
          this.policyListTmp = response.policyReportList;
          this.bugFileExcel = response.bugFileExcel;
          this.spinner.hide('sp');
          this.processExcel = 2;
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN', 'NOTIFICACION');
        }
      );
  }
  invokeServiceGetSubBranchByGroupPolicies() {
    const obj = {
      groupId: this.reportCore.groupId
    };
    this.api.getBranchesByGroupReportIncident(obj).then((data: any) => {
      this.subBranchList = data;
      if (data === null) {
        // tslint:disable-next-line:max-line-length
        this.toastr.warning('No se han detectado pólizas en el grupo seleccionado, primero debe de agregar pólizas para poder agregar reportes.', 'Notificación');
      }
    }, error => {
    });
  }

  getIsValid(detail: AbstractControl): boolean {
    return detail.status === 'INVALID';
  }

  invokeServiceGetRecordsExcelClaims() {
    const obj = {
      reportId: this.reportExcelList[0].reportId
    };
    this.api.getRecordsByReport(obj).then((data: any) => {
      this.reportExcelList = data;
    }, error => {
    });
  }

  invokeServiceSaveReportEnd() {
    this.spinner.show('sp');
    this.api.processReportIncidentSave(this.reportExcelList).then((configuration: any) => {
      this.spinner.hide('sp');
      this.processExcel = 0;
      this.modalRef.hide();
      this.toastr.success('SE PROCESO DE MANERA CORRECTA LA INFORMACIÓN', 'NOTIFICACIÓN');
    }, error => {
      this.spinner.hide('sp');
      this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN', 'NOTIFICACION');
    });
  }
  invokeServiceGetPoliciesByGroup(id) {
    const groupReport = {
      groupId: id
    };
    this.api.getPolicyByGroupReportInsurance(groupReport).then((configuration: any) => {
      this.policyList = configuration;
    }, error => {
    });
  }
  invokeServiceGetPoliciesNotFoundIncident() {
    const obj = {
      reportId: this.reportExcelList[0].reportId
    };
    this.api.getPoliciesNotFoundReport(obj).then((data: any) => {
      this.policyListTmp = data;
    }, error => {
    });
  }


  saveOneByOne(report) {
    if (report.numberPolicyReport > 0) {
      let countInvalid = 0;
      this.reportExcelList.forEach(item => {
        if (item.id === report.id) {
          item.status = 'VALID';
          this.policyList.forEach(po => {
            if (item.numberPolicyReport === po.policyId) {
              item.numberPolicyIncidentReport = po.policy;
            }
          });
        }
        if (item.status === 'INVALID') {
          countInvalid = countInvalid + 1;
        }
      });
      setTimeout(() => {
        this.bugFileExcel = countInvalid;
        console.log(this.reportExcelList);
      }, 600);
    }
  }

  invokeFunctionMassAssignment() {
    if (this.policyId === 0 || this.policyFile === undefined) {
      this.toastr.warning('TIENE QUE SELECCIONAR LAS PÓLIZAS A RELACIONAR', 'NOTIFICACIÓN');
      return;
    }
    // primero obtengo los valoes para llamar servicio
    const objUpdatePolicy = {
      policy: this.policyFile,
      policyId: this.policyId,
      reportId: this.reportExcelList[0].reportId
    };
    console.log(objUpdatePolicy);
    this.spinner.show('sp');
    this.api.updatePoliciesByReportIncident(objUpdatePolicy).then((configuration: any) => {
      this.spinner.hide('sp');
      this.bugFileExcel = configuration.bugFileExcel;
      // mando a llamar servicio de polizas no encontradas
      this.invokeServiceGetPoliciesNotFoundIncident();
      // servicio que me trae la información actualizada de la tabla tmp
      this.invokeServiceGetRecordsExcelClaims();
      this.toastr.success('SE HAN VINCULADO LAS PÓLIZAS DE MANERA CORRECTA', 'NOTIFICACIÓN');
      this.modalNotification.hide();
    }, error => {
      this.spinner.hide('sp');
      this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN', 'NOTIFICACION');
    });
  }


  // me quede aqui falta traer la configuraicón de la aaseguradora
  getInsuranceByGroupBranchReportIncident() {
    const obj = {
      groupId: this.reportCore.groupId,
      branchId: this.reportCore.branchId
    };
    this.api.getInsuranceByGroupBranchReportIncident(obj).then((data: any) => {
      console.log(data);
      this.insuranceId = data.insuranceId;
      const insurance  = {
        insuranceId : data.insuranceId
      };
      this.api.getConfigurationInitialByInsuranceReportIncident(insurance).then((configuration: any) => {
        console.log(configuration);
        this.reportCore.cellNumber = configuration.headerRow;
        this.cellNumber = configuration.headerRow;
        this.configurationId = configuration.configurationId;
      }, error => {
      });
    }, error => {
    });
    this.invokeServiceGetPoliciesByGroup(this.reportCore.groupId);
  }

  invokeFunctionSetTitle(position, event?) {
    if (event !== undefined && event !== null) {
      this.reportClaim[position].header = event.header;
      // VERIFICAR QUE LA ASIGNACIÓN NO HAYA SIDO ESPECIFICADA ANTES
    }
  }
  invokeFunctionSelectPolicyFile( event?) {
    if (event !== undefined && event !== null) {
      console.log(event.number);
      this.policyFile = event.number;
    }
  }
  invokeFunctionSelectPolicyBD(event?) {
    if (event !== undefined && event !== null) {
      console.log(event.number);
      this.policyBd = event.number;
    }
  }
  invokeFunctionOpenConfirm(template: TemplateRef<any>) {
    this.modalNotification = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
  }
}
