import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {SharedService} from '../shared-service.service';
import { ApiService } from '../api.service';
import { DatePipe } from '@angular/common';
import {TabDirective, TabsetComponent} from 'ngx-bootstrap/tabs';
import {SwiperComponent} from 'ngx-useful-swiper';
import {SwiperEvent, SwiperOptions} from 'swiper';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {Documents} from '../upload-file/documents';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-home-executive',
  templateUrl: './home-executive.component.html',
  styleUrls: ['./home-executive.component.css']
})
export class HomeExecutiveComponent implements OnInit {
  // variables para graficas
  chartPolicyFilterArray: string[] = [
    'Número de pólizas emitidas en el mes',
    'Número de pólizas emitidas de manera acumulada en el año',
    'Pólizas pagadas',
    'Pólizas pendientes de pago',
    'Pólizas canceladas'
  ]; // Variable que contiene los posibles filtros para la gráfica de pòlizas
  chartPolicyFilter: string = this.chartPolicyFilterArray[0]; // Variable que indica el filtro seleccionado para la gráfica de pòlizas
  @ViewChild('chartPolicyId', {static: false}) private chartPolicyId; // Elemento para la gráfica de pólizas
  chartPolicy: any; // Variable que contiene los datos para la gráfica de pólizas
  @ViewChild('chartRenovationId', {static: false}) private chartRenovationId; // Elemento para la gráfica de renovaciones
  chartRenovation: any; // Variable que contiene los datos para la gráfica de renovaciones
  @ViewChild('chartCollectionId', {static: false}) private chartCollectionId; // Elemento para la gráfica de cobranza
  chartCollection: any; // Variable que contiene los datos para la gráfica de cobranza
  @ViewChild('chartUDIId', {static: false}) private chartUDIId; // Elemento para la gráfica de UDI
  chartUDI: any; // Variable que contiene los datos para la gráfica de UDI
  @ViewChild('chartIssuedByBrandId', {static: false}) private chartIssuedByBrandId; // Elemento para la gráfica de pólizas emitidas por marca
  chartIssuedByBrand: any; // Variable que contiene los datos para la gráfica de pólizas emitidas por marca
  filterChart: any = {// Variable que contiene los filtros para las gráficas
    dateStart: new Date(),
    dateEnd: new Date()
  };
  // variables para polizas
  @ViewChild('swiperPolicy', {static: false}) private swiperPolicy: SwiperComponent;
  swiperPoliciesConf: SwiperOptions = {
    effect: 'coverflow',
    pagination: {el: '.swiper-pagination', clickable: true},
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0,
    height: 200,
    direction: 'vertical',
    mousewheel: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    }
  };
  policiesIndicators: any = [];
  policiesTypesList: any = [];
  lclPolicyTypeSelected: any = {};
  policiesList: any = [];
  lclPolicySelected: any = {};
  policyDetail: any = {};
  policyCoverageList: any = [];
  policyAttachmentsList: any = [];
  // variables reportes
  reportList: any = [];
  // variables recibos
  receiptsIndicators: any = [];
  receiptsList: any = [];
  insuranceList: any = [];
  dependentsListData: any = [];
  lclInsuranceIdSelected: number;
  receiptStatusList: any = [];
  lclReceiptStatusSelected: any = {};
  receiptDetail: any = {};
  receiptAttachmentsList: any = [];
  // variables asegurados
  insuredList: any = [];
  insuredListForTable: any = [];
  dependentsList: any = [];
  lclSectionSelected: number;
  bsStart: Partial<BsDatepickerConfig>;
  // variable para busqueda
  lastkeydown1: number = 0;
  // variable para la lista de grupos y clientes
  groupClientList: any = [];
  requestList: any = [];


  matchGroupClientList: any = [];
  groupId: number;
  groupIdFilters: number;
  groupIdForSiniestralidadPrimDev= 40;
  groupIdForSiniestralidadPrimDevToSearch = {groupId: 40, name: '', parent: 0};
  subranchIdForSiniestralidadPrimDev: number;
  clientId: number;
  clientIdFilter: number;
  clientIdTemp: number;
  clientIdReports: number;
  catClients: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  idsClients: any = {};
  numbersPoliciesList: any = [];
  numbersPoliciesListInsured: any = [];
  policySelected: number;
  catSelected = null;
  numbersPoliciesAux: any = {};
  catCategories: any = [];
  categorySelected: number;
  policiesSelectedsList: any = [];
  policieSelected: any = {};
  policyIdSelected: number;
  showSalary: boolean = false;
  showWorkSeniority: boolean = false;
  categoryId: number;
  isVisibleTable: boolean = false;
  isVisibleFormInsured: boolean = false;
  isVisibleFormDependent: boolean = false;
  password: string;
  bsLeavingDate: Partial<BsDatepickerConfig>;
  bsLeavingDateBirthDate: Partial<BsDatepickerConfig>;
  bsLeavingDateBirthDateUpdate: Partial<BsDatepickerConfig>;
  createAt: Date;
  createAtDependent: string;
  birthDate: Date;
  lowDateInsured: Date;
  endosoLow: string;
  lowDateDependent: Date;
  birthDateDependent: string;
  //birthDateDependentUpdate:string;
  modalRef: BsModalRef;
  @ViewChild('templateCancelPolicy', {static: false})
  templateCancelPolicy: TemplateRef<any>;
  @ViewChild('attachmentProvitions', {static: false})
  attachmentProvitions: TemplateRef<any>;
  @ViewChild('templateUpdateInsured', {static: false})
  templateUpdateInsured: TemplateRef<any>;
  optionGeneralSelected: number;
  cancelationId: number;
  branchIdCancellation: number;
  reasonsCancellationList: any = [];
  modalRefProv: BsModalRef;
  modalRefPassword: BsModalRef;
  modalRefCertificate: BsModalRef;
  modalRefSin: BsModalRef;
  selectedType: number = 1;
  selectedTypeDelete: number = 1;
  selectedTypeUpdate: number = 0;
  userId: number;
  userUpdate: number;
  insuredId: number;
  viewFormDeleteInsured: boolean;
  insuredName: string;

  isDependientSelected: boolean = false;
  isInsuredSelected: boolean = true;
  // Variables para typeahead (búsqueda de asegurados) ->
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  lclInsuredSelected: any = {};

  policiesLowSelectedItems: any = [];

  // Variables para typeahead (búsqueda de asegurados baja) ->
  dropdownSettingsPoliciesLow = {};

  // config select dependientes
  dependentsListByInsured: any = [];
  dependentsLowSelectedItems: any = [];
  dependentsEditSelectedItems: any = [];
  dropdownSettingsDependentsEdit: any = {};
  dropdownSettingsDependentsLow: any = {};


  // variables para procesar solicitudes
  lclRequestDetail: any = [];
  lclRequest: any = [];
  // variable para provisiones

  provisions: any = [];

  // datos actualizacion asegurado
  nameUpdate: string;
  lastNameUpdate: string;
  firstNameUpdate: string;
  emailUpdate: string;
  genderUpdate: number;
  contactUpdate: string;
  emailSecondaryUpdate: string;
  employerNumberUpdate: string;
  typekinshipId: number;
  birthDateDependentUpdate: Date;
  certificateFileUsers: any = [];
  dataUpdataInsured: any = {};

  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  public fileUploadAttachment: File;
  public fileUploadAttachmentInsured: File;
  dataToSendAttachment: any = {};

  //clientId:number;
  nameGroup: string;
  emaiValidate: string = '';
  isEmailValidate: boolean = true;
  kinshipId: number;

  pgClientBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgProvitionsTotalItems: number; // Total de registros
  pgProvitionCurrentPage = 1; // Página actual
  pgProvitionsMaxSize = 3; // Número de links en paginado
  pgProvitionsItemsPerPage: number;
  dataFilters: any = {};
  page = 1;
  size = 10;

  /*paginado  asegurados */
  pgInsuredsBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgInsuredsTotalItems: number; // Total de registros
  pgInsuredsCurrentPage = 1; // Página actual
  pgInsuredsMaxSize = 3; // Número de links en paginado
  pgInsuredsItemsPerPage: number;
  dataFiltersInsureds: any = {};
  sizeInsured = 5;

  //
  catGenders: any = [];
  uploadedFilesToLoad: Array<Documents> = []; // Documentos del cliente
  uploadedFilesAntique: Array<Documents> = []; // Documentos del cliente
  certificateFileUserSelected: any;
  // variables para siniestros
  incidentIndicators: any = {};
  searchIncidentSelected: string;
  typeaheadLoadingIncident: boolean;
  dataSourceIncident: Observable<any>;

  filters: any = {};
  filtersCobranza: any = {};
  typeGraphic = 1;
  typeGraphicCobranza = 1;
  typeChartIncident = 1;
  typeGraphicPolicy = 1;
  filtersIncident: any = {};
  filtersIncidentReport: any = {};
  idGraphic = '8';
  showHome = 1;
  subranchlist: any = [];
  dropdownSettingsClient = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  clientList: any = [];
  groupList: any = [];
  typePolice: any =  [];
  branchList: any = [];
  branchSelectedItems: any = [];
  dropdownSettingsBranch: any = {};
  subBranchList: any = [];
  subBranchSelectedItems: any = [];
  dropdownSettingsSubBranch: any = {};
  executiveSurexList: any = [];
  statusPolicyList: any = [];
  surexSelectedItems: any = [];
  statusPolicySelectedItems: any = [];
  dropdownSettingsSurex: any = {};
  dropdownSettingsStatusPolicy: any = {};
  insurancesList: any = [];
  insurancesSelectedItems: any = [];
  dropdownSettingsInsurances = {};
  salesmanList: any = [];
  salesmanSelectedItems: any = [];
  dropdownSettingsSalesman: any = {};
  filterRangeDate: Date[];
  bsConfig: Partial<BsDatepickerConfig>;
  roleId;
  dateStart;
  dateEnd;
  actualYear;
  fecha;
  userLoginId;
  genderSelected;
  @ViewChild('gestionTabs', {static: false}) staticTabs: TabsetComponent;
  @ViewChild(NgSelectComponent, {static: false}) ngSelectGender: NgSelectComponent;
  isCollapsedGraphic: boolean = true;
  requestId: 0;

  groupListProv: any = [];
  catClientsProv: any = [];
  policyListProv: any = [];
  groupIdProv: any;
  policy: any;
  accionList: any = [];
  policiesToCancelOrEmailsToSend: string;

  constructor(private router: Router,
              public shared: SharedService,
              private apiService: ApiService,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              private modalService: BsModalService,
              private http: HttpClient,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService
  ) {

    this.accionList = [
      {id: 1, description: 'Cancelar pólizas masivamente'},
      {id: 2, description: 'Obtener lista de asegurados'},
      {id: 3, description: 'Actualizar asegurados'},
      {id: 4, description: 'Envío de correos manualmente'}
    ];
    this.shared.gSkin = '#448AA6'; // localStorage.getItem('skin');
    this.shared.gLogo = localStorage.getItem('logo');
    this.userLoginId = localStorage.getItem('user-id');
    this.shared.fUpdateChartDataObservable().subscribe(fUpdateChartData => {
      if (fUpdateChartData) {
        this.loadChartsData(this.shared.gfilterRangeDate);
      }
    });

    this.shared.fUpdateHomeObservable().subscribe(fUpdateHome => {
      // console.log(this.shared.fUpdateHome);
      // console.log(fUpdateHome);
      if (fUpdateHome === 1) {
        this.showHome = 1;
      } else {
        this.showHome = 2;
      }
    });

    setTimeout(() => {
      this.loadChartPolicy();
      this.loadChartUDI();
      this.loadChartIssuedByBrand();
    }, 1000);

    /**
     * Observable para monitorear los cambios en la barra de Búsqueda de siniestros
     */
    this.dataSourceIncident = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchIncidentSelected);
    }).pipe(
      mergeMap((token: string) => this.getStatesAsObservable(token))
    );

    this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
    localStorage.removeItem('filters');
    this.typeGraphic = 1;
    this.fecha = new Date();
    this.actualYear = this.fecha.getFullYear();
    this.dateStart = this.actualYear.toString().concat('-01-01');
    this.dateEnd = this.actualYear.toString().concat('-12-31');
    this.clearFiltersTable();
    this.requestId = 0;
  }

  ngOnInit() {
    //this.cleanFieldsDependentForm();
    this.pgProvitionsItemsPerPage = this.size; // Número de registros por página
    this.pgInsuredsItemsPerPage = this.sizeInsured; // Número de registros por página
    this.invokeServiceForGetClientGroupList();
    this.bsStart = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: new Date()
    });
    this.lclSectionSelected = 0;
    // obtiene el password para e usuario
    this.invokeServiceForGetPasswordUser();
    this.roleId = Number(localStorage.getItem('roleId'));
    const currentDateMinus30Days = new Date();
    currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - (this.roleId === 1 || this.roleId === 2 ? undefined : 90));
    const currentDatePlus30Days = new Date();
    currentDatePlus30Days.setDate(currentDatePlus30Days.getDate() + (this.roleId === 1 || this.roleId === 2 ? undefined : 90));

    const currentDate = new Date();
    currentDate.setDate(currentDatePlus30Days.getDate());

    this.bsLeavingDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days,
      maxDate: currentDatePlus30Days
    });
    this.bsLeavingDateBirthDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: currentDate,
      adaptivePosition: true
    });
    this.bsLeavingDateBirthDateUpdate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: currentDate,
      adaptivePosition: true
    });

    /**
     * Observable para monitorear los cambios en typeahead de Asegurados
     */
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getInsuredAsObservable(token))
    );
    // console.log('this.dataSource');
    // console.log(this.dataSource);

    //configuracion select polizas baja
    this.dropdownSettingsPoliciesLow = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PÓLIZAS',
      searchPlaceholderText: 'BUSCAR'
    };
    //configuracion select dependientes baja
    this.dropdownSettingsDependentsLow = {
      singleSelection: false,
      idField: 'kinshipId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON DEPENDIENTES',
      searchPlaceholderText: 'BUSCAR'
    };

    this.getGroups();
    this.getBranches();
    this.getExecutives(2);
    this.getStatusPolicy();
    this.getInsuranceCompanies();
    this.getSalesman();
    this.invokeServiceGetSubranches();
    this.invokeGetSubranchByGroup(this.groupIdForSiniestralidadPrimDevToSearch);
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };

    this.dropdownSettingsClient = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsBranch = {
      singleSelection: false,
      idField: 'branchId',
      textField: 'branchType',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON RAMAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSubBranch = {
      singleSelection: false,
      idField: 'subBranchId',
      textField: 'subBranch',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB RAMOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSurex = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS SUREXS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatusPolicy = {
      singleSelection: false,
      idField: 'statusPolicyId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsInsurances = {
      singleSelection: false,
      idField: 'insuranceId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ASEGURADORAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSalesman = {
      singleSelection: false,
      idField: 'userId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON VENDEDORES',
      searchPlaceholderText: 'BUSCAR'
    };

  }

  // fired every time search string is changed
  getInsuredAsObservable(token: string): Observable<any> {
    return this.apiService.getInsuredList(this.groupId, this.clientId, token).pipe();
  }

  openModalProvisions(template: TemplateRef<any>) {
    this.dataFilters = {"subgroupId": -1, "clientId": 6, "size": this.size, "page": this.page};
    this.invokeServiceForGetProvisions(this.dataFilters);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, {class: 'classmodelprovitions'})
    );
  }

  openModal(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(78).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        if (this.groupId != null) {
          this.modalRef = this.modalService.show(
            template
          );
        } else {
          this.toastr.warning('Seleccione un grupo o cliente para continuar');
        }
      }
    });
  }

  openModalEditInsured(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(80).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        if (this.groupId != null) {
          this.modalRef = this.modalService.show(template);
          this.invokeServiceForGetInsuredList();
          this.userId = null;
        } else {
          this.toastr.warning('Seleccione un grupo o cliente para continuar');
        }
      }
    });
  }

  openModalEditInsuredFromTable(template: TemplateRef<any>, user) {
    if (this.groupId != null) {
      this.modalRef = this.modalService.show(template);
      this.invokeServiceForGetInsuredList();
      this.userId = null;
      this.selectedTypeUpdate = 0;
      this.selecEditClient(user);
    } else {
      this.toastr.warning('Seleccione un grupo o cliente para continuar');
    }

  }

  openModalEditCertificate(template: TemplateRef<any>, element) {
    this.modalRefCertificate = this.modalService.show(template);
    this.uploadedFilesToLoad = [];
    this.certificateFileUserSelected = element;
  }

  openModalRequest(template: TemplateRef<any>) {
    this.getEventInfoRequest();
    this.modalRef = this.modalService.show(template);
  }

  openModalRequestDetail(template: TemplateRef<any>, requestId) {
    this.getRequestDetail(requestId);
    this.modalRef = this.modalService.show(template);
  }

  openModalCancelPoliciesDetail(option: number) {

    switch (option) {
      case 1:
        this.optionGeneralSelected = 3;
        this.getBranches();
        this.modalRef = this.modalService.show(this.templateCancelPolicy, {class: 'modal-md', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        break;
      case 2:
        this.getGroupsProv();
        this.optionGeneralSelected = 1;
        this.modalRefProv = this.modalService.show(this.attachmentProvitions, {class: 'modal-md', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        break;
      case 3:
        this.getGroupsProv();
        this.modalRef = this.modalService.show(this.templateUpdateInsured, {class: 'modal-md', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        break;
      case 4:
        this.optionGeneralSelected = 4;
        this.getGroupsProv();
        this.modalRef = this.modalService.show(this.templateCancelPolicy, {class: 'modal-md', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        break;
    }


  }

  sendPoliciesToCancel(numberPoliciesString: string) {
    if (this.cancelationId === undefined || this.cancelationId === null) {
        this.toastr.warning('Favor de agregar un motivo', 'Notificación');
        return;
    }

    const cancelpolicy = {
          numbersPolicy: numberPoliciesString,
          idReasonCancelation: this.cancelationId
    };

    this.apiService.sendPoliciesForCancell(cancelpolicy).then(  (response: any) => {
      this.modalRef.hide();
      this.toastr.success('Su solicitud se ha realizado de forma exitosa, en cuanto termine, se le notificará', 'NOTIFICACIÓN');
      this.cancelationId === null;
      this.branchIdCancellation === null;
    }, error => {
      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
      this.spinner.hide('sp');
    });
  }

  closePoliciesToCancel() {
    this.modalRef.hide();
    this.policiesToCancelOrEmailsToSend = '';
  }

  updateAttachment() {
    this.certificateFileUserSelected.base64File = this.uploadedFilesToLoad[0].base64;
    this.certificateFileUserSelected.nameFile = this.uploadedFilesToLoad[0].name;
    this.modalRefCertificate.hide();
  }

  onManagementTabSelect(data: TabDirective): void {
    this.lclSectionSelected = Number(data.id);

    switch (this.lclSectionSelected) {
      case 0:
        this.invokeServiceForLoadReports();
        break;
      case 1:
        this.invokeServiceForIndicatorsReceipts();
        // this.invokeServiceForGetReceiptsListStatus(1, 1, 5);
        break;
      case 2:
        // console.info('ENTRO AQUI ' + this.lclSectionSelected);
        break;
      case 3:
        // console.info('ENTRO AQUI ' + this.lclSectionSelected);
        this.invokeServiceForIndicatorsPolicy();
        // this.invokeServiceForGetPoliciesListStatus(1, 1, 5);
        break;
      case 4:

        // console.info('ENTRO AQUI ' + this.lclSectionSelected);
        break;
      case 5:
        this.validateAccess.validateAccessCore(83).then((access: any) => {
          console.log('valor que regresa', access.accessCore);
          if (access.accessCore === 0) {
            this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
          } else {
            this.shared.gRequestGeneralId.next(0);
            this.router.navigate(['/admin/request']);
          }
        });
        // console.info('ENTRO AQUI ' + this.lclSectionSelected);
        break;
      case 6:
        // console.info('ENTRO AQUI ' + this.lclSectionSelected);
        break;
      case 7: // siniestros
        this.invokeServiceForIndicatorsIncident();
        break;
    }
  }

  maximizeSection() {
    switch (this.lclSectionSelected) {
      case 0:
        this.router.navigate(['/admin/reports']);
        break;
      case 1:
        this.shared.gSearchId = 2;
        this.router.navigate(['/admin/receipts']);
        break;
      case 2:
        break;
      case 3:
        this.shared.fPolicyDataReadonly = true;
        this.shared.gSearchId = 1;
        this.router.navigate(['/admin/policies']);
        break;
      case 4:
        /*
        if (this.insuredList.length > 0) {
          this.shared.fInsuredDataReadonly = true;
          localStorage.setItem('insuredView', JSON.stringify(this.insuredList[0]));
          this.router.navigate(['/admin/insured']);
        }*/
        break;
      case 5:
        this.router.navigate(['/admin/request']);
        break;
    }
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |------------------------------------------|  G R A F I C A S  |------------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  loadChartsData(filterRangeDate: Date[]) {
    // console.log(filterRangeDate);

    if (filterRangeDate == null) {
      return false;
    }
    // console.log(filterRangeDate[0]);
    // console.log(filterRangeDate[1]);
    this.filterChart.dateStart = this.datePipe.transform(filterRangeDate[0], 'yyyy-MM-dd');
    this.filterChart.dateEnd = this.datePipe.transform(filterRangeDate[1], 'yyyy-MM-dd');
    // console.log(this.filterChart);
    this.loadChartPolicy();
    this.loadChartUDI();
    this.loadChartIssuedByBrand();
    this.shared.fUpdateChartData.next(false);
  }

  loadChartPolicy() {
    /* this.apiService.getDataChartPolicy(this.filterChart.dateStart, this.filterChart.dateEnd, this.shared.gfilterAgency).subscribe( (response) => {
      let labels = [];
      let data = [];
      let bckgnd = [];

      for (let obj in response){
        labels.push(response[obj].month);
        data.push({month: response[obj].month, total: response[obj].total});
        bckgnd.push(this.shared.gSkin);
      }


      let chart = am4core.create(this.chartPolicyId.nativeElement, am4charts.XYChart);
      //chart.scrollbarX = new am4core.Scrollbar();

      // Add data
      chart.data = data;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'month';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minHeight = 110;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = 'total';
      series.dataFields.categoryX = 'month';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 0;

      series.tooltip.pointerOrientation = 'vertical';

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;

      let listColors = this.generateColor('#FFFFFF', this.shared.gSkin, data.length + data.length);
      let seriesColors = [];
      for (let obj of listColors) {
        seriesColors.push(am4core.color('#' + obj));
      }
      chart.colors.list =  seriesColors;

      let hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      chart.cursor = new am4charts.XYCursor();

    }, error => {

    });
*/
  }

  loadChartUDI() {
    /*
        this.apiService.getDataChartUdi(this.filterChart.dateStart, this.filterChart.dateEnd, this.shared.gfilterAgency).subscribe( response => {

          let labels = [];
          let dt = [];

          for (let obj in response){
            labels.push(response[obj].month);
            dt.push({date: response[obj].date, total: response[obj].total});
          }

          let chart = am4core.create(this.chartUDIId.nativeElement, am4charts.XYChart);
          chart.language.locale = am4lang_es_ES;

          let listColors = this.generateColor('#FFFFFF', this.shared.gSkin, dt.length + dt.length);
          let seriesColors = [];
          for (let obj of listColors) {
            seriesColors.push(am4core.color('#' + obj));
          }
          chart.colors.list =  seriesColors;

          chart.data = dt;

          let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
          dateAxis.renderer.grid.template.location = 0;
          dateAxis.renderer.minGridDistance = 50;

          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.logarithmic = true;
          valueAxis.renderer.minGridDistance = 20;

          let series = chart.series.push(new am4charts.LineSeries());
          series.dataFields.valueY = 'total';
          series.dataFields.dateX = 'date';
          series.tensionX = 0.8;
          series.strokeWidth = 3;

          let bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.fill = am4core.color('#fff');
          bullet.circle.strokeWidth = 3;

          chart.cursor = new am4charts.XYCursor();
          chart.cursor.fullWidthLineX = true;
          chart.cursor.xAxis = dateAxis;
          chart.cursor.lineX.strokeWidth = 0;
          chart.cursor.lineX.fill = am4core.color('#000');
          chart.cursor.lineX.fillOpacity = 0.1;

          let range = valueAxis.axisRanges.create();
          range.value = 90.4;
          range.grid.stroke = am4core.color(this.shared.gSkin);
          range.grid.strokeWidth = 1;
          range.grid.strokeOpacity = 1;
          range.grid.strokeDasharray = '3,3';
          range.label.inside = true;
          range.label.text = 'Promedio';
          range.label.fill = range.grid.stroke;
          range.label.verticalCenter = 'bottom';
        });
     */
  }

  loadChartIssuedByBrand() {
    /*
    this.apiService.getDataChartIssuedByBrand(this.filterChart.dateStart, this.filterChart.dateEnd, this.shared.gfilterAgency).subscribe( response => {
      let data = [];
      for (let obj in response){
        data.push({mark: response[obj].mark, total: response[obj].total});
      }
      let labels = [];
      let dt = [];
      let bckgnd = [];
      for (let obj in response) {
        labels.push(response[obj].mark);
        dt.push({mark: response[obj].mark, total: response[obj].total});
        bckgnd.push(this.shared.gSkin);
      }


      let chartIssuedByBrand = am4core.create(this.chartIssuedByBrandId.nativeElement, am4charts.XYChart);
      chartIssuedByBrand.hiddenState.properties.opacity = 0; // this makes initial fade in effect

      chartIssuedByBrand.data = data;

      let listColors = this.generateColor('#FFFFFF', this.shared.gSkin, data.length + data.length);
      let seriesColors = [];
      for (let obj of listColors) {
        seriesColors.push(am4core.color('#' + obj));
      }
      chartIssuedByBrand.colors.list = seriesColors;


      let categoryAxis = chartIssuedByBrand.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = 'mark';
      categoryAxis.renderer.minGridDistance = 40;

      let valueAxis = chartIssuedByBrand.yAxes.push(new am4charts.ValueAxis());

      let series = chartIssuedByBrand.series.push(new am4charts.CurvedColumnSeries());
      series.dataFields.categoryX = 'mark';
      series.dataFields.valueY = 'total';
      series.tooltipText = '{valueY.value}';
      series.columns.template.strokeOpacity = 0;

      series.columns.template.fillOpacity = 0.75;

      let hoverState = series.columns.template.states.create('hover');
      hoverState.properties.fillOpacity = 1;
      hoverState.properties.tension = 0.4;

      chartIssuedByBrand.cursor = new am4charts.XYCursor();

      series.columns.template.adapter.add('fill', function(fill, target) {
        return chartIssuedByBrand.colors.getIndex(target.dataItem.index);
      });

    });
    */
  }

  hex(c) {
    var s = '0123456789abcdef';
    var i = parseInt(c);
    if (i == 0 || isNaN(c))
      return '00';
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - i % 16) / 16) + s.charAt(i % 16);
  }

  /* Convert an RGB triplet to a hex string */
  convertToHex(rgb) {
    return this.hex(rgb[0]) + this.hex(rgb[1]) + this.hex(rgb[2]);
  }

  /* Remove '#' in color hex string */
  trim(s) {
    return (s.charAt(0) == '#') ? s.substring(1, 7) : s
  }

  /* Convert a hex string to an RGB triplet */
  convertToRGB(hex) {
    var color = [];
    color[0] = parseInt((this.trim(hex)).substring(0, 2), 16);
    color[1] = parseInt((this.trim(hex)).substring(2, 4), 16);
    color[2] = parseInt((this.trim(hex)).substring(4, 6), 16);
    return color;
  }

  generateColor(colorStart, colorEnd, colorCount) {

    // The beginning of your gradient
    var start = this.convertToRGB(colorStart);

    // The end of your gradient
    var end = this.convertToRGB(colorEnd);

    // The number of colors to compute
    var len = colorCount;

    // Alpha blending amount
    var alpha = 0.0;

    var saida = [];

    for (let i = 0; i < len; i++) {
      var c = [];
      alpha += (1.0 / len);

      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];

      saida.push(this.convertToHex(c));

    }

    return saida;

  }

  /*+-------------------------------------------------------------------------------------------------------+
  |------------------------------------------|  P O L I Z A S  |------------------------------------------|
  +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceForGetPoliciesListStatus(id: number) {
    this.validateAccess.validateAccessCore(72).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        switch (id) {
          case 1:
            localStorage.setItem('filter-policies',   'active');
            break;
          case 2:
            localStorage.removeItem('filter-policies');
            break;
          case 3:
            localStorage.setItem('filter-policies',   'renewals');
            break;
          case 4:
            localStorage.setItem('filter-policies',   'cancellations');
            break;
          case 5:
            localStorage.removeItem('filter-policies');
            break;
        }
        if (id === 2) {
          this.router.navigate(['/admin/policies_orders']);
        } else {
          this.router.navigate(['/admin/policies_reports']);
        }
      }
    });
  }

  invokeServiceForIndicatorsPolicy() {
    this.apiService.getPolicyIndicators()
      .then(
        (response) => {
          this.policiesIndicators = response;
        }, error => {
          // console.error(error);
        }
      );
  }
  // todo ammia lite
  openNewDocument(option: number) {
    let accessCore = 0;
    if (option === 1) {
      accessCore = 74;
    } else {
      accessCore = 75;
    }
    this.validateAccess.validateAccessCore(accessCore).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.shared.fPolicyDataReadonly = false;
        switch (option) {
          case 1:
            this.shared.gPolicyId.next(0);
            this.router.navigate(['/admin/policies']);
            break;
          case 2:
            this.shared.gPolicyIdLite.next(0);
            this.router.navigate(['/admin/ammia_lite']);
            break;
          case 3:
            this.shared.fPolicyDataReadonly = false;
            this.shared.gPolicyIdDamage.next(0);
            this.router.navigate(['/admin/damage-policy']);
            break;
          case 4:
            this.shared.fPolicyDataReadonly = false;
            this.shared.gPolicyIdDamage.next(0);
            this.router.navigate(['/admin/massive-load-policy']);
            break;
          case 5:
            this.shared.fPolicyDataReadonly = false;
            this.shared.gPolicyIdDamage.next(0);
            this.router.navigate(['/admin/automation-list']);
            break;
        }
      }
    });
  }

  public hexToRgb(hex, opacity) {
    const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));

    return `rgba(${rgb.toString()}, ${opacity})`;
  }

  /*+-------------------------------------------------------------------------------------------------------+
  |------------------------------------------|  R E P O R T E S  |------------------------------------------|
  +-------------------------------------------------------------------------------------------------------+*/
  public invokeServiceForLoadReports() {
    this.router.navigate(['/admin/report_core']);
  }


  /*+-------------------------------------------------------------------------------------------------------+
  |------------------------------------------|  R E C I B O S  |------------------------------------------|
  +-------------------------------------------------------------------------------------------------------+*/
  invokeServiceForGetReceiptsListStatus(id: number) {
    this.validateAccess.validateAccessCore(68).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        switch (id) {
          case 1:
            localStorage.setItem('filter-receipts',   'paid');
            break;
          case 2:
            localStorage.setItem('filter-receipts',   'pending');
            break;
          case 3:
            localStorage.setItem('filter-receipts',   'cancellations');
            break;
          case 4:
            localStorage.removeItem('filter-receipts');
            break;
        }
        this.shared.gSearchId = 2;
        this.router.navigate(['/admin/receipts']);
      }
    });
  }

  invokeServiceForIndicatorsReceipts() {
    this.apiService.getReceiptIndicators()
      .then(
        (response) => {
          this.receiptsIndicators = response;
        }, error => {
          // console.error(error);
        }
      );
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |---------------------------------------|  A S E G U R A D O S  |---------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  /**
   * Método para obtener una lista de Asegurados
   * la lista se obtiene a través del servicio REST @getInsuredList
   * se indican los parametros de paginado Número de Pagina, Tamaño del pagiinado
   *
   * 1.- Invocamos el servicio rest y recuperamos la respuesta en nuestra variable @insuredList
   */
  invokeServiceForGetInsuredList() {
    this.isVisibleTable = true;
    this.isVisibleFormDependent = false;
    this.isVisibleFormInsured = false;
    this.viewFormDeleteInsured = false;
    this.insuredList = [];

    this.apiService.getInsuredList(this.groupIdFilters, this.clientIdFilter, "")
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.insuredList = response;
          } else {
            this.insuredList = [];
          }
        }, error => {
          // console.error(error);
        });
  }

  /**
   * Método para obtener una lista de Asegurados
   * la lista se obtiene a través del servicio REST @getInsuredList
   * se indican los parametros de paginado Número de Pagina, Tamaño del pagiinado
   *
   * 1.- Invocamos el servicio rest y recuperamos la respuesta en nuestra variable @insuredList
   */
  invokeServiceForGetInsuredListPaginated(data) {
    this.isVisibleTable = true;
    this.isVisibleFormDependent = false;
    this.isVisibleFormInsured = false;
    this.viewFormDeleteInsured = false;
    this.insuredListForTable = [];
    this.apiService.getInsuredListPaginated(data)
      .then(
        (response: any) => {
          if (response !== null && response !== undefined) {
            if (response.length > 0) {
              this.insuredListForTable = response;
              this.pgInsuredsTotalItems = response[0].totalData;
            }
          } else {
            this.pgInsuredsTotalItems = 0;
            this.toastr.info('No se encontraron registros');
          }

        }, error => {
          // console.error(error);
        });
  }

  public invokeServiceForDownloadFileInsured() {
    this.apiService.getFileInsured()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'Asegurados.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          // console.error(error);
        }
      );
  }

  public viewLikeInsured(insured) {
    this.shared.fInsuredDataReadonly = true;
    localStorage.setItem('insuredView', JSON.stringify(insured));
    this.router.navigate(['/admin/insured']);
  }

  invokeServiceForGetClientGroupList() {
    this.isVisibleTable = true;
    this.isVisibleFormDependent = false;
    this.isVisibleFormInsured = false;
    // console.log('groupClientList');
    this.apiService.getClientGroupList()
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.groupClientList = this.groupClientList.concat(response);
            // console.log('groupClientList');
            // console.log(this.groupClientList);
            //setea el valor del primer idCliente

          } else {
            this.groupClientList = [];
          }

        }, error => {

        });

  }

  public addDependets( dependentToAdd?: any, policyList?: any, startAt?:any) {
    let dependent;
    let name;
    let firstName;
    let lastName;
    let typeKinshipId;
    let gender;

    // obtener el numero de la poliza
    this.policieSelected = {};
    let typeDependet = '';
    if (dependentToAdd !== undefined) {
      name = dependentToAdd.name;
      firstName = dependentToAdd.firstName;
      lastName = dependentToAdd.lastName;
      typeKinshipId = dependentToAdd.typeDependent;
      gender = dependentToAdd.gender;
      this.birthDateDependent = dependentToAdd.birthdate;
      this.createAtDependent = startAt;

      switch (typeKinshipId) {
        case '1':
          typeDependet = 'CÓNYUGE';
          break;
        case '2':
          typeDependet = 'HIJO(A)';
          break;
        case '3':
          typeDependet = 'ASCENDENTE';
          break;
        case '4':
          typeDependet = 'PADRES';
          break;
      }


        if (
          name === '' ||
          firstName === '' ||
          this.birthDateDependent === null ||
          this.createAtDependent === null // ||
          // this.policyIdSelected === null ||
          // this.policyIdSelected === undefined ||
          // this.categorySelected === null ||
          // this.categorySelected === undefined
          // this.uploadedFilesToLoad.length === 0
        ) {
          this.toastr.warning('Por favor llene todos los campos requeridos');
        } else if (typeKinshipId === '2' && !this.onCalculateAgeDependent(true)) {
          return;
        } else {
          const date = new Date(this.createAtDependent);
          const year = date.getFullYear();
          const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
          const month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);
          const createAtDependenFinal = year + '-' + month + '-' + day;

          const dateBirtDate = new Date(this.birthDateDependent);
          const yearBirtdate = dateBirtDate.getFullYear();
          const dayBirtdate = dateBirtDate.getDate() > 9 ? dateBirtDate.getDate() : "0" + dateBirtDate.getDate();
          const monthBirtdate = (dateBirtDate.getMonth() + 1) > 9 ? (dateBirtDate.getMonth() + 1) : "0" + (dateBirtDate.getMonth() + 1);
          const birthdateDependenFinal = yearBirtdate + '-' + monthBirtdate + '-' + dayBirtdate;

          dependent = {
            name,
            firstName,
            lastName,
            // endosoDependent,
            birthdate: birthdateDependenFinal,
            typeKinshipId,
            userId: this.userId,
            gender,
            typeDependet,
            createAt: createAtDependenFinal,
            policy: {}
          };

          this.dependentsList.push(dependent);
        }

    } else {
      name = (document.getElementById('nameDependent') as HTMLInputElement).value.toUpperCase();
      firstName = (document.getElementById('firstNameDependent') as HTMLInputElement).value.toUpperCase();
      lastName = (document.getElementById('lastNameDependent') as HTMLInputElement).value.toUpperCase();
      typeKinshipId = (document.getElementById('typeDependent') as HTMLInputElement).value;
      gender = (document.getElementById('genderDependent') as HTMLInputElement).value;

      switch (typeKinshipId) {
        case '1':
          typeDependet = 'CÓNYUGE';
          break;
        case '2':
          typeDependet = 'HIJO(A)';
          break;
        case '3':
          typeDependet = 'ASCENDENTE';
          break;
        case '4':
          typeDependet = 'PADRES';
          break;

      }

      if (
        name === '' ||
        firstName === '' ||
        this.birthDateDependent === null ||
        this.createAtDependent === null ||
        this.policyIdSelected === null ||
        this.policyIdSelected === undefined ||
        this.categorySelected === null ||
        this.categorySelected === undefined
        // this.uploadedFilesToLoad.length === 0
      ) {
        this.toastr.warning('Por favor llene todos los campos requeridos');
      } else if (typeKinshipId === '2' && !this.onCalculateAgeDependent(true)) {
        return;
      } else {
        let date = new Date(this.createAtDependent);
        let year = date.getFullYear();
        let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
        let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);
        let createAtDependenFinal = year + '-' + month + '-' + day;

        let dateBirtDate = new Date(this.birthDateDependent);
        let yearBirtdate = dateBirtDate.getFullYear();
        let dayBirtdate = dateBirtDate.getDate() > 9 ? dateBirtDate.getDate() : "0" + dateBirtDate.getDate();
        let monthBirtdate = (dateBirtDate.getMonth() + 1) > 9 ? (dateBirtDate.getMonth() + 1) : "0" + (dateBirtDate.getMonth() + 1);
        let birthdateDependenFinal = yearBirtdate + '-' + monthBirtdate + '-' + dayBirtdate;

        let policeExist = false;
        for (const dato in this.numbersPoliciesList) {
          if (this.policyIdSelected === this.numbersPoliciesList[dato]['id']) {
            this.policieSelected.policyId = this.policyIdSelected;
            this.policieSelected.numberPolicy = this.numbersPoliciesList[dato]['title'];
          }
        }
        // obtener el nombre del cliente
        let nombre;
        for (const dato in this.catClients) {
          if (this.clientId === this.catClients[dato].clientId) {
            nombre = this.catClients[dato].name;
          }
        }

        for (const dato in this.catCategories) {
          if (Number(this.categorySelected) === this.catCategories[dato].categoryId) {
            this.policieSelected.nameCategory = this.catCategories[dato].name;
            this.policieSelected.categoryId = this.catCategories[dato].categoryId;
          }
        }

        this.policieSelected['nameClient'] = nombre;
        // this.policieSelected['base'] = this.uploadedFilesToLoad[0].base64;
        // this.policieSelected['nameFile'] = this.uploadedFilesToLoad[0].name;

        let foundend = false;
        if (this.policiesSelectedsList.length !== 0) {
          this.policiesSelectedsList.forEach(item => {
            if (item.policyId === this.policieSelected.policyId) {
              foundend = true;
            }
          });
        }

        if (!foundend) {
          this.policiesSelectedsList.push(this.policieSelected);
        }


        dependent = {
          name,
          firstName,
          lastName,
          // endosoDependent,
          birthdate: birthdateDependenFinal,
          typeKinshipId,
          userId: this.userId,
          gender,
          typeDependet,
          createAt: createAtDependenFinal,
        };

        this.dependentsList.push(dependent);
    }
      // limpiar campos  campos
      this.cleanFieldsDependents();
    }

  }

  getClieteGroupFirstWay($event) {
    this.matchGroupClientList = [];

    const txtCLient = (document.getElementById('clientGroupFirstWay') as HTMLInputElement).value.toUpperCase();
    this.matchGroupClientList = [];
    if (txtCLient.length > 0) {
      if ($event.timeStamp - this.lastkeydown1 > 200) {
        this.matchGroupClientList = this.searchFromArray(this.groupClientList, txtCLient);
      }
    }
  }


  selecClient(event) {
    this.validateAccess.validateAccessCore(76).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        if (event.clientId !== -1) {
          const clientid = {
            idClient: event.clientId
          };
          this.apiService.getGroupName(clientid).then((data: any) => {
            this.nameGroup = data;
          }, error => {
          });
        } else {
          this.nameGroup = event.name;
        }


        if (event !== undefined) {
          this.invokeServiceGetGenders();
          this.groupId = event.groupId;
          this.clientIdReports = event.clientId;
          this.clientId = event.clientId;
          this.clientIdFilter = event.clientId;
          this.groupIdFilters = event.groupId;
          this.dataFiltersInsureds = {
            "clientId": this.clientIdFilter,
            "groupId": this.groupIdFilters,
            "size": this.sizeInsured,
            "page": this.page
          };

          this.invokeServiceForGetInsuredListPaginated(this.dataFiltersInsureds);
          this.invokeServiceForGetInsuredList();

          if (this.clientId === -1) {
            this.getSubGroups(this.groupId);
          } else {
            this.getClient(this.clientId);
          }
        }
      }
    });

  }

  selectInsuredForTable(event) {
    this.validateAccess.validateAccessCore(11).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.dataFiltersInsureds = {
          userId: event.userId,
          clientId: this.clientIdFilter,
          groupId: this.groupIdFilters,
          size: this.sizeInsured,
          page: 1
        };

        this.invokeServiceForGetInsuredListPaginated(this.dataFiltersInsureds);
        this.invokeServiceForGetInsuredList();

        if (this.clientId === -1) {
          this.getSubGroups(this.groupId);
        } else {
          this.getClient(this.clientId);
        }
      }
    });
  }

  clearClient() {

  }

  clearRequest(form?: NgForm) {
    if (form !== undefined) {
      this.policiesSelectedsList = [];
      form.form.enable();
      this.requestId = 0;
    }
  }

  clearClientCombobox() {
    //this.groupId=null;
    this.userId = null;
    this.kinshipId = null;

  }

  selecDependentClient(event) {
    if (event !== undefined) {
      this.userId = event.userId;
      console.log('userId');
      console.log(this.userId);
      this.invokeServiceForGetPoliciesInsuredIdForDependent(this.userId);
      this.invokeServiceForGetDependentsByInsured(this.userId);
      // obtener los dependientes
    }
  }

  clearDependentClient() {
    this.clientIdTemp = null;
  }

  selecLowDependentClient(event) {
    this.policiesLowSelectedItems = [];
    this.dependentsLowSelectedItems = [];
    if (event !== undefined) {
      this.insuredId = event.userId;
      if (Number(this.selectedTypeDelete) === 0) {
        this.invokeServiceForGetPoliciesInsuredId(this.insuredId);
      }
      this.invokeServiceForGetDependentsByInsured(this.insuredId);
      // obtener los dependientes
    } else {
      this.requestList = [];
    }
  }

  selectLowDependent() {
    this.policiesLowSelectedItems = [];
    if (this.dependentsLowSelectedItems !== undefined || this.dependentsLowSelectedItems.length !== 0) {
      if (Number(this.selectedTypeDelete) === 1) {
        this.invokeServiceForGetPoliciesDependetId(this.dependentsLowSelectedItems[0].kinshipId);
      }
    }
  }

  clearLowDependentClient() {
    this.userId = null;
  }

  selecEditClient(event) {
    if (event !== undefined) {
      this.cleanDataUpdate();
      let data = {"userId": event.userId};
      this.userUpdate = event.userId;
      if (this.selectedTypeUpdate === 1) {
        this.invokeServiceFoGetDataDependent(data);
      } else {
        this.invokeServiceForFetDataInsuredById(data);
      }
      // obtener los dependientes
    }
  }

  selecEditClientFromTable(event) {
    if (event !== undefined) {
      this.cleanDataUpdate();
      let data = {userId: event.userId};
      this.userUpdate = event.userId;
      if (this.selectedTypeUpdate === 1) {
        this.invokeServiceFoGetDataDependent(data);
      } else {
        this.invokeServiceForFetDataInsuredById(data);
      }
      // obtener los dependientes
    }
  }

  clearEditClient() {
    this.userId = null;
  }

  clearEditClientFromTable() {
    this.dataFiltersInsureds = {
      clientId: this.clientIdFilter,
      groupId: this.groupIdFilters,
      size: this.sizeInsured,
      page: 1
    };

    this.invokeServiceForGetInsuredListPaginated(this.dataFiltersInsureds);
    this.invokeServiceForGetInsuredList();

    if (this.clientId === -1) {
      this.getSubGroups(this.groupId);
    } else {
      this.getClient(this.clientId);
    }
  }

  cleanDataUpdate() {
    this.userId = null;
    this.nameUpdate = null;
    this.firstNameUpdate = null;
    this.emailUpdate = null;
    this.lastNameUpdate = null;
    this.birthDateDependentUpdate = null;
    this.kinshipId = null;
    this.certificateFileUsers = [];

  }

  cleanDataLow() {
    this.userId = null;
    this.policiesLowSelectedItems = [];
    this.lowDateDependent = null;
    this.lowDateInsured = null;
    this.endosoLow = null;
    this.dependentsListByInsured = [];


  }

  selecEditDependentClient(event) {
    if (event !== undefined) {
      this.nameUpdate = event.name;
      this.firstNameUpdate = event.firstName;
      this.lastNameUpdate = event.lastName;
      this.birthDateDependentUpdate = new Date(event.birthdate);
      this.kinshipId = event.kinshipId;
      this.typekinshipId = event.typeKinshipId;
      this.userUpdate = this.kinshipId;
      this.genderUpdate = event.gender;

    }
  }

  clearEditDependentClient() {
    this.kinshipId = null;
  }

  searchFromArray(arr, regex) {
    let matches = [], i;
    for (i = 0; i < arr.length; i++) {
      if ((arr[i].name).match(regex)) {
        matches.push(arr[i]);
      }
    }
    return matches;
  }

  onChangeDynmicNames(e) {
    // console.log('onChangeDynmicNames');
    // buscar el id del cliente o grupo apartir de la lista de match
    //for (let i =0; i<this.matchGroupClientList.size();i++){

    for (let dato in this.matchGroupClientList) {
      if (e.target.value === this.matchGroupClientList[dato]['name']) {

        this.groupId = this.matchGroupClientList[dato]['groupId'];
        this.clientId = this.matchGroupClientList[dato]['clientId'];
        this.invokeServiceForGetInsuredList();
        // tslint:disable-next-line:triple-equals
        if (this.groupId != -1) {
          this.getSubGroups(this.groupId);
        }
        // llena combobox

        break;
      }
    }
  }

  getClient(id) {
    this.apiService.getClient(id).then((data: any) => {
      if (data.length > 0) {
        this.catClients = data;
        // si no entra el changue del combox se asigna el primer elemento
        this.llenarCombobox();
      } else {
        this.catClients = [];
      }

    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  getSubGroups(id) {
    this.apiService.getSubGroups(id).then((data: any) => {
      if (data.length > 0) {
        this.catClients = data;
        // si no entra el changue del combox se asigna el primer elemento
        this.llenarCombobox();
      } else {
        this.catClients = [];
      }

    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  getPoliciesBySubGruop(event) {
    //vacia polizas

    this.idsClients = {};
    this.idsClients['clients'] = [];
    // + para convertir a entero
    this.clientId = +event.target.value;
    this.idsClients['clients'].push(this.clientId);

    this.invokeServiceForGetNumberPoliciesByIdsCLient(this.idsClients);
  }

  invokeServiceForGetNumberPoliciesByIdsCLient(idsClients) {
    this.apiService.getNumberPoliciesByIdsCLient(idsClients)
      .then(
        (response: any) => {
          if (response.length > 0) {
            this.numbersPoliciesList = response;
            // this.policyIdSelected = this.numbersPoliciesList[0].id;
            // this.invokeServiceForGetCategoriesByPolicy(this.policyIdSelected);
          } else {
            this.numbersPoliciesList = [];
          }
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          // this.toastr.error('Ocurrió un problema al procesar su petición lalo', 'Notificación');
        }
      );
  }

  invokeServiceForGetPoliciesInsuredId(insuredId) {
      this.numbersPoliciesListInsured = [];
      this.numbersPoliciesList = [];

      this.apiService.getPoliciesInsured(insuredId)
        .then(
          (response: any) => {
            if (response !== null && response.length > 0) {
              this.numbersPoliciesListInsured = response;
              this.numbersPoliciesList = response;
              if (this.isVisibleFormDependent) {
                this.policyIdSelected = this.numbersPoliciesList[0].id;
                this.invokeServiceForGetCategoriesByPolicyIdUserId(this.policyIdSelected);
              }

            } else {
              this.toastr.warning('No se encontró información del asegurado.', 'Notificación');
              this.numbersPoliciesList = [];
              this.numbersPoliciesListInsured = [];

            }
          }, error => {
            if (error.status === 409) {
              this.toastr.error('Hubo un error al obtener las pólizas del asegurado.', 'Notificación');
            } else if (error.status === 204) {
              this.toastr.warning('No se encontró información del asegurado.', 'Notificación');
            }
          }
        );
  }

  invokeServiceForGetPoliciesInsuredIdForDependent(insuredId)  {
      this.numbersPoliciesListInsured = [];
      this.numbersPoliciesList = [];
      this.catCategories = [];
      this.apiService.getPoliciesInsuredForDependent(insuredId)
        .then(
          (response: any) => {
            console.log(response);
            if (response !== null && response.length > 0) {
              this.numbersPoliciesListInsured = response;
              this.numbersPoliciesList = response;

              /*
              if (this.isVisibleFormDependent) {
                this.policyIdSelected = this.numbersPoliciesList[0].id;
                this.invokeServiceForGetCategoriesByPolicyIdUserId(this.policyIdSelected);
              }*/

            } else {

              this.numbersPoliciesList = [];
              this.numbersPoliciesListInsured = [];
            }
          }, error => {

          }
        );

  }

  invokeServiceForGetPoliciesDependetId(dependentId) {
    this.numbersPoliciesListInsured = [];
    this.apiService.getPoliciesDependent(dependentId)
      .then(
        (response: any) => {
          console.log(response);
          if (response.length > 0) {
            this.numbersPoliciesListInsured = response;
          } else {
            this.numbersPoliciesListInsured = [];
          }
        }, error => {

        }
      );
  }

  getCategoriesByPolicy(event) {
    this.showSalary = false;
    // se agrega para convertir a entero el valor
    this.policyIdSelected = event;

    this.numbersPoliciesList.some(X => {
      if (Number(X.id) === Number(this.policyIdSelected)) {
       if (Number(X.type) === 6 || Number(X.type) === 10 || Number(X.type) === 11 || Number(X.type) === 13) {
         this.showSalary = true;
         return;
       }
      }
    });

    //invoca al servicio
    this.invokeServiceForGetCategoriesByPolicy(this.policyIdSelected, true);

  }

  getCategorySelected(event) {
    this.categorySelected = event;
  }

  getCategoriesByPolicyUser(event) {
    // + se agrega para convertir a entero el valor
    this.policyIdSelected = event;

    for (const dato in this.numbersPoliciesList) {
      if (this.policyIdSelected === this.numbersPoliciesList[dato]['id']) { // Se usa el campo clientId para el subramo
        if (this.numbersPoliciesList[dato].type === 6
          || this.numbersPoliciesList[dato].type === 10
          || this.numbersPoliciesList[dato].type === 11
          || this.numbersPoliciesList[dato].type === 13) {
          this.policySelected = null;
          this.catSelected = null;
          this.catCategories = [];
          this.policyIdSelected = null;
          this.toastr.warning(
            'No se puede agregar dependientes a una póliza de vida o póliza de gastos funerarios, por favor, selecciona otra.',
            'Notificación');
          break;
        } else {
          this.invokeServiceForGetCategoriesByPolicyIdUserId(this.policyIdSelected);
          break;
        }
      }
    }
  }

  invokeServiceForGetCategoriesByPolicyIdUserId(policyId) {
    this.apiService.getCategoriesPolicyUserId(this.policyIdSelected, this.userId).then((data: any) => {
      this.catCategories = [];
      if (data.length > 0) {
        this.catCategories = data;
        this.categorySelected = this.catCategories[0].categoryId;
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  invokeServiceForGetCategoriesByPolicy(policyId, isUpdate?) {

    this.apiService.getCategoriesPolicy(policyId).then((data: any) => {
      this.catCategories = [];
      if (data.length > 0) {

        if (isUpdate !== undefined && isUpdate === true) {
          this.catCategories = data;
          this.categorySelected = this.catCategories[0].categoryId;

        } else {
          // busca solo los del cliente seleccionados
          for (const item in data) {
            if (data[item].clientId === this.clientId) {
              this.catCategories.push(data[item]);
              this.categorySelected = this.catCategories[0].categoryId;
            }
          }
        }

      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getCategorySeleccted(event) {
    this.categoryId= +event.target.value;
    for (let dato in this.policiesList) {
      if (this.categoryId === this.catCategories[dato]['categoryId']){
        this.policieSelected['nameCategory'] = this.catCategories[dato]['name'];
      }
    }

  }
  addPoliciesSelected() {
    // obtener el numero de la poliza
    let policeExist = false;
    this.policieSelected = {};
    for ( const dato in this.numbersPoliciesList) {
      if (this.policyIdSelected === this.numbersPoliciesList[dato]['id']) {

        if (this.policiesSelectedsList !== undefined && this.policiesSelectedsList.length > 0) {
          this.policiesSelectedsList.some(X => {
             if (Number(X.policyId) === Number(this.policyIdSelected)) {
               policeExist = true;
               this.toastr.warning('La póliza ya se encuentra asignada', 'Notificación');
               return;
             }
          });
        }

        this.policieSelected.policyId = this.policyIdSelected;
        this.policieSelected.numberPolicy = this.numbersPoliciesList[dato].title;
      }
    }

    if (!policeExist) {
      // obtener el nombre del cliente
      let nombre;
      for (const dato in this.catClients) {
        if (this.clientId === this.catClients[dato].clientId) {
          nombre = this.catClients[dato].name;
        }
      }

      for (const dato in this.catCategories) {
        if (Number(this.categorySelected) === this.catCategories[dato].categoryId) {
          this.policieSelected['nameCategory'] = this.catCategories[dato].name;
          this.policieSelected['categoryId'] = this.catCategories[dato].categoryId;
        }
      }


      this.policieSelected['nameClient'] = nombre;
      this.policiesSelectedsList.push(this.policieSelected);
      // this.invokeServiceForGetCategoriesByPolicy(this.policyIdSelected);
    }

  }

  addPoliciesSelectedForUpdate() {
    // obtener el numero de la poliza
    let policeExist = false;
    const certificateFileUser = { };
    for ( const dato in this.numbersPoliciesList) {
      if (this.policyIdSelected === this.numbersPoliciesList[dato].id) {

        if (this.certificateFileUsers !== undefined && this.certificateFileUsers.length > 0) {
          this.certificateFileUsers.some(X => {
            if (Number(X.policyId) === Number(this.policyIdSelected)) {
              policeExist = true;
              this.toastr.warning('La póliza ya se encuentra asignada', 'Notificación');
              return;
            }
          });
        }

        certificateFileUser['policyId'] = this.policyIdSelected;
        certificateFileUser['numerPolicy'] = this.numbersPoliciesList[dato].title;
      }
    }

    if (!policeExist) {
      // obtener el nombre del cliente
      let nombre;

      for (const dato in this.catCategories) {
        if (Number(this.categorySelected) === this.catCategories[dato].categoryId) {
          certificateFileUser['categoryId'] = this.catCategories[dato].categoryId;
        }
      }

      certificateFileUser['base64File'] = this.uploadedFilesToLoad[0].base64;
      certificateFileUser['nameFile'] = this.uploadedFilesToLoad[0].name;
      certificateFileUser['attachmentId'] = 0;
      certificateFileUser['attachmentPath'] = '';
      certificateFileUser['certificateId'] = 0;
      this.certificateFileUsers.push(certificateFileUser);
      this.policyIdSelected = null;
      this.categorySelected = null;
      this.uploadedFilesToLoad = [];
    }

  }

  removePoliciesSelected(element) {
    // quitar un elemento
     let indexs = this.policiesSelectedsList.indexOf(element);
    // agregar a la lista de polizas de nuevo
    // this.numbersPoliciesList.push({id:element.policyId,title:element.numberPolicy});
     this.policiesSelectedsList.splice(indexs, 1);
  }
  removeDependentSelected(element){
    // quitar un elemento
    let indexs = this.dependentsList.indexOf(element);
    // agregar a la lista de polizas de nuevo
    this.dependentsList.splice(indexs,1);
  }
  llenarCombobox(){
    //
    this.idsClients = {};
    this.idsClients.clients = [];
    this.clientId = this.catClients[0]['clientId'];
    this.idsClients.clients.push(this.clientId);

    this.invokeServiceForGetNumberPoliciesByIdsCLient(this.idsClients);

  }
  save() {
    let  salary = null;
    const  name = (document.getElementById('nameInsured') as HTMLInputElement).value.toUpperCase();
    const  firstName = (document.getElementById('firstName') as HTMLInputElement).value.toUpperCase();
    const  lastName = (document.getElementById('lastName') as HTMLInputElement).value.toUpperCase();
    const  email = (document.getElementById('email') as HTMLInputElement).value;
    const  employeeNumber = (document.getElementById('employeeNumber') as HTMLInputElement).value;
    const  gender = this.genderSelected;
    const  endoso = (document.getElementById('endoso') as HTMLInputElement).value;
    if (this.showSalary) {
      salary = (document.getElementById('salario') as HTMLInputElement).value;
    }

    if (
      name === '' ||
      firstName === '' ||
      this.birthDate === null ||
      this.createAt === null ||
      email === '' ||
      this.password === 'error' ||
      employeeNumber === '' ||
      this.policiesSelectedsList.length === 0 ||
      this.isEmailValidate === false ||
      gender === null ||
      endoso === ''
    ) {
      this.toastr.warning('Por favor llene todos los campos requeridos');
      return;
    } else if (this.showSalary && (salary === '' || salary === null || salary === undefined)) {
       this.toastr.warning('Por favor llene todos los campos requeridos');
       return;
    } else if (!this.onCalculateAge(true)) {
      this.toastr.warning('El asegurado debe ser mayor de edad');
      return;
    } else {
      let date = new Date(this.createAt);
      let year = date.getFullYear();
      let day  = date.getDate()>9 ? date.getDate() : "0"+date.getDate();
      let month  = (date.getMonth() + 1)> 9 ? (date.getMonth() + 1) : "0"+(date.getMonth() + 1);
      let createAtFinal=year+'-'+month+'-'+day;

      let dateBirtDate = new Date(this.birthDate);
      let yearBirtdate = dateBirtDate.getFullYear();
      let dayBirtdate   = dateBirtDate.getDate()>9 ? dateBirtDate.getDate() : "0"+dateBirtDate.getDate();
      let monthBirtdate   = (dateBirtDate.getMonth() + 1)> 9 ? (dateBirtDate.getMonth() + 1) : "0"+(dateBirtDate.getMonth() + 1);
      let birthdateFinal=yearBirtdate+'-'+monthBirtdate+'-'+dayBirtdate ;
      let dataSend={};
      let insured = {name: name, firstName: firstName, lastName: lastName,
        email: email, groupId: this.groupId, clientId: this.clientId, birthdate: birthdateFinal
        ,createAt: createAtFinal, employeeNumber: employeeNumber
        ,salary: salary, gender: gender
        ,password: this.password
        ,group: this.nameGroup
        ,endorsment: endoso
        ,requestId: this.requestId};

      dataSend['policiesSelectedsList'] = [];
      dataSend['policiesSelectedsList'] = this.policiesSelectedsList;
      dataSend['insured']=[];
      dataSend['insured']=insured;
      dataSend['baseAntique'] = '';
      dataSend['baseAntique'] =  this.uploadedFilesAntique.length === 0 ? '' : this.uploadedFilesAntique[0].base64;
      dataSend['nameFileAntique'] = '';
      dataSend['nameFileAntique'] = this.uploadedFilesAntique.length === 0 ? '' : this.uploadedFilesAntique[0].name;
      this.spinner.show('sp');
      this.apiService.postInsured(dataSend)
        .then(
          (response: any) => {
            if (response.validateInsured !== undefined && response.validateInsured > 0) {
              this.toastr.warning('El correo electrónico ya se encuentra registrado', 'Notificación');
              this.spinner.hide('sp');
            } else if (response.clientId !== undefined && response.clientId !== '' ) {
              this.toastr.success('Operación realizada con éxito', 'Notificación');
              this.spinner.hide('sp');

              this.cleanFieldsInsuredForm();
              this.dataFiltersInsureds = {
                clientId: this.clientIdFilter,
                groupId: this.groupIdFilters,
                size: this.sizeInsured,
                page: this.page};
              this.invokeServiceForGetInsuredListPaginated(this.dataFiltersInsureds);
              this.invokeServiceForGetInsuredList();
            }

          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
            this.spinner.hide('sp');
          }
        );

    }
    //potInsured
  }


  onChangeInputFile(evt: any) {
    this.error = {active: false, msg: ''};
    this.fileUpload = evt.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }

    const formData = new FormData();
    formData.append('file', this.fileUpload);
  }

  closeModal() {
    this.modalRefCertificate.hide();
  }

  closeModalRef() {
    this.modalRefPassword.hide();
  }

  closeModalEditInsured() {
    this.searchItemSelected = null;
    this.nameUpdate = undefined;
    this.lastNameUpdate = undefined;
    this.firstNameUpdate = undefined ;
    this.birthDateDependentUpdate = null ;
    this.emailUpdate = undefined;
    this.certificateFileUsers = [];
    this.modalRef.hide();
  }



  onKey(event) {

    this.emaiValidate = event.target.value + '';
    // let regOficial = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let regOficial = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regOficial.test(this.emaiValidate)) {
      document.getElementById('emailOK').innerText = 'Ingrese un correo valido';
      this.isEmailValidate = false;
    } else if (!reg.test(this.emaiValidate)) {
      document.getElementById('emailOK').innerText = 'Ingrese un correo valido';
      this.isEmailValidate = false;
    } else {

      this.isEmailValidate = true;
      document.getElementById('emailOK').innerText='';
    }

  }

  cleanFieldsInsuredForm() {
    this.policiesSelectedsList = [];
    this.birthDate = null;
    this.createAt = null;
    this.showSalary = false;
    (document.getElementById('nameInsured') as HTMLInputElement).value="";
    (document.getElementById('firstName') as HTMLInputElement).value="";
    (document.getElementById('lastName') as HTMLInputElement).value="";
    (document.getElementById('email') as HTMLInputElement).value="";
    (document.getElementById('employeeNumber') as HTMLInputElement).value="";
    this.genderSelected = null;
    this.uploadedFilesToLoad = [];
  }
  cleanFieldsDependentForm(){
    this.searchItemSelected=null;
    this.birthDateDependent = null;
    this.createAtDependent = null;
    this.dependentsList = [];
    this.policieSelected = [];
    this.numbersPoliciesList = [];
    this.numbersPoliciesListInsured = [];
    this.catCategories = [];
    (document.getElementById('nameDependent') as HTMLInputElement).value="";
    (document.getElementById('firstNameDependent') as HTMLInputElement).value="";
    (document.getElementById('lastNameDependent') as HTMLInputElement).value="";

  }
  cleanFieldsDependents() {
    this.searchItemSelected = null;
    this.birthDateDependent = null;
    this.createAtDependent = null;
    this.uploadedFilesToLoad = [];
    this.policySelected = null;
    this.catSelected = null;
    //this.dependentsList = [];
    (document.getElementById('nameDependent') as HTMLInputElement).value = '';
    (document.getElementById('firstNameDependent') as HTMLInputElement).value = '';
    (document.getElementById('lastNameDependent') as HTMLInputElement).value = '';
    // (document.getElementById('endosoDependent') as HTMLInputElement).value = '';

  }
  cleanListDependentsAdds() {
    this.dependentsList = [];
    this.policiesSelectedsList = [];
    this.dependentsList = [];
  }

  addPoliciesToUser(policiesList, dependetList): any[] {
    const dependentToSend = [];
    dependetList.forEach((dependent) => {

      policiesList.forEach((policies) => {
        const depdentCopy = {...dependent};
        this.policieSelected = {};
        this.policieSelected.policyId = policies.policyId;
        this.policieSelected.numberPolicy = policies.numberPolicy;
        this.policieSelected.nameCategory = policies.categoryName;
        this.policieSelected.categoryId = policies.categoryId;
        this.policieSelected.base = policies.base;
        this.policieSelected.nameFile = policies.nameFile;

        const copyPolicy = {...this.policieSelected};

        depdentCopy.policy =  copyPolicy;
        dependentToSend.push(depdentCopy);
      });
    });

    return dependentToSend;
  }

  postDependents() {
    const endosoDependent = (document.getElementById('endosoDependent') as HTMLInputElement).value;
    if (endosoDependent === undefined || endosoDependent === '') {
      this.toastr.warning('Es necesario agregar el endoso.', 'Notificación');
      return;
    }

    let skipDocument = false;
    this.policiesSelectedsList.forEach((policy) => {
      if (policy.base === undefined || policy.base === null) {
        skipDocument = true;
        return;
      }
    });

    if (skipDocument) {
      this.toastr.warning('Es necesario agregar los certificados.', 'Notificación');
      return;
    }


    let dataSen={};
    dataSen['listDependents'] = [];
    dataSen['listDependents'] =  this.addPoliciesToUser(this.policiesSelectedsList, this.dependentsList);
    dataSen['requestId'] = this.requestId === undefined ? 0 : this.requestId;
    dataSen['endorsment'] = endosoDependent;

    this.spinner.show('sp');
    this.apiService.postDependents(dataSen)
      .then(
        (response: any) => {
            if( response.userId !== undefined || response.userId !== '') {
              this.toastr.success('Operación realizada con éxito', 'Notificación');
              this.cleanFieldsDependentForm();
              this.invokeServiceForGetInsuredList();
              //this.invokeServiceFoGetDataDependent();
              this.cleanListDependentsAdds();

              this.spinner.hide('sp');
            }
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.spinner.hide('sp');
        }
      );


  }
  lowInsured() {
    // baja de asegurado
    if (this.selectedTypeDelete === 0) {
      console.log('this.userId');
      console.log(this.insuredId);
      console.log('this.dependentsLowSelectedItems.length');
      console.log(this.policiesLowSelectedItems.length);
      console.log('this.lowDateInsured');
      console.log(this.lowDateInsured);
      // validar campos llenos
      if (this.insuredId === null || this.insuredId === undefined
        || this.policiesLowSelectedItems.length === 0
        || this.lowDateInsured === null || this.lowDateInsured === undefined
        || this.endosoLow === null
        || (this.showWorkSeniority ? this.uploadedFilesToLoad.length === 0 : false)
        ) {
        this.toastr.warning('Por favor llene todos los campos requeridos');
      } else {

        // prepara el json
        let dataLowIndependet = {
          requestId: this.requestId,
          insuredId: this.insuredId,
          endorsment: this.endosoLow,
          lowDateInsured: this.lowDateInsured,
          dependentIds: [],
          policiesSelectedsListLow: this.policiesLowSelectedItems,
          isDependent: 'false',
          base: this.showWorkSeniority ? this.uploadedFilesToLoad[0].base64 : '',
          nameFile: this.showWorkSeniority ? this.uploadedFilesToLoad[0].name : ''
        };
        this.invokeServiceForLowDependents(dataLowIndependet);

      }
    } else {
      if (this.insuredId === null
        || this.endosoLow === null
        || this.dependentsLowSelectedItems.length === 0
        || this.lowDateDependent === null
      ){
        this.toastr.warning('Por favor llene todos los campos requeridos');
      } else {
        let dataLowIndependet = {
          requestId: this.requestId === undefined ? 0 : this.requestId,
          insuredId: this.insuredId,
          endorsment: this.endosoLow,
          dependentIds: this.dependentsLowSelectedItems,
          lowDateDependent: this.lowDateDependent,
          policiesSelectedsListLow: this.policiesLowSelectedItems,
          isDependent: 'true'};
        this.invokeServiceForLowDependents(dataLowIndependet);
      }

    }
  }
  viewForm() {
    //cierra modal
    this.modalRef.hide();
    this.selectedType == 0 ? this.viewFormInsured() : this.viewFormDependent();
    this.isVisibleTable = false;
    this.isDependientSelected = false;
    this.viewFormDeleteInsured = false;
  }

  cloreViewFormInsured() {

    this.isVisibleTable = true;
    this.isVisibleFormDependent = false;
    this.isVisibleFormInsured = false;
    this.isVisibleTable = false;
    this.isDependientSelected = false;
    this.viewFormDeleteInsured = false;
    this.cleanFieldsInsuredForm();
    this.dataFiltersInsureds = {
      clientId: this.clientIdFilter,
      groupId: this.groupIdFilters,
      size: this.sizeInsured,
      page: this.page};
    this.invokeServiceForGetInsuredListPaginated(this.dataFiltersInsureds);
    this.invokeServiceForGetInsuredList();
  }

  viewFormInsured() {
    this.policiesSelectedsList = [];
    this.isVisibleTable = false;
    this.isVisibleFormDependent = false;
    this.isVisibleFormInsured = true;
    this.invokeServiceForGetRequestList(this.groupId, this.clientId, 1);
  }
  viewFormDependent() {

    this.userId = null;
    this.isVisibleTable = false;
    this.isVisibleFormDependent = true;
    this.isVisibleFormInsured = false;
    this.invokeServiceForGetRequestList(this.groupId, this.clientId, 2);
  }
  invokeServiceForGetPasswordUser(){
    this.apiService.getPasswordUser().then((data: any) => {


      if(data.password!==null || data.password!==""){
        this.password=data.password;

      }else{
        this.password="error";
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  invokeServiceForLowDependents(sendData) {
    this.spinner.show('sp');
    this.apiService.lowDependents(sendData).then((data: any) => {
        if (data !== null && data.userId !== null && data.userId !== '') {
          this.toastr.success('Operación realizada con éxito', 'Notificación');
          this.policiesLowSelectedItems = [];
          this.dependentsListByInsured = [];
          this.uploadedFilesToLoad = [];
          this.searchItemSelected  = null;
          this.lowDateInsured = null;
          this.lowDateDependent = null;
          this.dependentsLowSelectedItems = null;
          this.showWorkSeniority = false;
          this.invokeServiceForGetInsuredList();
          this.dataFiltersInsureds = {
            clientId: this.clientIdFilter,
            groupId: this.groupIdFilters,
            size: this.sizeInsured,
            page: this.page};
          this.invokeServiceForGetInsuredListPaginated(this.dataFiltersInsureds);
          this.cleanDataLow();
          this.spinner.hide('sp');
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición.', 'Notificación');
          this.spinner.hide('sp');
        }

    }, error => {
        this.toastr.error('Ocurrió un problema al procesar su petición.', 'Notificación');
        this.spinner.hide('sp');
    });
  }

  invokeServiceForGetDependentsByInsured(insuredId){
    console.log("invokeServiceForGetDependentsByInsured");
    console.log(insuredId);
    this.dependentsListByInsured=[];
    this.apiService.getDependentsByInsured(insuredId).then((data: any) => {
      if(data!==undefined && data!==null){
        if(data.length>0){
          this.dependentsListByInsured=data;

        }else{
          this.dependentsListByInsured=[];
        }
      }
    }, error => {
      this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  // Show loading indicator
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  // Selected value event
  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.userId=e.item.userId;
  }
  typeaheadOnSelectLowDependente(e: TypeaheadMatch): void {
    this.insuredId=e.item.userId;

    this.invokeServiceForGetPoliciesInsuredId(this.insuredId);
    this.invokeServiceForGetDependentsByInsured(this.insuredId);

  }
  typeaheadOnSelectUpdateDependente(e: TypeaheadMatch): void {
  	//this.insuredId=e.item.userId;
  	// console.log('e.item');
  	// console.log('e.item.name');
  	// console.log(e.item);
  	//this.name=e.item['name'];
  	let data={"userId":e.item['userId']};
  	// console.log(data);
  	this.invokeServiceForFetDataInsuredById(data);

  }

  viewFormDelete() {
    this.validateAccess.validateAccessCore(79).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        if (this.groupId != null) {
          this.userId = null;
          this.viewFormDeleteInsured = true;
          this.isVisibleTable = false;
          this.isVisibleFormInsured = false;
          this.isVisibleFormDependent = false;

        } else {
          this.toastr.warning('Seleccione un grupo o cliente para continuar');
        }
        this.policiesLowSelectedItems = [];
        this.lowDateInsured = null;
        this.endosoLow = null;
        this.invokeServiceForGetRequestList(this.groupId, this.clientId, 3);
      }
    });
  }
  getSelectedTypeDelete(event){
    let value=+event.target.value;
     switch (value){
       case 0:{
         this.isInsuredSelected=true;
       }break;
       case 1:{
         this.isInsuredSelected=false;

       }break;
     }

  }

  editInsured(){
      // guaradrias
  }
  resetPassword(){
  }
  getEventInfoRequest() {
        this.apiService.getInfoRequest()
              .then(
                (response: any) => {
                 // console.log('getInfoRequest');
                 // console.log(response);
                 this.lclRequest = response;

                }, error => {
                  // console.error(error.status);
                  // console.error(error.statusText);
                  // console.error(error.message);
                  //this.toastr.error('Ocurrió un problema al procesar su petición lalo', 'Notificación');
                }
              );

  }
  procesarSolicitud(index){

      let id= this.lclRequest[index]['requestId'];
      let data= {"requestId":id};
      this.spinner.show('sp');
      this.apiService.postRequestData(data)
          .then(
              (response: any) => {
                  if(response.sagaSucces===true) {
                    this.spinner.hide('sp');
                    this.toastr.success('Operación realizada con éxito', 'Notificación');
                    this.getEventInfoRequest();

                  }
                }, error => {
                  // console.error(error.status);
                  // console.error(error.statusText);
                  // console.error(error.message);
                  this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
                }
          );

  }

  getRequestDetail(requestId){
    let data ={"requestId":requestId};
    // console.log(data);
    this.apiService.getRequest(data)
       .then(
          (response: any) => {
                // console.log('ViewRequestDetail');
                // console.log(response);
              this.lclRequestDetail = response.requestProcessUser;

          }, error => {
              // console.error(error.status);
              // console.error(error.statusText);
              // console.error(error.message);
              //this.toastr.error('Ocurrió un problema al procesar su petición lalo', 'Notificación');
          }
        );
  }

  invokeServiceForGetProvisions(data){
    console.log('invokeServiceForGetProvisions')
    console.log(data)
      this.apiService.getProvisions(data)
            .then(
              (response: any) => {

                 this.provisions = response;
                 console.log('response');
                 //console.log(response.totalData);
                 this.pgProvitionsTotalItems=response[0].totalData;
              }, error => {
                // console.error(error);
              }
            );
  }
  invokeServiceForGetProvitionsInitialPopulation() {
    this.validateAccess.validateAccessCore(82).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        const provisionType = (document.getElementById('provisiones') as HTMLInputElement).value;

        if ( Number(provisionType) !== 0 ) {
          if (this.clientIdReports !== null && this.clientIdReports !== undefined){
            const data = {
              subgroupId: this.clientIdReports,
              clientId: this.groupId,
              id: provisionType};

            console.log('data');
            console.log(data);
            this.apiService.getProvitionsInitialPopulation(data)
              .then(
                (response: any) => {
                  console.log(response);
                  if (response == null) {
                    this.toastr.info('No se encontraron registros para descargar');
                    return;
                  } else {
                    const dataType = response.type;
                    const binaryData = [];
                    binaryData.push(response);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                    downloadLink.setAttribute('download', 'reporte_provision.xls');
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();
                  }
                }, error => {

                  this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
                }
              );
          } else {
            this.toastr.warning('Seleccione un cliente para continuar', 'Notificación');
          }
        }
      }
    });
  }
  saveSiniestro() {
    alert('clic ');
  }

  invokeServiceForGeneratePdfAnalisys(){
       // obtener el grupo al que pertenece el usuario logeado
      //let data={"clientId":6};
      let data={"clientId":this.clientId,"name":""};
      this.apiService.generatePdfAnalisys(data)
        .then(
           (response: any) => {
               // console.log('invokeServiceForGeneratePdfAnalisys');
               // console.log("response[data]");
               // console.log(response);
              if (response == null) {
                      this.toastr.info('No existen archivos para descargar');
                      return;
              } else {

                 const dataType = response.type;
                 const binaryData = [];
                 binaryData.push(response);
                 const downloadLink = document.createElement('a');
                 downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                 downloadLink.setAttribute('download', 'reporte_initial_population.pdf');
                 document.body.appendChild(downloadLink);
                 downloadLink.click();
                 downloadLink.remove();
              }

           }, error => {
               // console.error(error.status);
               // console.error(error.statusText);
               // console.error(error.message);
               this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
           }
         );

    }

  invokeServiceForFetDataInsuredById(data) {

    this.apiService.getDataInsuredById(data)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen datos');
            return;
          } else {
            this.nameUpdate = response.name;
            this.lastNameUpdate = response.lastName;
            this.firstNameUpdate = response.firstName;
            this.birthDateDependentUpdate = new Date(response.birthdate + 'T00:00:00');
            this.emailUpdate = response.email;
            this.genderUpdate = response.gender;
            this.contactUpdate = response.contact;
            this.emailSecondaryUpdate = response.emailSecondary;
            this.certificateFileUsers = response.certificateFileUsers;
            this.employerNumberUpdate = response.employeeNumber;
            this.userId = response.userId;

            this.idsClients.clients = [];
            this.idsClients.clients = response.idsClient;
            this.invokeServiceForGetNumberPoliciesByIdsCLient(this.idsClients);
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
        }
      );

  }
    invokeServiceFoGetDataDependent(data){
      this.dependentsListData=[];
        this.apiService.getDataDependentsByInsured(data)
          .then(
             (response: any) => {
                 // console.log('invokeServiceForGeneratePdfAnalisys');
                 // console.log("response[data]");
                 // console.log(response);
                if (response == null) {
                        this.toastr.info('No se encontraron dependientes ');
                        this.cleanDataUpdate();
                        return;
                } else {
                  this.dependentsListData = response;
                  console.log('this.dependentsListData');
                  console.log(this.dependentsListData);
                   /*this.nameUpdate=response.name;
                   this.lastNameUpdate=response.lastName;
                   this.firstNameUpdate=response.firstName;
                   this.birthDateDependentUpdate=response.birthdate;
                   this.userId=response.kinshipId;*/
                }

             }, error => {
                 // console.error(error.status);
                 // console.error(error.statusText);
                 // console.error(error.message);
                 this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
             }
           );

      }


   updateInsured() {
       if (this.selectedTypeUpdate === 1) {

         const dateExample = this.datePipe.transform(this.birthDateDependentUpdate, 'yyyy-MM-dd');
         if (this.typekinshipId === 2 && !this.onCalculateAgeDependent(false)) {
           return;
         } else if (
           this.nameUpdate !== undefined &&
           this.userUpdate !== null &&
           this.lastNameUpdate !== undefined &&
           this.emailUpdate !== undefined &&
           this.firstNameUpdate !== undefined &&
           this.genderUpdate !== undefined &&
           this.birthDateDependentUpdate !== null) {
           this.dataUpdataInsured = {
             kinshipId: this.userUpdate,
             name: this.nameUpdate,
             firstName: this.firstNameUpdate,
             lastName: this.lastNameUpdate,
             gender: this.genderUpdate,
             typeKinshipId: this.typekinshipId,
             birthdate: dateExample
           };

           this.apiService.updateDataDependent(this.dataUpdataInsured)
             .then(
               (response: any) => {
                 console.log(response);
                 if (response == null) {
                   this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
                   return;
                 } else {
                   this.toastr.success('Se han actualizado los datos correctamente');
                   this.invokeServiceForGetInsuredList();
                   this.searchItemSelected = null;
                   this.nameUpdate = undefined;
                   this.lastNameUpdate = undefined;
                   this.firstNameUpdate = undefined;
                   this.emailUpdate = undefined;
                   this.certificateFileUsers = [];
                   this.birthDateDependentUpdate = null;
                   this.modalRef.hide();
                   this.clearClientCombobox();
                   this.dependentsListData = [];

                 }

               }, error => {
                 if (error.status === 500) {
                   if (error.error.message !== undefined) {
                     this.toastr.warning(error.error.message, 'Notificación');
                   } else {
                     this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                   }
                 } else {
                   this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                 }
               }
             );
         } else {
           this.toastr.warning('Por favor llene todos los campos requeridos');
         }
       } else {

         const dateExample = this.datePipe.transform(this.birthDateDependentUpdate, 'yyyy-MM-dd');
         if (!this.onCalculateAge(false)) {
           this.toastr.warning('El asegurado debe ser mayor de edad');
         } else if (
           this.nameUpdate !== undefined &&
           this.userId !== null &&
           this.lastNameUpdate !== undefined &&
           this.emailUpdate !== undefined &&
           this.firstNameUpdate !== undefined &&
           this.genderUpdate !== undefined &&
           dateExample !== null) {

           this.dataUpdataInsured = {
             userId: this.userId,
             name: this.nameUpdate,
             firstName: this.firstNameUpdate,
             lastName: this.lastNameUpdate,
             email: this.emailUpdate,
             birthdate: dateExample,
             group: this.nameGroup,
             contact: this.contactUpdate,
             emailSecondary: this.emailSecondaryUpdate,
             employeeNumber: this.employerNumberUpdate,
             certificateFileUsers: this.certificateFileUsers,
             gender: this.genderUpdate
           };
           console.log('this.selectedTypeUpdate');
           console.log(this.selectedTypeUpdate);

           this.dataUpdataInsured.insuredTypeId = 10;
           this.apiService.postUpdateInsuredById(this.dataUpdataInsured)
             .then(
               (response: any) => {

                 if (response == null) {
                   this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
                   return;
                 } else {
                   this.toastr.success('Se han actualizado los datos correctamente');
                   this.invokeServiceForGetInsuredList();
                   this.searchItemSelected = null;
                   this.nameUpdate = undefined;
                   this.lastNameUpdate = undefined;
                   this.firstNameUpdate = undefined;
                   this.birthDateDependentUpdate = null;
                   this.emailUpdate = undefined;
                   this.contactUpdate = undefined;
                   this.emailSecondaryUpdate = undefined;
                   this.employerNumberUpdate = undefined;
                   this.certificateFileUsers = [];
                   this.modalRef.hide();

                 }

               }, error => {
                 if (error.status === 500) {
                   if (error.error.message !== undefined) {
                     this.toastr.warning(error.error.message, 'Notificación');
                   } else {
                     this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                   }
                 } else {
                   this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                 }
               }
             );
         } else {
           this.toastr.warning('Por favor llene todos los campos requeridos');
         }
       }
   }

  openModalRestorePassword(template: TemplateRef<any>, insured?) {


    if (insured !== undefined && insured !== null ) {
      this.dataUpdataInsured = {
        userId: insured.userId,
        name: insured.name,
        email: insured.email
      };
    } else {
      if (this.userId !== undefined && this.userId !== null) {
        this.dataUpdataInsured = {
          userId: this.userId,
          name: this.nameUpdate,
          email: this.emailUpdate
        };
      } else {
        this.toastr.warning('Por favor, seleccione un usuario');
        return;
      }

    }

    this.modalRefPassword = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
  }

  confirmRestore() {
    this.apiService.restorePassword (this.dataUpdataInsured)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
            this.dataUpdataInsured = {};
            this.closeModalRef();
            return;
          } else {
            this.toastr.success('Se ha reestablecido la contraseña correctamente');
            this.dataUpdataInsured = {};
            this.closeModalRef();
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
        });
  }
   onCalculateAge(isNew: boolean): boolean {
     let timeDiff;
     if (isNew) {
       timeDiff = Math.abs(Date.now() - new Date(this.birthDate).getTime());
    } else {
      timeDiff = Math.abs(Date.now() - new Date(this.birthDateDependentUpdate).getTime());
    }

     const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

     if (Number(age) > 18 ) {
       return true;
     } else {
       return false;
     }
     console.log(age);
   }

  onCalculateAgeDependent(isNew: boolean): boolean {
    let timeDiff;
    if (isNew) {
      timeDiff = Math.abs(Date.now() - new Date(this.birthDateDependent).getTime());
    } else {
      timeDiff = Math.abs(Date.now() - new Date(this.birthDateDependentUpdate).getTime());
    }

    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    let isAgeAllowed = true;

    for (const dato in this.numbersPoliciesList) {
      if (this.policyIdSelected === this.numbersPoliciesList[dato].id) {
        const maxAgeLimit = this.numbersPoliciesList[dato].maxAgeLimit;
        if (maxAgeLimit !== 0 && Number(age) > maxAgeLimit ) {
          this.toastr.warning('La póliza: ' + this.numbersPoliciesList[dato].title
            + ' no permite dependientes con edad mayor a: ' + this.numbersPoliciesList[dato].maxAgeLimit);
          isAgeAllowed = false;
        }
      }
    }

    return isAgeAllowed;
    console.log(age);
  }

  getSelectedTypeInsured(event) {
    console.log('getSelectedTypeInsured');
    console.log(this.selectedTypeUpdate);
    this.cleanDataUpdate();
    // this.clientId = null;
    this.userId=null;

  }
  getSelectedTypeInsuredLow(event) {
    if (event === 0) {
      this.invokeServiceForGetRequestList(this.groupId, this.clientId, 3);
    } else {
      this.dependentsLowSelectedItems = [];
      this.invokeServiceForGetRequestList(this.groupId, this.clientId, 4);
    }
    this.userId = null;
    this.cleanDataLow();
  }
  registerInsured(event) {
    this.clientIdTemp = null;
    this.cleanListDependentsAdds();
  }

  /***  PAGINACION **/
  pageProvitionsChanged(event: PageChangedEvent): void {
    this.pgProvitionCurrentPage = event.page;
    this.getProvitionsChangePage(this.pgProvitionCurrentPage, this.pgProvitionsItemsPerPage);

  }
  changeColorPagination() {
  }
  getProvitionsChangePage(page, size) {

    this.dataFilters={"subgroupId":-1,"clientId":6,"size":size,"page":page};
    this.invokeServiceForGetProvisions(this.dataFilters);
  }

  /***  PAGINACION ASEGURADOS **/
  pageInsuredsChanged(event: PageChangedEvent): void {
    this.pgInsuredsCurrentPage = event.page;
    this.getInsuerdsChangePage(this.pgInsuredsCurrentPage, this.pgInsuredsItemsPerPage);

  }
  getInsuerdsChangePage(page, size) {

    console.log('getInsuerdsChangePage');

    //this.dataFiltersInsureds={"groupId":this.groupId,"clientId":this.clientId,"size":size,"page":page};
    this.dataFiltersInsureds={"clientId":this.clientIdFilter,"groupId": this.groupIdFilters,"size":size,"page":page};
    console.log(this.dataFiltersInsureds);
    this.invokeServiceForGetInsuredListPaginated(this.dataFiltersInsureds);
  }
  invokeServiceGetGenders() {
    console.log('invokeServiceGetGenders');
    const data = {name: 'GENDERS'};
    this.apiService.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.catGenders = data;
        console.log('data');
        console.log(data);
      }
    }, error => {
      //console.error(error.status);
      //console.error(error.statusText);
      //console.error(error.message);
    });
  }
  getSelectedGender(event) {
    this.genderSelected = event;
    console.log(event);
  }

  onLoadDocuments(event, isCertificate, policySelected?) {
    if (isCertificate) {
      // Limpiamos los archivos a cargar
      this.uploadedFilesToLoad = event;
      policySelected.base = this.uploadedFilesToLoad[0].base64;
      policySelected.nameFile = this.uploadedFilesToLoad[0].name;
      this.uploadedFilesToLoad = new Array<Documents>();
    } else {
      // Limpiamos los archivos a cargar
      this.uploadedFilesAntique = event;
    }
  }

  /*+-------------------------------------------------------------------------------------------------------+
 |---------------------------------------|  S O L I C I T U D E S |---------------------------------------|
 +-------------------------------------------------------------------------------------------------------+*/


  /*+-------------------------------------------------------------------------------------------------------+
  |------------------------------------------|  S I N I E S T R O S |------------------------------------------|
  +-------------------------------------------------------------------------------------------------------+*/

  invokeServiceForIndicatorsIncident() {
    this.apiService.getIncidentIndicators()
      .then(
        (response) => {
          if (response != null) {
            this.incidentIndicators = response;
          } else {
            this.incidentIndicators = {
              incidentsPendingDocumentation: 0,
              incidentsPendingInsurance: 0,
              incidentsActive: 0,
              clientId: 0,
              subGroup: 'SIN DATOS',
              groupId: 0,
              incidentsPendingPayment: 0,
              incidentsDeclined: 0,
              group: 'SIN DATOS'
            };
          }

        }, error => {
          // console.error(error);
        }
      );
  }
  changeTypeaheadLoadingIncident(e: boolean): void {
    this.typeaheadLoadingIncident = e;
  }
  typeaheadOnSelectIncident(e: TypeaheadMatch): void {
    const itemSelected: any = e.item;
    console.log(itemSelected);
    localStorage.setItem('incidentId',  itemSelected.incidentId);
    this.router.navigate(['/admin/incident_detail']);
  }
  getStatesAsObservable(token: string): Observable<any> {
    return this.apiService.getIncidentsByFolio(token).pipe();
  }
  goIncident() {
    this.validateAccess.validateAccessCore(88).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.shared.gSearchId = 1;
        localStorage.setItem('filter', undefined);
        this.router.navigate(['/admin/incidents']);
      }
    });
  }

  invokeServiceGetFilters(filter: number) {
    this.validateAccess.validateAccessCore(88).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        console.log(filter);
        switch (filter) {
          case 1:
            localStorage.setItem('filter',   'decline');
            break;
          case 2:
            localStorage.setItem('filter',   'active');
            break;
          case 3:
            localStorage.setItem('filter',   'opinion');
            break;
          case 4:
            localStorage.setItem('filter',   'documentation');
            break;
          case 5:
            localStorage.setItem('filter',   'classification');
            break;
          case 6:
            localStorage.setItem('filter',   'group');
            const data = { groupId: this.incidentIndicators.groupId, name: this.incidentIndicators.group };
            localStorage.setItem('group',  JSON.stringify(data));
            break;
          case 7:
            localStorage.setItem('filter',   'client');
            const dataGroup = { groupId: this.incidentIndicators.groupId, name: this.incidentIndicators.group };
            localStorage.setItem('group',  JSON.stringify(dataGroup));
            // tslint:disable-next-line:max-line-length
            const dataClient = { clientId: this.incidentIndicators.clientId, name: this.incidentIndicators.group + '-' + this.incidentIndicators.subGroup };
            localStorage.setItem('client',  JSON.stringify(dataClient));
            break;
        }
        this.router.navigate(['/admin/incidents']);
      }
    });
  }

  /* R E P O R T E S*/



  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.groupsSelectedItems = [];
    this.branchSelectedItems = [];
    this.subBranchSelectedItems = [];
    this.surexSelectedItems = [];
    this.filterRangeDate = [];
    this.insurancesSelectedItems = [];
    this.salesmanSelectedItems = [];

    this.filters = {
      idGrupo: [],
      idRamo: [],
      idSubRamo: [],
      idEjecutivo: [],
      idSalesman: [],
      idAseguradora: [],
      idStatus: [1, 4],
      dateStart: this.dateStart,
      dateEnd: this.dateEnd
    };

    const fecha = new Date();
    const actualYear = fecha.getFullYear();
    const monthYear = fecha.getMonth();
    const dateStart = this.datePipe.transform(new Date(actualYear, monthYear, 1), 'yyyy-MM-dd');
    const dateEnd = this.datePipe.transform(new Date(actualYear, monthYear + 1, 0), 'yyyy-MM-dd');
    // La gráfica de cobranza solo se filtrará por el mes actual
    this.filtersCobranza = {
      idGrupo: [],
      idRamo: [],
      idSubRamo: [],
      idEjecutivo: [],
      idSalesman: [],
      idAseguradora: [],
      idStatus: [1, 4],
      typeGraphic: this.typeGraphicCobranza,
      dateStart,
      dateEnd
    };

    localStorage.setItem('filters', JSON.stringify(this.filters));
    this.filtersIncident = {
      idGrupo: 0,
      idSubgrupo: 0,
      idRamo: 1,
      idSubRamo: 5,
      idEjecutivo: 0,
      idAseguradora: 0,
      idIncidentType: 0,
      idRequestType: 0,
      idStatus: 1,
      dateStart: '',
      dateEnd: '',
      page: 0,
      size: 0
    };
    localStorage.setItem('filtersIncident', JSON.stringify(this.filtersIncident));

    this.filtersIncidentReport = {
      idGrupo: [],
      idSubgrupo: [],
      idRamo: [],
      idSubRamo: [],
      idEjecutivo: [],
      idAseguradora: [],
      idIncidentType: [],
      idRequestType: [],
      idStatus: [1, 4],
      dateStart: '',
      dateEnd: '',
      page: 0,
      size: 10
    };

  }
  viewFiltersGraphic(val: any) {
    if (!val) {
      this.validateAccess.validateAccessCore(1).then((access: any) => {
        console.log('valor que regresa', access.accessCore);
        if (access.accessCore === 0) {
          this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
        } else {
          this.isCollapsedGraphic = val;
        }
      });
    } else {
      this.isCollapsedGraphic = val;
    }
  }
  loadFiltersTableByCobranza(value: any) {
    const fecha = new Date();
    const actualYear = fecha.getFullYear();
    const monthYear = fecha.getMonth();
    const dateStart = this.datePipe.transform(new Date(actualYear, monthYear, 1), 'yyyy-MM-dd');
    const dateEnd = this.datePipe.transform(new Date(actualYear, monthYear + 1, 0), 'yyyy-MM-dd');
    // La gráfica de cobranza solo se filtrará por el mes actual
    this.filters = {
      idGrupo: value.idGrupo,
      idRamo: value.idRamo,
      idSubRamo: value.idSubRamo,
      idEjecutivo: value.idEjecutivo,
      idSalesman: value.idSalesman,
      idAseguradora: value.idAseguradora,
      idStatus: value.idStatus,
      dateStart,
      dateEnd
    };
    this.filtersCobranza = { ...this.filters };
    this.filtersCobranza.typeGraphic = this.typeGraphicCobranza;
  }
  loadFiltersTable(value: any) {
    this.filters = {
      idGrupo: value.idGrupo,
      idRamo: value.idRamo,
      idSubRamo: value.idSubRamo,
      idEjecutivo: value.idEjecutivo,
      idSalesman: value.idSalesman,
      idAseguradora: value.idAseguradora,
      idStatus: value.idStatus,
      dateStart: value.dateStart,
      dateEnd: value.dateEnd
    };

    const fecha = new Date();
    const actualYear = fecha.getFullYear();
    const monthYear = fecha.getMonth();
    const dateStart = this.datePipe.transform(new Date(actualYear, monthYear, 1), 'yyyy-MM-dd');
    const dateEnd = this.datePipe.transform(new Date(actualYear, monthYear + 1, 0), 'yyyy-MM-dd');
    // La gráfica de cobranza solo se filtrará por el mes actual
    this.filtersCobranza = { ...this.filters};
    this.filtersCobranza.typeGraphic = this.typeGraphicCobranza;
    this.filtersCobranza.dateStart = dateStart;
    this.filtersCobranza.dateEnd = dateEnd;

    this.filtersIncidentReport = {
      idGrupo: value.idGrupo,
      idSubgrupo: [],
      idRamo: value.idRamo,
      idSubRamo: value.idSubRamo,
      idEjecutivo: value.idEjecutivo,
      idAseguradora: value.idAseguradora,
      idIncidentType: [],
      idRequestType: [],
      idStatus: value.idStatus,
      dateStart: value.dateStart,
      dateEnd: value.dateEnd,
      page: 0,
      size: 10
    };

    localStorage.removeItem('filters');
    localStorage.setItem('filters', JSON.stringify(this.filters));
    this.viewFiltersGraphic(true);
  }
  getGroups() {
    this.apiService.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  changeStatusGroups() {
    const group = [];
    try {
        this.groupsSelectedItems.forEach(item => {
          group.push(item.groupId);
        });
        this.filters.idGrupo = group;
        this.filters.dateStart === null ? this.dateStart : this.filters.dateStart;
        this.filters.dateEnd === null ? this.dateEnd : this.filters.dateEnd;
        this.loadFiltersTable(this.filters);
      } catch (e) { }
  }

  changeStatusBranch() {
    const branch = [];
    setTimeout(() => {
      try {
        this.branchSelectedItems.forEach(item => {
          branch.push(item.branchId);
        });
        this.getSubBranches(branch);
        this.filters.dateStart === null ? this.dateStart : this.filters.dateStart;
        this.filters.dateEnd === null ? this.dateEnd : this.filters.dateEnd;
        this.filters.idRamo = branch;
        this.loadFiltersTable(this.filters);
      } catch (e) { }
    }, 500);
  }

  getBranches() {
    this.apiService.getBranches().then((data: any) => {
      this.branchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  getSubBranches(id) {
    this.apiService.getSubBranchesMulti(id).then((data: any) => {
      this.subBranchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  changeStatusSubBranch() {
    const subBranch = [];
    setTimeout(() => {
      try {
        this.subBranchSelectedItems.forEach(item => {
          subBranch.push(item.subBranchId);
        });
        this.filters.dateStart === null ? this.dateStart : this.filters.dateStart;
        this.filters.dateEnd === null ? this.dateEnd : this.filters.dateEnd;
        this.filters.idSubRamo = subBranch;
        this.loadFiltersTable(this.filters);
      } catch (e) { }
    }, 500);
  }

  changeStatusSurexs() {
    setTimeout(() => {
      try {
        const executive = [];
        this.surexSelectedItems.forEach(item => {
          executive.push(item.directoryId);
        });
        this.filters.dateStart === null ? this.dateStart : this.filters.dateStart;
        this.filters.dateEnd === null ? this.dateEnd : this.filters.dateEnd;
        this.filters.idEjecutivo = executive;
        this.loadFiltersTable(this.filters);
      } catch (e) {
      }
    }, 500);
  }

  changeStatusPolicy() {
    setTimeout(() => {
      try {
        const status = [];
        this.statusPolicySelectedItems.forEach(item => {
          status.push(item.statusPolicyId);
        });
        this.filters.dateStart === null ? this.dateStart : this.filters.dateStart;
        this.filters.dateEnd === null ? this.dateEnd : this.filters.dateEnd;
        this.filters.idStatus = status;
        this.loadFiltersTable(this.filters);
      } catch (e) {
      }
    }, 500);
  }

  getInsuranceCompanies() {
    this.apiService.getInsuranceCompanies().then((data: any) => {
      this.insurancesList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  changeStatusInsurances() {
    const insurances = [];
    setTimeout(() => {
      try {
        this.insurancesSelectedItems.forEach(item => {
          insurances.push(item.insuranceId);
        });
        this.filters.dateStart === null ? this.dateStart : this.filters.dateStart;
        this.filters.dateEnd === null ? this.dateEnd : this.filters.dateEnd;
        this.filters.idAseguradora = insurances;
        this.loadFiltersTable(this.filters);
      } catch (e) { }
    }, 500);
  }

  getExecutives(id) {
    this.apiService.getExecutives(id).then((data: any) => {
          this.executiveSurexList = data;

    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  getStatusPolicy() {
    this.statusPolicyList = [
      {statusPolicyId: 1, name: 'ACTIVA'},
      {statusPolicyId: 2, name: 'ORDEN DE PAGO'},
      {statusPolicyId: 3, name: 'NO RENOVABLE'},
      {statusPolicyId: 7, name: 'NO RENOVADA'},
      {statusPolicyId: 6, name: 'RENOVACIÓN'},
      {statusPolicyId: 0, name: 'CANCELADO'}
    ];

    this.statusPolicySelectedItems.push({statusPolicyId: 1, name: 'ACTIVA'});
  }

  getSalesman() {
    this.apiService.getSalesman().then((data: any) => {
      this.salesmanList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  changeStatusSalesman() {
    const salesman = [];
    setTimeout(() => {
      try {
        this.salesmanSelectedItems.forEach(item => {
          salesman.push(item.userId);
        });
        this.filters.dateStart === null ? this.dateStart : this.filters.dateStart;
        this.filters.dateEnd === null ? this.dateEnd : this.filters.dateEnd;
        this.filters.idSalesman = salesman;
        this.loadFiltersTable(this.filters);
      } catch (e) { }
    }, 500);
  }

  loadDatesData(filterRangeDate: Date[]) {
    if (filterRangeDate != null) {
      this.filters.dateStart = this.datePipe.transform(filterRangeDate[0], 'yyyy-MM-dd');
      this.filters.dateEnd = this.datePipe.transform(filterRangeDate[1], 'yyyy-MM-dd');
    }
  }

  changeFilterIncident(value: any) {

    this.filtersIncident = {
      idGrupo: value.idGrupo,
      idSubgrupo: value.idSubgrupo,
      idRamo: Number(value.idRamo),
      idSubRamo: Number(value.idSubRamo),
      idEjecutivo: value.idEjecutivo,
      idAseguradora: value.idAseguradora,
      idIncidentType: value.idIncidentType,
      idRequestType: value.idRequestType,
      idStatus: value.idStatus,
      dateStart: value.dateStart,
      dateEnd: value.dateEnd,
      page: value.page,
      size: value.size
    };
    localStorage.removeItem('filtersIncident');
    localStorage.setItem('filtersIncident', JSON.stringify(this.filtersIncident));
  }

  invokeServiceGetSubranches() {
    const data = {name: 'SUBRANCHES'};
    this.apiService.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.subranchlist = data;

      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  changeGraphic() {
    this.filters = localStorage.getItem('filters');
    this.filters = JSON.parse(this.filters);
    this.typeGraphic = 1;
  }

  invokeServiceForGetReportsProduccionXls(subranchType?) {
    this.validateAccess.validateAccessCore(2).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        this.spinner.show('sp');
        if (this.filters.dateStart === null) {
          const fecha = new Date();
          const actualYear = fecha.getFullYear();
          const dateStart = actualYear.toString().concat('-01-01');
          const dateEnd = actualYear.toString().concat('-12-31');

          this.filters.dateStart = dateStart;
          this.filters.dateEnd = dateEnd;
        }

        let graphicExcel;

        /* 1 .- Prima neta
           3 .- Ingresos
           4 .- Subranch
           6 .- Cobranza
        * */
        if (subranchType !== undefined && subranchType !== null ) {
          if (subranchType === 1 || subranchType === '1') {
            graphicExcel = 4;
          } else if (subranchType === 2 || subranchType === '2') {
            graphicExcel = 5;
          } else if (subranchType === 6 || subranchType === '6') {
            graphicExcel = 6;
          }
          subranchType = undefined;
        } else {
          graphicExcel = this.typeGraphicPolicy;
        }

        this.apiService.getReportsProduccionXls(this.filters, graphicExcel)
          .then(
            (response: any) => {
              console.log(response);
              if (response == null) {
                this.toastr.info('No se encontraron registros para descargar');
                this.spinner.hide('sp');
                return;
              } else {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', 'reporte_produccion.xls');
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                this.spinner.hide('sp');
              }

            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      }
    });
  }

  invokeServiceForGetReportsIncidentXls() {
    this.spinner.show('sp');

    this.apiService.getReportsIncidentXls(this.filtersIncidentReport)
      .then(
        (response: any) => {
          console.log(response);
          if (response == null) {
            this.toastr.info('No se encontraron registros para descargar');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', 'reporte_incident.xls');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            this.spinner.hide('sp');
          }

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceForGetReportsGeneralIncidentXls() {
    this.validateAccess.validateAccessCore(2).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        this.spinner.show('sp');
        this.apiService.getReportIncidentGeneralExcelFromGraphic(this.filtersIncidentReport)
          .then(
            (response: any) => {
              console.log(response);
              if (response == null) {
                this.toastr.info('No se encontraron registros para descargar');
                this.spinner.hide('sp');
                return;
              } else {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', 'reporte_incident.xls');
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                this.spinner.hide('sp');
              }
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      }
    });
  }

  invokeServiceForGetReportsUserXls() {
    this.validateAccess.validateAccessCore(2).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        this.spinner.show('sp');
        if (this.filters.dateStart === null) {
          const fecha = new Date();
          const actualYear = fecha.getFullYear();
          const dateStart = actualYear.toString().concat('-01-01');
          const dateEnd = actualYear.toString().concat('-12-31');

          this.filters.dateStart = dateStart;
          this.filters.dateEnd = dateEnd;
        }

        this.apiService.getReportUserExcelFromGraphic(this.filters)
          .then(
            (response: any) => {
              console.log(response);
              if (response == null) {
                this.toastr.info('No se encontraron registros para descargar');
                this.spinner.hide('sp');
                return;
              } else {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', 'reporte_users.xls');
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                this.spinner.hide('sp');
              }

            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      }
    });
  }


  getSubranchFromPolicy() {
    this.showWorkSeniority = false;

    setTimeout(() => {
      try {
        if (this.policiesLowSelectedItems.length === 0) {
          this.showWorkSeniority = false;
        } else {
          this.policiesLowSelectedItems.forEach(item => {
            this.numbersPoliciesList.some(X => {
              if (Number(X.id) === Number(item.id)) {
                if (Number(X.type) === 3 || Number(X.type) === 5) {
                  this.showWorkSeniority = true;
                  return;
                }
              }
            });

          });
        }
      } catch (e) {
      }
    }, 500);

  }

  public invokeServiceForGetAttachmentFile(attachment) {
    this.apiService.getAttachmentFilepolicy(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', attachment.nameFile);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          // console.error(error);
        }
      );
  }

  invokeGetSubranchByGroup(idGroup) {
    if (idGroup !== undefined ) {
      this.apiService.getPolicyTypeByGroup(idGroup.groupId).then((data: any) => {
        this.typePolice = data;
        if (data != null) {
          this.subranchIdForSiniestralidadPrimDev = data[0].typeId;
        }
      }, error => {

      });
    }
  }

  invokeServiceForGetRequestList(groupId, clientId, operationType) {
    const filters = {
      groupId,
      clientId,
      operationType
    };

    this.apiService.getRequestList(filters).then(
        (response: any) => {
          if (response.length > 0) {
            this.requestList = response;
          } else {
            this.requestList = [];
          }

        }, error => {

        });

  }

  selectRequest(event, operationType, form: NgForm) {
    if (operationType === 1) {
      this.cleanFieldsInsuredForm();
      form.form.disable();
    } else if (operationType === 2) {
      this.cleanFieldsDependentForm();
    }
    this.policiesSelectedsList = [];
    if (event.requestId !== undefined) {
      this.requestId = event.requestId;
      this.apiService.getRequestById(event.requestId, operationType).then(
        (response: any) => {
          if (response !== null && response !== undefined) {
            if (operationType === 1) {
              (document.getElementById('nameInsured') as HTMLInputElement).value = response.name;
              (document.getElementById('firstName') as HTMLInputElement).value = response.firstName;
              (document.getElementById('lastName') as HTMLInputElement).value = response.lastName;
              (document.getElementById('email') as HTMLInputElement).value = response.email;
              (document.getElementById('employeeNumber') as HTMLInputElement).value  = response.employeeNumber;
              this.birthDate = new Date(response.birthdate);
              this.createAt = new Date(response.createAt);
              form.controls.gender.setValue(response.gender);

              const policiesList = response.policyCategories;

              if (policiesList !== null && policiesList !== undefined && policiesList.length > 0) {
                policiesList.forEach(policyCat => {
                  this.policieSelected = {};
                  this.policieSelected.policyId = policyCat.policyId;
                  this.policieSelected.categoryId = policyCat.categoryId;
                  this.policieSelected.numberPolicy = policyCat.numberPolicy;
                  this.policieSelected.nameCategory = policyCat.categoryName;
                  this.policiesSelectedsList.push(this.policieSelected);
                });
              }
            } else {
              this.userId = response.userId;
              const dependentlist = response.dependentsList;
              const policiesList = response.policyCategories;
              if (policiesList !== null && policiesList !== undefined && policiesList.length > 0) {
                policiesList.forEach(policyCat => {
                  this.policieSelected = {};
                  this.policieSelected.policyId = policyCat.policyId;
                  this.policieSelected.categoryId = policyCat.categoryId;
                  this.policieSelected.numberPolicy = policyCat.numberPolicy;
                  this.policieSelected.nameCategory = policyCat.categoryName;
                  this.policiesSelectedsList.push(this.policieSelected);
                });
              }
              if (dependentlist !== null && dependentlist !== undefined && dependentlist.length > 0) {
                dependentlist.forEach(dependent => {
                  this.addDependets(dependent, policiesList, response.createAt);
                });
              }
            }
          }

        }, error => {

        });
    } else {
      this.requestId = 0;
    }
  }

  selectRequestDelete(event, operationType, form: NgForm) {
    this.cleanDataLow();

    if (event !== undefined) {

      this.apiService.getRequestById(event.requestId, operationType).then(
        (response: any) => {
          if (response !== null && response !== undefined) {
            const policiesList = response.policyCategories;
            this.userId =  response.userId;
            this.insuredId = response.userId;
            this.requestId = event.requestId;
            this.invokeServiceForGetPoliciesInsuredId(this.insuredId);
            if (policiesList !== null && policiesList !== undefined && policiesList.length > 0) {
              policiesList.forEach(policyCat => {
                this.policiesLowSelectedItems.push({
                  id: policyCat.policyId,
                  title: policyCat.numberPolicy
                });
              });
            }
            form.form.controls.policiesLow.setValue(this.policiesLowSelectedItems);

            if (operationType === 3 ) { // Solicitud de baja de Aseguradps
              this.lowDateInsured = new Date(response.createAt);
              this.getSubranchFromPolicy();
            } else { // Solicitud de baja de dependients
              const dependentlist = response.dependentsList;
              this.lowDateDependent = new Date(response.createAt);
              if (dependentlist !== null && dependentlist !== undefined && dependentlist.length > 0) {
                dependentlist.forEach(dependent => {
                  this.dependentsLowSelectedItems.push({
                    kinshipId: dependent.kinshipId,
                    name: dependent.name
                  });
                });
              }
              form.form.controls.dependentsLow.setValue(this.dependentsLowSelectedItems);
              this.invokeServiceForGetPoliciesDependetId(this.dependentsLowSelectedItems[0].kinshipId);
            }

          }
        }, error => {
        });
    } else {
      this.requestId = 0;
    }
  }

  invokeGetSubgrupobyGroup(idGroup) {
    if (idGroup !== undefined ) {
      const group = idGroup.groupId;
      this.apiService.getSubGroups(group).then((data: any) => {
        this.catClientsProv = data;
      }, error => {

      });
    }
  }

  invokeGetPolicyByGroup(idClient) {
    if (idClient !== undefined ) {
      this.clientId = idClient.clientId;
      this.apiService.getPoliciesByCliente(this.clientId).then((data: any) => {
        this.policyListProv = data;
      }, error => {

      });
    }
  }

  clearGroup() {
    this.catClients = [];
    this.policyListProv = [];
  }

  clearClients() {
    this.policy = null;
    this.policyListProv = [];
  }

  getGroupsProv() {
    this.apiService.getGroups().then((data: any) => {
      this.groupListProv = data;
    }, error => {
    });
  }

  openModalProvitions(template: TemplateRef<any>) {
    this.optionGeneralSelected = 2;
    this.validateAccess.validateAccessCore(77).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.modalRefProv = this.modalService.show(template);
        this.getGroupsProv();
      }
    });
  }

  sendGroupForProvision() {
    if (this.groupIdProv === undefined) {
      this.toastr.info('Es necesario seleccionar un grupo');
      return;
    }

    const regenerateProvisions = {
      idGroup: this.groupIdProv
    };
    this.spinner.show('sp');
    this.apiService.postGeneratedProvisions(regenerateProvisions).subscribe(
    (response: any) => {
          console.log(response);
          this.spinner.hide('sp');
          this.closeModalProvision();
      this.toastr.success('El proceso de regeneración de provisiones ha comenzado, cuando termine se le notificará ', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  closeModalProvision() {
    this.modalRefProv.hide();
  }

  invokeServiceGetCertificatesZip(user) {
    this.spinner.show('sp');
    this.apiService.getCertificatesZipByUserId(user.userId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', user.name + '.zip');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            this.spinner.hide('sp');
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  sendGroupForInsuredList() {
    if (this.groupIdProv === undefined) {
      this.toastr.info('Es necesario seleccionar un grupo');
      return;
    }
    this.spinner.show('sp');
    this.apiService.downloadInsuredListXLS(this.groupIdProv).then(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'USUARIOS.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, (error) => {
        this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
        this.spinner.hide('sp');
        });
    this.spinner.hide('sp');
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    this.fileUploadAttachmentInsured = evt.target.files[0];
    this.dataToSendAttachment.name = this.fileUploadAttachmentInsured.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    this.dataToSendAttachment.attachmentPath = btoa(e.target.result);
  }

  saveFileInsuredUpdate() {
    this.spinner.show('sp');
    // console.log(this.dataToSendAttachment);
    if (this.dataToSendAttachment.attachmentPath === undefined) {
      this.toastr.warning('DEBE DE CARGAR UN ARCHIVO', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }

    const formDataXlsx = new FormData();
    formDataXlsx.append('fileXlsx', this.fileUploadAttachmentInsured);

    this.apiService.postInsuredData(formDataXlsx)
      .then(
        (response: any) => {
          this.fileUploadAttachmentInsured = undefined;
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.modalRef.hide();
          this.spinner.hide('sp');
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  sendEmailsToSendWelcome(emailsToString: string) {
    this.apiService.sendEmailManually(emailsToString).then(  (response: any) => {
      this.modalRef.hide();
      this.toastr.success('Su solicitud se ha realizado de forma exitosa, se paciente', 'NOTIFICACIÓN');
    }, error => {
      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
      this.spinner.hide('sp');
    });
  }

  getCancellationList(branchId) {
    this.apiService.getCancellations(branchId).then((data: any) => {
      this.reasonsCancellationList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
}
