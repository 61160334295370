import {Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

import * as _ from 'lodash';

@Component({
  selector: 'app-mascotas',
  templateUrl: './mascotas.component.html',
  styleUrls: ['./mascotas.component.css']
})
export class MascotasComponent implements OnInit {

  planForm: FormGroup; // Formulario

  idPlan: number;
  idProduct: number;

  configuraciones: any;
  coverages: any;
  plansCoverages: any = [];
  coverageList: any = [];
  indexSeleccionado: number;

  plan: any;
  modalCoverage: BsModalRef;

  key = 'idCoverage';
  display = 'coverageName';
  format = { add: 'Agregar', all: 'Todo', none: 'ninguno' };

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idPlan = +params.idPlan;
      this.idProduct = +params.idProduct;

      this.getCoverages();
      this.getConfiguraciones();
    });

    this.createForm();
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  createForm(): void {
    this.planForm = this.formBuilder.group({
      typeConfigurationPolicyId: [null, Validators.required],
      numeroPlanes: [null],
      plans: this.formBuilder.array([])
    });
  }

  addPlan(value: any): FormGroup {
		return this.fb.group({
			idFlexPlanPlanes: [value.idFlexPlanPlanes ? value.idFlexPlanPlanes : null],
			idFlexPlanCategories: [value.idFlexPlanCategories ? value.idFlexPlanCategories : null],
			planName: [value.planName ? value.planName : null],
		});
	}

  getCoverageByName(name: string): any {
    const index = _.findIndex(this.coverages, { coverageName: name });
    return this.coverages[index];
  }

  getConfigurationData(): void {
    const params = {
      planFlexId: this.idPlan
    };

    this.api.getConfigurationData(params).subscribe(response => {
      this.plan = response;
      this.planForm.get('typeConfigurationPolicyId').setValue(this.plan.typeConfigurationPolicyId ? this.plan.typeConfigurationPolicyId.toString() : null);
      this.planForm.get('numeroPlanes').setValue(this.plan.plans.length);

      const plans = <FormArray>this.planForm.get('plans');
      this.plan.plans.forEach(plan => {
        plans.push(this.addPlan(plan));
        const coverages = plan.coveragesPlan.map(ob => {
          const cov = this.getCoverageByName(ob.coverageName);
          const newOb = Object.assign({}, cov);
          newOb.newValue = ob.unitMeasurementId;
          newOb.newValue2 = ob.value;
          return newOb;
        });
        const sorted = _.sortBy(coverages, 'coverageName');
        this.plansCoverages.push(sorted);
      });
    },
    error => {
      console.log(error);
    });
  }

  getCoverages(): void {
      const params = {
        idPlan: this.idPlan,
        idProduct: this.idProduct
      };
      this.api.getCoveragesByIdProductOb(params).subscribe(response => {
        this.coverages = response;
        this.getConfigurationData();
      },
      error => {
        console.log(error);
      });
  }

  getCoverageByPlan(index: number, name: string): any {
    let res = null;
    if (this.plan.plans.length > 0) {
      this.plan.plans[index].coveragesPlan.forEach(cov => {
        if (cov.coverageName === name) {
          res = cov;
        }
      });
    }

    return res;
  }

  savePlan(): void {
    if (!this.planForm.valid) {
      this.toastr.error('Faltan datos por capturar');
      return;
    }

    const planes = this.planForm.get('plans').value;
    const plans = planes.map((ob, index) => {
      const coveragesPlan = this.plansCoverages[index].map(obCov => {
        let idFlexPlanCoverages = null;
        let value = null;
        if (this.plan) {
          const resCov = this.getCoverageByPlan(index, obCov.coverageName);
          if (resCov) {
            idFlexPlanCoverages = resCov.idFlexPlanCoverages;
            value = resCov.value;
          } else {
            const cov = this.getCoverageByName(obCov.coverageName);
            idFlexPlanCoverages = cov.idFlexPlanCoverages;
            value = cov.value;
          }
        }

        return {
          idFlexPlanCoverages: idFlexPlanCoverages,
          coverageSurexsId: obCov.idCoverage,
          coverageName: obCov.coverageName,
          value: obCov.newValue2,
          unitMeasurementId: obCov.newValue
        };
      });

      return {
        idFlexPlanPlanes: ob.idFlexPlanPlanes,
        idFlexPlanCategories: ob.idFlexPlanCategories,
        planName: ob.planName,
        coveragesPlan: coveragesPlan
      }
    });

    const params = {
      planFlexId: this.idPlan,
      typeConfigurationPolicyId: +this.planForm.get('typeConfigurationPolicyId').value,
      plans: plans
    };

    this.api.savePhaseOneConfiguration(params).subscribe(response => {
      this.toastr.success('Configuración guardada correctamente');
      this.getSlip(true);
    },
    error => {
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }

  checkCambioCobertura(index: number, indexCov: number, event: any): void {
    const planOb = this.plan.plans[index]
    if (planOb && planOb.status === 2) {
      const modalRef = this.modalService.show(ConfirmComponent, { });
      modalRef.content.textConfirm = 'El plan ya tiene tarifas agregadas, si se modifica se eliminaran estas tarifas, estas de acuerdo?';

      modalRef.content.onClose.subscribe(data => {
          modalRef.hide();
          if (data.res) {
            planOb.status = 1;
            this.plansCoverages[index][indexCov].newValue = event;
          } else {
            const cov = this.plansCoverages[index][indexCov];
            const ind2 = _.findIndex(planOb.coveragesPlan, { coverageName : cov.coverageName });
            this.plansCoverages[index][indexCov].newValue = planOb.coveragesPlan[ind2].unitMeasurementId; 
          }
      });
    } else {
      this.plansCoverages[index][indexCov].newValue = event;
    }
  }

  deletePlan(plan: any, index: number): void {
    const params = {
      planFlexId: this.idPlan,
      idFlexPlanPlanes: plan.idFlexPlanPlanes
    };

    this.api.deletePlanConfiguration(params).subscribe(response => {
      const plans = <FormArray>this.planForm.get('plans');
      plans.removeAt(index);
      this.plansCoverages.splice(index, 1);
      this.toastr.success('Plan elliminado correctamente');
    },
    error => {
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }

  getConfiguraciones(): void {
    this.api.getConfigurationTypeOb({ idProduct: this.idProduct }).subscribe(response => {
      this.configuraciones = response;
    },
    error => {
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }

  checkTipoPrograma(): void {
    const tipoPrograma = this.planForm.get('typeConfigurationPolicyId').value;
    if (tipoPrograma === 5) {

    }
  }

  checkNumeroPlanes(): void {
    const numeroPlanes = this.planForm.get('numeroPlanes').value;
    const plans = <FormArray>this.planForm.get('plans');
    plans.clear();
    this.plansCoverages = [];
    for(let i = 0; i < numeroPlanes; i++) {
      plans.push(this.addPlan({}));
      const coverages = this.coverages.map(ob => {
        const newOb = Object.assign({}, ob);
        return newOb;
      });

      const sorted = _.sortBy(coverages, 'coverageName');
      this.plansCoverages.push(sorted);
    }
  }

  isViaNomina(): boolean {
    const tipoPrograma = this.planForm.get('typeConfigurationPolicyId').value;
    return tipoPrograma === 5;
  }

  getSlip(exit: boolean = false): void {
    const params = {
      planFlexId: +this.idPlan
    };

    this.api.getSlipMascotas(params).subscribe((response: any) => {
      const binaryImg = atob(response.slip);
      const length = binaryImg.length;
      const arrayBuffer = new ArrayBuffer(length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < length; i++) {
          uintArray[i] = binaryImg.charCodeAt(i);
      }
      const blob = new Blob([uintArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL);
      if (exit) {
        this.router.navigate(['/admin/plans-detalle', this.idPlan]);
      }
    },
    error => {
      console.log(error);
    });
  }

  eliminarCobertura(index: number, covIndex: number): void {
    this.plansCoverages[index].splice(covIndex, 1);
  }

  agregarCobertura(template: TemplateRef<any>, index: number) {
    this.indexSeleccionado = index;
    this.coverageList = this.plansCoverages[index];
    this.modalCoverage = this.modalService.show(template, {class: 'modal-md', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  onCoverageByPlanChange(event: any) {
    this.plansCoverages[this.indexSeleccionado] = event;
  }

  closeModal() {
    this.modalCoverage.hide();
  }

  get plans(): FormControl[] {
		return (this.planForm.get('plans') as FormArray).controls as FormControl[];
	}

}
